import React, { ComponentPropsWithoutRef } from 'react';
interface inputTypes extends ComponentPropsWithoutRef<'input'> {
  label: string;
  type: string;
  value: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;

  noUnderline?: boolean;
}

function InputAnimated({
  label,
  type,
  value,
  name,
  onChange,
  noUnderline = false,
  ...rest
}: inputTypes) {
  return (
    <div className="inputEffect ">
      <div className="form__group">
        <input
          id={name}
          onChange={onChange}
          value={value || ''}
          type={type}
          name={name}
          className={`form__field ${
            value?.length > 0
              ? 'border-b focus:!border-b-primary placeholder:text-transparent'
              : ''
          } ${noUnderline ? ' !border-transparent ' : ' '}`}
          placeholder={label}
          {...rest}
        />
        <label htmlFor={name} className="form__label gotham text-sm">
          {label}
        </label>
      </div>
    </div>
  );
}

export default InputAnimated;
