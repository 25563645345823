
import { ApolloProvider } from '@apollo/client';
import React, { useContext, useEffect } from 'react';
import { AuthContext } from './auth';
interface iProps {
  children: React.ReactNode;
}
function ApolloWrapper(props: iProps) {
  const client = useContext(AuthContext).client
  
  if(!client) return null;
  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
}

export default ApolloWrapper;
