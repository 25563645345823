import { toast } from 'react-hot-toast';

export const toastError = (e) => toast.error(e);

export const toastSuccess = (e) => toast.success(e);
export const toastLoading = (e, style) =>
  toast.loading(e, {
    style: { ...style, zoom: 1.2 },
    className: 'w-full h-full  max-h-[80px] max-w-[450px] flex ',
    position: 'top-center',
    icon: false,
  });

export const toastDismiss = () => toast.dismiss();
