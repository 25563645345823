export enum MENU_ID {
  DASHBOARD = '',
  CLASS = 'class',
  MEMBERSHIPS = 'memberships',
  ACCOUNT = 'account',
  BENEFITS = 'benefits',
  CLUB_SEARCH = 'club-search',
}

export interface IMenu {
  name: string;
  link: string;
  id: string;
  isNotReady?: boolean;
}

export const menus: Array<IMenu> = [
  { name: 'dashboard', link: '/dashboard', id: MENU_ID.DASHBOARD },
  {
    name: 'Classes',
    link: '/dashboard/class/timetable',
    id: MENU_ID.CLASS,
  },
  {
    name: 'my membership',
    link: '/dashboard/memberships',
    id: MENU_ID.MEMBERSHIPS,
  },
  {
    name: 'my profile',
    link: '/dashboard/account',
    id: MENU_ID.ACCOUNT,
  },
  {
    name: 'member benefits',
    link: 'benefits',
    id: MENU_ID.BENEFITS,
    isNotReady: true,
  },
  {
    name: 'Club Search',
    link: '/dashboard/club-search',
    id: MENU_ID.CLUB_SEARCH,
  },
];
