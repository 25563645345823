import PrimaryOutlinedButton from '@/common/buttons/PrimaryOutlinedButton';
import ThreeDot from '@/common/loading/ThreeDot';
import { toastError } from '@/common/toasts/toast';
import { useMemberShipContext } from '@/context/MembershipContext';
import React from 'react';
import 'flatpickr/dist/themes/material_green.css';
import Flatpickr from 'react-flatpickr';
import { breakpoints } from '@/constants';
import ModalBase from '@/common/modals/ModalBase';
import ButtonNavigate from '@/common/buttons/ArrowButton';
import InputTextArea from '@/common/inputs/textArea';

const SuspendMembership = ({
  openModal,
  modalLoading,
  isSuspend,
  setModal,
  setUserReasonInput,
  handleSuspendInputs,
  reason,
  setReason,
  endDate,
  setEndDate,
  startDate,
  setStartDate,
  setItemSuspend,
  setIsSuspended,
  width,
  handleDateISO,
  minDD,
  userReason,
  handleMedSusStart,
  setpaymentAmount,
  setPaymentPayloadData,
  setPaymentType,
}) => {
  const { userSelectedMembership } = useMemberShipContext();
  const calculateNumberOfWeeks = (startDate, endDate) => {
    const formatStartDate = new Date(handleDateISO(startDate, true));
    const formatEndDate = new Date(handleDateISO(endDate, true));
    const msInDay = 24 * 60 * 60 * 1000;
    const amount = 0;
    // Math.ceil(
    //   Math.abs(formatEndDate - formatStartDate + msInDay) / msInDay / 7
    // ) * 2.5;
    if (reason.filter((e) => e.name.toLowerCase() == 'medical')?.[0]?.select) {
      // return `The suspension from ${startDate} to ${endDate} will cost you $0`;
      return ` Suspension from ${startDate} to ${endDate} `;
    } else {
      // return `The suspension from ${startDate} to ${endDate} will cost you $${amount}`;
      return `Suspension from ${startDate} to ${endDate}`;
    }
    // "The suspension from startDate will cost you $" +
    // Math.ceil(Math.abs(endDate - startDate) / msInDay / 7) * 2.5 +
    // " for " +
    // Math.abs(endDate - startDate) / msInDay +' days'
  };

  const handleCheck = () => {
    // HANDLE FOR MOBILE
    if (width && width <= breakpoints.sm) {
      if (startDate && endDate && reason.filter((i) => i.select).length) {
        const reasonSelected = reason.filter((e) => e.select)?.[0];

        if (
          reasonSelected.name.toLocaleLowerCase() === 'medical' &&
          reasonSelected.file?.length === 0
        ) {
          toastError('Please attach your file then you good to go');
          return;
        } else if (
          reasonSelected.name.toLocaleLowerCase() === 'standard suspension' &&
          userReason.trim() === ''
        ) {
          toastError('Please provide reason details then you good to go');
          return;
        }
        handleSuspendInputs();
      } else toastError('Please fill all the inputs then you good to go ');
    }
    // HANDLE FOR DESKTOP
    else {
      if (startDate && endDate && reason.filter((i) => i.select).length) {
        const reasonSelected = reason.filter((e) => e.select)?.[0];
        if (
          reasonSelected.file?.length === 0 &&
          reasonSelected.name.toLocaleLowerCase() === 'medical'
        ) {
          toastError('Please attach your file then you good to go');
          return;
        }

        setItemSuspend(userSelectedMembership.id);

        const formatStartDate = new Date(handleDateISO(startDate, true));
        const formatEndDate = new Date(handleDateISO(endDate, true));
        const msInDay = 24 * 60 * 60 * 1000;
        const amount = 0;
        // Math.ceil(
        //   Math.abs(formatEndDate - formatStartDate + msInDay) / msInDay / 7
        // ) * 2.5;
        setpaymentAmount(amount);
        setPaymentPayloadData({
          suspensionArguments: {
            suspendFrom: startDate.split('/').reverse().join('-'),
            suspendTo: endDate.split('/').reverse().join('-'),
            suspensionReason: userReason,
            suspensionType: 'OTHER',
            operation: 'CREATE_SUSPENSION',
            suspensionAmount:
              Math.ceil(
                Math.abs(
                  new Date(endDate.split('/').reverse().join('-')) -
                    new Date(startDate.split('/').reverse().join('-')) +
                    24 * 60 * 60 * 1000
                ) /
                  (24 * 60 * 60 * 1000) /
                  7
              ) * 2.5,
          },
        });
        setPaymentType('SSC');
        setModal(true);
        // setModal;
      } else toastError('Please fill all the inputs then you good to go ');
    }
  };
  // const checkStart
  const handleMaxSusEnd = () => {
    const sixMonthFromStartDate = new Date(
      new Date(startDate.split('/').reverse().join('-')).setDate(
        new Date(startDate.split('/').reverse().join('-')).getDate() + 90
      )
    );
    let suspensionEndDate;
    const contractEndDate = new Date(userSelectedMembership?.endDateTime);

    if (
      userSelectedMembership?.endDateTime &&
      contractEndDate.valueOf() < sixMonthFromStartDate.valueOf()
    ) {
      suspensionEndDate = contractEndDate;
    } else {
      suspensionEndDate = sixMonthFromStartDate;
    }
    return suspensionEndDate;
  };

  return (
    <>
      {openModal && (
        <ModalBase
          noBaseHeader
          onClickOutSide={() => {
            // setModal(false)
          }}
          loading={modalLoading}
        >
          <div className="flex items-center justify-between pt-6 md:pt-6 pr-6 pl-[33px] w-[688px] ">
            <div className="flex items-center gap-[15px] ">
              <span className="uppercase gotham tracking-[0.01em]">
                SUSPEND MEMBERSHIP
              </span>
            </div>
            {isSuspend !== true && (
              <div
                onClick={() => setModal(false)}
                className="flex items-center justify-center w-8 h-8 rounded-full border border-white cursor-pointer"
              >
                <div className="w-4 h-4 block">
                  <img
                    src="/icons/close.png"
                    alt="close"
                    className="object-fit w-full"
                  />
                </div>
              </div>
            )}
          </div>
          {isSuspend == null && (
            <div className="pr-6 pl-[33px] flex gap-[24px] my-[50px] flex-col justify-center items-center max-w-[688px] ">
              <p className="text-[40px] text-center gravity uppercase font-bicyclette font-bold">
                are you sure you want to suspend?
              </p>

              <div className="self-start w-full">
                <p className="gotham text-sm text-white cursor-pointer my-2">
                  Please tell us your reason:
                </p>
                <InputTextArea
                  // isShowRequired={checking}
                  onchange={setUserReasonInput}
                  nameInput="reason"
                  placeholder="Reason*"
                  style="lg:w-full"
                  isRequired={true}
                  type="text"
                />
              </div>

              <div className="flex gap-6 sm:flex-col">
                <ButtonNavigate
                  noArr={true}
                  text="Yes. I want to suspend"
                  // ;
                  onclick={
                    () => handleSuspendInputs()
                    // reason.filter((i) => i.select)[0].name.toLowerCase() ===
                    // 'standard'
                    //   ? setPaymentModal(true)
                    //   : handleSuspendInputs()
                  }
                />
                <ButtonNavigate
                  noArr={true}
                  text="No. I want to continue"
                  onclick={() => setModal(false)}
                />
              </div>
            </div>
          )}
          {isSuspend == true && (
            <div className="pr-6 pl-[33px] flex gap-[24px] my-[50px] flex-col justify-center items-center mt-[58px] mb-[100px]">
              {reason.filter((i) => i.select)[0].name.toLowerCase() !==
              'medical' ? (
                <>
                  <p className="text-[40px] font-bold font-bicyclette gravity text-center uppercase">
                    your membership
                    <br /> will be suspended
                  </p>
                  <p className=" text-lg text-center gotham text-primary">
                    from {startDate} to {endDate}{' '}
                  </p>
                  <p className="max-w-[392px] text-xs text-center gotham leading-[22px] mb-[8px]">
                    Your membership will resume on{' '}
                    {new Date(
                      new Date(endDate.split('/').reverse().join('-')).setDate(
                        new Date(
                          endDate.split('/').reverse().join('-')
                        ).getDate() + 1
                      )
                    )
                      .toISOString()
                      .split('T')[0]
                      .split('-')
                      .reverse()
                      .join('/')}
                    .<br /> Enjoy your time off, we will see you then. We will
                    send
                    <br /> you a reminder email close to that date.
                  </p>
                </>
              ) : (
                <p className="text-[40px] font-bold font-bicyclette gravity text-center uppercase">
                  <>Your membership suspension is awaiting approval</>
                </p>
              )}

              <ButtonNavigate
                text="TAKE ME TO DASHBOARD"
                onclick={() => {
                  setItemSuspend(null);
                  setIsSuspended(null);
                  setModal(false);
                  // navigate('/dashboard');
                  window.location.replace('/dashboard');
                }}
              ></ButtonNavigate>
            </div>
          )}
        </ModalBase>
      )}

      {/* MOBILE */}
      {isSuspend && width && width <= breakpoints.sm ? (
        <div className="px-6 pb-20 mt-4">
          <h2 className="font-bicyclette font-bold text-xl text-primary uppercase">
            your membership will be suspended
          </h2>
          <p className="text-sm font-bold text-white mt-2">
            from {startDate} to {endDate}
          </p>
          <p className="text-sm leading-[160%] text-white mt-4">
            Your membership will resume on {endDate}. Enjoy your time off,
            we will see you then. We will send you a reminder email close to
            that date.
          </p>

          <div className="flex flex-col gap-y-5 mt-9">
            <PrimaryOutlinedButton
              onClick={() => window.location.replace('/dashboard/memberships')}
              className="h-[52px] w-full rounded text-base font-bold leading-[160%] uppercase"
            >
              my membership
            </PrimaryOutlinedButton>
            <PrimaryOutlinedButton
              onClick={() => window.location.replace('/dashboard')}
              className="h-[52px] w-full rounded text-base font-bold leading-[160%] uppercase"
            >
              go to dashboard
            </PrimaryOutlinedButton>
          </div>
        </div>
      ) : (
        <div className="sm:block hidden px-6 pb-20 relative">
          {modalLoading && (
            <div className="fixed top-0 left-0 h-screen w-screen">
              <ThreeDot />
            </div>
          )}
          <div className="flex gap-x-[10px] items-center text-textSecondary font-normal text-sm mb-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              className="flex-none"
            >
              <path
                d="M4.77144 25.2283C-0.915588 19.5413 -0.942374 10.3527 4.70529 4.70505C10.353 -0.942613 19.5415 -0.915825 25.2286 4.77121C30.9156 10.4582 30.9424 19.6468 25.2947 25.2945C19.647 30.9421 10.4585 30.9153 4.77144 25.2283Z"
                stroke="#848484"
                stroke-width="0.6742"
              />
              <path
                d="M14.4837 17.2073L14.2876 14.9224L14.3529 14.8519C16.0523 14.8096 16.9542 14.048 16.9542 12.8632V12.835C16.9542 11.8195 16.2222 11.0014 15.0458 11.0014C14.0784 11.0014 13.3333 11.4951 12.6667 12.3272L12 11.6361C12.7451 10.6629 13.6732 10 15.0719 10C16.8366 10 18 11.1848 18 12.7927V12.8209C18 14.5839 16.7712 15.4584 15.281 15.6841L15.1503 17.2073H14.4837ZM14.2092 20V18.519H15.4118V20H14.2092Z"
                fill="#848484"
              />
            </svg>
            We have a maximum suspension period of 90 days and a minimum of 7
            days.
            <br />
            {/* suspension cost: $2.50 per week (rounded to next full week i.e. if
              you suspend for 8 days the suspendsion fee will be $5.00)  */}
            Your next suspension cannot start before your next Direct Debit on{' '}
            {minDD ? handleDateISO(minDD?.toISOString()) : 'Today'}
          </div>

          <h2 className="text-primary text-xl font-bold font-bicyclette uppercase mb-4">
            suspension period
          </h2>

          <div className="flex flex-col gap-y-[25px] mt-2">
            <label
              onClick={() => {
                const selectedReason = reason.filter((i) => i.select);
                if (selectedReason.length === 0) {
                  toastError('please select the reason first');
                }
              }}
            >
              <div
                className={`border-b flex h-[30px] flex-col pb-[2px]  relative ${
                  startDate !== '' ? 'border-b-white' : 'border-b-white'
                } `}
              >
                <div className="absolute  bottom-[6px] w-full">
                  {reason.filter((i) => i.select).length > 0 && (
                    <Flatpickr
                      onChange={(dateObj, dateStr) => setStartDate(dateStr)}
                      // value={
                      //   startDate
                      //     ? startDate.split('/').reverse().join('-')
                      //     : null
                      // }
                      options={{
                        dateFormat: 'd/m/Y',
                        disableMobile: 'true',
                        enableTime: false,
                        time_24hr: false,
                        allowInput: false,
                        minDate:
                          reason
                            .filter((i) => i.select)[0]
                            .name.toLocaleLowerCase() === 'standard suspension'
                            ? new Date(minDD)
                            : handleMedSusStart(),
                      }}
                    />
                  )}
                </div>
                <label
                  className={`gotham transition-all duration-300 absolute left-0 ${
                    startDate !== ''
                      ? 'text-textSecondary top-[2px] sm:top-[-13px] text-xs'
                      : 'text-white bottom-[6px] text-base'
                  }`}
                >
                  Start date*
                </label>
                <div className="absolute  z-10 right-3 bottom-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="19"
                    viewBox="0 0 20 19"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_1_1209)">
                      <path
                        d="M4.09005 5.14832H4.09376C4.70159 5.14832 5.19434 4.65957 5.19434 4.05669L5.19434 1.08795C5.19434 0.485065 4.70159 -0.00366497 4.09376 -0.00366497H4.09005C3.48222 -0.00366497 2.98947 0.485065 2.98947 1.08795L2.98947 4.05669C2.98947 4.65957 3.48222 5.14832 4.09005 5.14832Z"
                        fill="white"
                      />
                      <path
                        d="M16.0813 5.14832H16.085C16.6928 5.14832 17.1855 4.65957 17.1855 4.05669V1.08795C17.1855 0.485065 16.6928 -0.00366497 16.085 -0.00366497H16.0813C15.4734 -0.00366497 14.9807 0.485065 14.9807 1.08795V4.05669C14.9807 4.65957 15.4734 5.14832 16.0813 5.14832Z"
                        fill="white"
                      />
                      <path
                        d="M1.52086 18.9999H18.4906C18.8923 18.9989 19.2772 18.84 19.5608 18.5579C19.8445 18.2758 20.0039 17.8937 20.0039 17.4953V3.57717C20.0039 3.17875 19.8445 2.79659 19.5608 2.51452C19.2772 2.23245 18.8923 2.07349 18.4906 2.07251H1.52086C1.11917 2.07349 0.734266 2.23245 0.450575 2.51452C0.166884 2.79659 0.00756715 3.17875 0.00756836 3.57717V17.4953C0.00756715 17.8937 0.166884 18.2758 0.450575 18.5579C0.734266 18.84 1.11917 18.9989 1.52086 18.9999V18.9999ZM1.72908 6.82989H18.2823V16.979H1.72164L1.72908 6.82989Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_1209">
                        <rect width="20" height="19" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
            </label>
            <label
              onClick={() => {
                !startDate && toastError('please select start date first');
              }}
            >
              <div
                className={`border-b flex h-[30px] flex-col pb-[2px]  relative ${
                  endDate !== '' ? 'border-b-white' : 'border-b-white'
                } `}
              >
                <div className="absolute  bottom-[6px] w-full">
                  {startDate && (
                    <Flatpickr
                      onChange={(dateObj, dateStr) => setEndDate(dateStr)}
                      value={
                        endDate
                          ? new Date(endDate.split('/').reverse().join('-'))
                          : null
                      }
                      options={{
                        dateFormat: 'd/m/Y',
                        disableMobile: 'true',
                        enableTime: false,
                        time_24hr: false,
                        allowInput: false,
                        maxDate: new Date(
                          new Date(
                            startDate.split('/').reverse().join('-')
                          ).setDate(
                            new Date(
                              startDate.split('/').reverse().join('-')
                            ).getDate() + 90
                          )
                        ),

                        minDate: startDate
                          ? reason
                              .filter((i) => i.select)[0]
                              .name.toLocaleLowerCase() ===
                            'standard suspension'
                            ? new Date(
                                new Date(
                                  startDate.split('/').reverse().join('-')
                                ).setDate(
                                  new Date(
                                    startDate.split('/').reverse().join('-')
                                  ).getDate() + 6
                                )
                              )
                            : new Date(startDate.split('/').reverse().join('-'))
                          : 'today',
                      }}
                    />
                  )}
                </div>
                <label
                  className={`gotham transition-all duration-300 absolute left-0 ${
                    endDate !== ''
                      ? 'text-textSecondary top-[2px] sm:top-[-13px] text-xs'
                      : 'text-white bottom-[6px] text-base '
                  }`}
                >
                  End date*
                </label>
                <div className="absolute  z-10 right-3 bottom-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="19"
                    viewBox="0 0 20 19"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_1_1209)">
                      <path
                        d="M4.09005 5.14832H4.09376C4.70159 5.14832 5.19434 4.65957 5.19434 4.05669L5.19434 1.08795C5.19434 0.485065 4.70159 -0.00366497 4.09376 -0.00366497H4.09005C3.48222 -0.00366497 2.98947 0.485065 2.98947 1.08795L2.98947 4.05669C2.98947 4.65957 3.48222 5.14832 4.09005 5.14832Z"
                        fill="white"
                      />
                      <path
                        d="M16.0813 5.14832H16.085C16.6928 5.14832 17.1855 4.65957 17.1855 4.05669V1.08795C17.1855 0.485065 16.6928 -0.00366497 16.085 -0.00366497H16.0813C15.4734 -0.00366497 14.9807 0.485065 14.9807 1.08795V4.05669C14.9807 4.65957 15.4734 5.14832 16.0813 5.14832Z"
                        fill="white"
                      />
                      <path
                        d="M1.52086 18.9999H18.4906C18.8923 18.9989 19.2772 18.84 19.5608 18.5579C19.8445 18.2758 20.0039 17.8937 20.0039 17.4953V3.57717C20.0039 3.17875 19.8445 2.79659 19.5608 2.51452C19.2772 2.23245 18.8923 2.07349 18.4906 2.07251H1.52086C1.11917 2.07349 0.734266 2.23245 0.450575 2.51452C0.166884 2.79659 0.00756715 3.17875 0.00756836 3.57717V17.4953C0.00756715 17.8937 0.166884 18.2758 0.450575 18.5579C0.734266 18.84 1.11917 18.9989 1.52086 18.9999V18.9999ZM1.72908 6.82989H18.2823V16.979H1.72164L1.72908 6.82989Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_1209">
                        <rect width="20" height="19" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
            </label>
          </div>

          <h2 className="text-primary text-xl font-bold font-bicyclette uppercase mt-5 leading-[37px] pt-[1px]">
            reason for suspension
          </h2>
          {reason.map((item, index) => {
            return (
              <div
                className={` ${
                  index + 1 !== reason?.length
                    ? ' border-b-[0.5px] border-textSecondary '
                    : ' '
                }${index == 0 ? 'pt-[10px] pb-5 ' : 'py-5'}`}
                key={index}
              >
                <div className={`flex items-center gap-x-3`}>
                  <div
                    className={`text-white justify-self-end w-[30px] rounded-[5px] h-[30px] border  flex justify-center items-center ${
                      item.select ? 'bg-white' : 'border-white'
                    }`}
                    onClick={() => {
                      const newData = [...reason].map((childItem, childIndex) =>
                        childIndex == index
                          ? { ...childItem, select: !childItem.select }
                          : { ...childItem, select: false }
                      );
                      setReason(newData);
                    }}
                  >
                    {item.select && (
                      <svg
                        width="14"
                        height="10"
                        viewBox="0 0 14 10"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 4.69231L5.2 9L13 1"
                          stroke={'black'}
                          strokeWidth="1.02"
                          fill="white"
                        />
                      </svg>
                    )}
                  </div>
                  <p className="text-white font-normal">{item.name}</p>
                </div>

                {item.select && item.name.toLocaleLowerCase() === 'medical' && (
                  <>
                    <div className="image-upload p-[14px] bg-textSecondary bg-opacity-20 rounded border border-dashed border-textSecondary mt-5">
                      <label
                        htmlFor="medical-file-input"
                        className="flex items-center gap-x-4"
                      >
                        <div className="w-[35px]">
                          <img
                            className="object-contain w-full block"
                            src="/icons/cloud-upload.svg"
                          />
                        </div>
                        <div className="">
                          <p className="text-white mb-1 font-normal">
                            Upload medical certificate
                          </p>
                          <p className="text-textSecondary text-xs font-normal">
                            Image files or PDF only
                          </p>
                        </div>
                      </label>

                      <input
                        hidden
                        id="medical-file-input"
                        type="file"
                        onChange={(e) => {
                          const newData = [...reason].map(
                            (childItem, childIndex) =>
                              childIndex == index
                                ? {
                                    ...childItem,
                                    file: [e.target.files[0]],
                                  }
                                : { ...childItem }
                          );
                          setReason(newData);
                        }}
                        accept="application/pdf,application/vnd.ms-excel,application/vnd.ms-word,pdf, image/jpg, image/jpeg, image/png"
                        multiple
                      />
                    </div>
                    {item.file?.length > 0 && (
                      <div className="flex flex-col mt-5 gap-y-3">
                        {item.file?.map((file, childIndex) => (
                          <div className="flex items-center gap-x-3">
                            <button
                              className={`text-textSecondary font-medium border-b rounded-md relative h-[29px] flex items-center justify-center w-[29px] border-t border-l border-r border-textSecondary border-solid`}
                              onClick={() => {
                                const newData = [...reason].map((item, index) =>
                                  index == childIndex
                                    ? { ...item, file: [] }
                                    : { ...item }
                                );
                                setReason(newData);
                              }}
                            >
                              X
                            </button>
                            <p className="text-textSecondary text-sm leading-[121%] font-normal">
                              {file.name}
                            </p>
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                )}

                {item.select &&
                  item.name.toLocaleLowerCase() === 'standard suspension' && (
                    <div>
                      <textarea
                        placeholder="Please provide details*"
                        className="w-full bg-white rounded border border-textSecondary p-2 mt-5 text-textSecondary"
                        rows={5}
                        value={userReason}
                        onChange={setUserReasonInput}
                      ></textarea>
                    </div>
                  )}
              </div>
            );
          })}

          <PrimaryOutlinedButton
            onClick={() => {
              // handleSuspend();

              setItemSuspend(userSelectedMembership.id);

              handleCheck();
            }}
            className={`text-white text-base font-bold uppercase rounded h-[52px] w-full mt-5 ${
              !startDate || !endDate || !reason.filter((i) => i.select).length
                ? 'pointer-events-none opacity-40'
                : ' '
            }`}
          >
            SUSPEND
          </PrimaryOutlinedButton>
        </div>
      )}

      {/* DESKTOP */}
      <div className="block sm:hidden">
        {userSelectedMembership?.isActive && (
          <div className="mt-[30px] lg:w-full pb-[32px] mb-6 border-[1.5px] border-[rgb(255,255,255,0.4)] rounded hover:bg-[#1F2528]">
            <>
              <p className="border-b border-white font-bicyclette font-medium text-white px-6 tracking-[1.4px] py-[19.5px] uppercase h-[57px] px-[27px] border-b border-b-white text-white font-bold  flex justify-between items-center uppercase">
                Reason for suspension
              </p>
              {reason.map((i, ind) =>
                i.type != 'attach' ? (
                  <div
                    className="flex mx-[30px] text-white items-center px-[7px] justify-between border-b border-white py-[12px] "
                    key={ind}
                  >
                    <p className="gotham text-white tracking-[0.01em] text-sm font-medium ">
                      {i.name}
                    </p>
                    <div
                      className={`text-white w-[30px] rounded-[5px] h-[30px] border  flex justify-center items-center ${
                        i.select ? 'bg-white' : 'border-white'
                      }`}
                      onClick={() => {
                        const newData = [...reason].map((item, index) =>
                          index == ind
                            ? { ...item, select: !item.select }
                            : { ...item, select: false }
                        );
                        setReason(newData);
                      }}
                    >
                      {i.select && (
                        <svg
                          width="14"
                          height="10"
                          viewBox="0 0 14 10"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 4.69231L5.2 9L13 1"
                            stroke={'black'}
                            strokeWidth="1.02"
                            fill="white"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                ) : (
                  <>
                    <div
                      className={`grid grid-cols-2 mx-[30px] text-white items-center px-[7px] justify-between ${
                        i.name.toLowerCase() == 'medical' &&
                        'border-b border-white pb-[39px]'
                      }   pt-[12px]  gap-y-[25px]`}
                    >
                      <p className="gotham text-white tracking-[0.01em] text-sm font-normal ">
                        {i.name.toLowerCase() == 'medical'
                          ? 'Medical suspension'
                          : 'Standard Suspension'}
                      </p>
                      <div
                        className={`text-white justify-self-end w-[30px] rounded-[5px] h-[30px] border  flex justify-center items-center ${
                          i.select ? 'bg-white' : 'border-white'
                        }`}
                        onClick={() => {
                          const newData = [...reason].map((item, index) =>
                            index == ind
                              ? { ...item, select: !item.select }
                              : { ...item, select: false }
                          );
                          setReason(newData);
                        }}
                      >
                        {i.select && (
                          <svg
                            width="14"
                            height="10"
                            viewBox="0 0 14 10"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 4.69231L5.2 9L13 1"
                              stroke={'black'}
                              strokeWidth="1.02"
                              fill="white"
                            />
                          </svg>
                        )}
                      </div>
                      <div className="h-full">
                        <div>
                          <div className="flex gap-6">
                            {i.file.length == 0 &&
                              i.name.toLowerCase() == 'medical' && (
                                <div>
                                  <div className="flex items-center">
                                    <button
                                      className={`text-white  font-bold border-b rounded-md  relative h-[30px] flex items-center justify-center w-[35px] border-t border-l border-r border-white border-solid`}
                                    >
                                      <label>
                                        <input
                                          onChange={(e) => {
                                            const newData = [...reason].map(
                                              (item, index) =>
                                                index == ind
                                                  ? {
                                                      ...item,
                                                      file: [e.target.files[0]],
                                                    }
                                                  : { ...item }
                                            );
                                            setReason(newData);
                                          }}
                                          type="file"
                                          accept="application/pdf,application/vnd.ms-excel,application/vnd.ms-word,pdf, image/jpg, image/jpeg, image/png"
                                          className="w-[56px] opacity-0 py-2 absolute z-10 left-0 top-0"
                                          multiple
                                        />
                                      </label>
                                      <div className="absolute left-1/2 -translate-x-1/2">
                                        +
                                      </div>
                                    </button>
                                    <p className="ml-[14px] graphik-regular text-white text-sm font-normal">
                                      Attach medical certificate
                                    </p>
                                  </div>
                                </div>
                              )}
                            <div className="flex flex-col">
                              {i.file?.map((file, i) => (
                                <div className="flex items-center">
                                  <button
                                    className={`text-white  font-bold border-b rounded-md  relative h-[30px] flex items-center justify-center w-[35px]  border-t border-l  border-r border-white border-solid`}
                                    onClick={() => {
                                      const newData = [...reason].map(
                                        (item, index) =>
                                          index == i
                                            ? { ...item, file: [] }
                                            : { ...item }
                                      );
                                      setReason(newData);
                                    }}
                                  >
                                    <div className="absolute rotate-45 left-1/2 -translate-x-1/2">
                                      +
                                    </div>
                                  </button>
                                  <p className="ml-[14px] graphik-regular text-white text-sm font-normal">
                                    {file.name}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>
                          <input className="w-0 h-0" />
                        </div>
                      </div>
                      {i.name.toLowerCase() == 'medical' && (
                        <div className="justify-self-center gotham text-sm font-normal">
                          File type must be .jpg, .png, or
                          <br /> PDF. Total file size must be 5mb or
                          <br /> lower.{' '}
                        </div>
                      )}
                    </div>
                  </>
                )
              )}{' '}
            </>
          </div>
        )}

        <div className="mt-[30px] rounded-md border-[1.5px] border-[rgb(255,255,255,0.4)] hover:bg-[#1F2528]">
          <div className="border-b border-white font-bicyclette font-medium text-white px-6 tracking-[1.4px] py-[19.5px] uppercase">
            {userSelectedMembership?.isActive
              ? 'suspend period'
              : 'cancel Suspension'}
          </div>
          {!userSelectedMembership?.isActive && (
            <div className="pt-[18px] flex justify-between pb-[14px] mx-6  gap-x-[14px] items-center text-[#898c8d]">
              Are you sure you want to cancel the suspension?
              <div className="flex flex-row lg:flex-col justify-end items-end gap-6">
                <div
                  className="w-[180px] flex items-center justify-center cursor-pointer gotham text-sm font-bold uppercase rounded border  transition-all border-gray text-white  h-[36px] hover:bg-white hover:text-black"
                  onClick={() => {
                    setRevokeSuspendModal(true);
                  }}
                >
                  Revoke suspension
                </div>
                {/* <div
                                    onClick={() => setCancelStep(1)}
                                    className="w-[180px] flex items-center justify-center cursor-pointer gotham text-sm font-bold uppercase rounded border  transition-all border-gray text-white  h-[36px] hover:bg-white hover:text-black"
                                >
                                    yes, i'm sure
                                </div> */}
              </div>
            </div>
          )}

          {userSelectedMembership?.isActive && (
            <>
              <div className="pt-[24px] pb-[30px] mx-6 flex gap-x-[14px] text-sm items-center text-[#898c8d]">
                {/* <div className=' w-[40%] flex flex-col gap-[3px]'>
                    <div className='text-[#898c8d]'>Home club</div>
                    <div>Plus Fitness Tuggeranong</div>
                </div>
                <div className='w-[60%] flex flex-col gap-[3px]'>
                    <div className='text-[#898c8d]'>Per fortnight</div>
                    <div>$32.80</div>
                </div> */}
                <svg
                  width="31"
                  height="31"
                  viewBox="0 0 31 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="flex-none"
                >
                  <circle
                    cx="15.5"
                    cy="15.5"
                    r="14.5"
                    stroke="currentColor"
                    stroke-width="1.07487"
                  />
                  <path
                    d="M14.7151 9.24414H15.8018L15.6144 18.5935H14.9024L14.7151 9.24414ZM15.2584 22.4344C15.0461 22.4344 14.865 22.3595 14.7151 22.2096C14.5652 22.0597 14.4902 21.8786 14.4902 21.6662C14.4902 21.4539 14.5652 21.2728 14.7151 21.1229C14.865 20.973 15.0461 20.898 15.2584 20.898C15.4833 20.898 15.6706 20.973 15.8205 21.1229C15.9704 21.2728 16.0453 21.4539 16.0453 21.6662C16.0453 21.8786 15.9704 22.0597 15.8205 22.2096C15.6706 22.3595 15.4833 22.4344 15.2584 22.4344Z"
                    fill="currentColor"
                  />
                </svg>
                {reason.filter((i) => i.select).length > 0 &&
                reason.filter((i) => i.select)[0].name.toLowerCase() ===
                  'medical' ? (
                  <>
                    ONCE DETAILS HAVE BEEN SUBMITTED A TEAM MEMBER WILL REVIEW
                    YOUR DOCUMENT AND IF APPROVED,
                    <br />
                    YOUR REQUEST WILL BE PROCESSED.
                  </>
                ) : (
                  <>
                    We have a maximum suspension period of 90 days and a minimum
                    of 7 days.
                    <br />
                    {/* suspension cost: $2.50 per week (rounded to next full week
                      i.e. if you suspend for 8 days the suspendsion fee will be
                      $5.00)*/}
                    Your next suspension cannot start before your next Direct
                    Debit on {minDD ? handleDateISO(minDD?.toISOString()) : ''}
                  </>
                )}
              </div>
              <div className="grid grid-cols-2 gap-[18px] mx-6 mb-[42px]">
                <label
                  onClick={() => {
                    const selectedReason = reason.filter((i) => i.select);
                    if (selectedReason.length === 0) {
                      toastError('please select the reason first');
                    }
                  }}
                >
                  <div
                    className={`border-b flex h-[47px] flex-col pb-[2px]  relative ${
                      startDate !== '' ? 'border-b-gray' : 'border-b-gray'
                    } `}
                  >
                    <div className="absolute  bottom-[6px] w-full">
                      {reason.filter((i) => i.select).length > 0 && (
                        <Flatpickr
                          onChange={(dateObj, dateStr) => setStartDate(dateStr)}
                          // value={
                          //   startDate
                          //     ?
                          //         startDate
                          //     : null
                          // }
                          options={{
                            dateFormat: 'd/m/Y',
                            disableMobile: 'true',
                            enableTime: false,
                            time_24hr: false,
                            allowInput: false,
                            minDate:
                              reason
                                .filter((i) => i.select)[0]
                                .name.toLocaleLowerCase() ===
                              'standard suspension'
                                ? new Date(minDD)
                                : handleMedSusStart(),
                          }}
                        />
                      )}
                    </div>
                    <label
                      className={`gotham absolute left-0 ${
                        startDate !== ''
                          ? 'text-textSecondary text-[11px] top-[2px] sm:top-[-8px]'
                          : 'text-white bottom-[14px] text-sm'
                      }`}
                    >
                      Start date*
                    </label>
                    <div className="absolute  z-10 right-3 bottom-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_1_1209)">
                          <path
                            d="M4.09005 5.14832H4.09376C4.70159 5.14832 5.19434 4.65957 5.19434 4.05669L5.19434 1.08795C5.19434 0.485065 4.70159 -0.00366497 4.09376 -0.00366497H4.09005C3.48222 -0.00366497 2.98947 0.485065 2.98947 1.08795L2.98947 4.05669C2.98947 4.65957 3.48222 5.14832 4.09005 5.14832Z"
                            fill="white"
                          />
                          <path
                            d="M16.0813 5.14832H16.085C16.6928 5.14832 17.1855 4.65957 17.1855 4.05669V1.08795C17.1855 0.485065 16.6928 -0.00366497 16.085 -0.00366497H16.0813C15.4734 -0.00366497 14.9807 0.485065 14.9807 1.08795V4.05669C14.9807 4.65957 15.4734 5.14832 16.0813 5.14832Z"
                            fill="white"
                          />
                          <path
                            d="M1.52086 18.9999H18.4906C18.8923 18.9989 19.2772 18.84 19.5608 18.5579C19.8445 18.2758 20.0039 17.8937 20.0039 17.4953V3.57717C20.0039 3.17875 19.8445 2.79659 19.5608 2.51452C19.2772 2.23245 18.8923 2.07349 18.4906 2.07251H1.52086C1.11917 2.07349 0.734266 2.23245 0.450575 2.51452C0.166884 2.79659 0.00756715 3.17875 0.00756836 3.57717V17.4953C0.00756715 17.8937 0.166884 18.2758 0.450575 18.5579C0.734266 18.84 1.11917 18.9989 1.52086 18.9999V18.9999ZM1.72908 6.82989H18.2823V16.979H1.72164L1.72908 6.82989Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1_1209">
                            <rect width="20" height="19" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                </label>
                <label
                  onClick={() => {
                    !startDate && toastError('please select start date first');
                  }}
                >
                  <div
                    className={`border-b flex h-[47px] flex-col pb-[2px]  relative ${
                      endDate !== '' ? 'border-b-gray' : 'border-b-gray'
                    } `}
                  >
                    <div className="absolute  bottom-[6px] w-full">
                      {startDate && (
                        <Flatpickr
                          onChange={(dateObj, dateStr) => setEndDate(dateStr)}
                          value={
                            endDate
                              ? new Date(endDate.split('/').reverse().join('-'))
                              : null
                          }
                          options={{
                            dateFormat: 'd/m/Y',
                            disableMobile: 'true',
                            enableTime: false,
                            time_24hr: false,
                            allowInput: false,
                            maxDate: handleMaxSusEnd(),
                            minDate:
                              startDate &&
                              reason
                                .filter((i) => i.select)[0]
                                .name.toLocaleLowerCase() ===
                                'standard suspension'
                                ? new Date(
                                    startDate.split('/').reverse().join('-')
                                  ).fp_incr(6)
                                : new Date(
                                    startDate.split('/').reverse().join('-')
                                  ),
                          }}
                        />
                      )}
                    </div>
                    <label
                      className={`gotham  absolute left-0 ${
                        endDate !== ''
                          ? ' top-[2px] sm:top-[-8px] text-[11px]  text-textSecondary'
                          : 'text-white bottom-[14px] text-[14px]'
                      }`}
                    >
                      End date*
                    </label>
                    <div className="absolute  z-10 right-3 bottom-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_1_1209)">
                          <path
                            d="M4.09005 5.14832H4.09376C4.70159 5.14832 5.19434 4.65957 5.19434 4.05669L5.19434 1.08795C5.19434 0.485065 4.70159 -0.00366497 4.09376 -0.00366497H4.09005C3.48222 -0.00366497 2.98947 0.485065 2.98947 1.08795L2.98947 4.05669C2.98947 4.65957 3.48222 5.14832 4.09005 5.14832Z"
                            fill="white"
                          />
                          <path
                            d="M16.0813 5.14832H16.085C16.6928 5.14832 17.1855 4.65957 17.1855 4.05669V1.08795C17.1855 0.485065 16.6928 -0.00366497 16.085 -0.00366497H16.0813C15.4734 -0.00366497 14.9807 0.485065 14.9807 1.08795V4.05669C14.9807 4.65957 15.4734 5.14832 16.0813 5.14832Z"
                            fill="white"
                          />
                          <path
                            d="M1.52086 18.9999H18.4906C18.8923 18.9989 19.2772 18.84 19.5608 18.5579C19.8445 18.2758 20.0039 17.8937 20.0039 17.4953V3.57717C20.0039 3.17875 19.8445 2.79659 19.5608 2.51452C19.2772 2.23245 18.8923 2.07349 18.4906 2.07251H1.52086C1.11917 2.07349 0.734266 2.23245 0.450575 2.51452C0.166884 2.79659 0.00756715 3.17875 0.00756836 3.57717V17.4953C0.00756715 17.8937 0.166884 18.2758 0.450575 18.5579C0.734266 18.84 1.11917 18.9989 1.52086 18.9999V18.9999ZM1.72908 6.82989H18.2823V16.979H1.72164L1.72908 6.82989Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1_1209">
                            <rect width="20" height="19" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                </label>
              </div>
              <div className=" mx-7 flex justify-between mb-6 ">
                {/* <button
                        className="w-[180px] gotham text-[11.2px] uppercase rounded border  transition-all border-gray text-white  h-[36px] hover:bg-white hover:text-black"
                        onClick={() => {
                            // handleSuspend();
                            handleCheck();
                        }}
                    >
                        Suspend
                    </button>{" "} */}
                <div
                  onClick={() => {
                    // handleSuspend();

                    handleCheck();
                  }}
                  className="flex cursor-pointer text-white hover:bg-primary hover:border-primary hover:text-white justify-center items-center border border-white rounded-md w-[135px] h-[37px] text-xs font-bold"
                >
                  SUSPEND
                </div>

                <p className="inline-block text-sm text-white">
                  {startDate &&
                    endDate &&
                    calculateNumberOfWeeks(startDate, endDate)}
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default SuspendMembership;
