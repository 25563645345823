//@ts-nocheck
import { Router, useLocation } from '@reach/router';
import React, { useState, useEffect, useRef, useContext } from 'react';
import HomePage from '@/routes/HomePage';
import PrivateRoute from '../components/PrivateRoute';
import AdvertiseWithUsPage from '@/routes/AdvertiseWithUsPage';
import FreeTrial from '@/routes/FreeTrial';
import Access from '@/routes/Access';
import CareersPage from '@/routes/CareersPage';
import FeedbackPage from '@/routes/FeedbackPage';
import MembershipsPage from '@/routes/MembershipsPage';
import OwnAGymPage from '@/routes/OwnAGymPage';
import PrivacyPolicyPage from '@/routes/PrivacyPolicyPage';
import BlogPage from '@/routes/BlogPage';
import BlogDetailPage from '@/routes/BlogDetailPage';
import GymsPage from '@/routes/GymsPage';
import GymDetailPage from '@/routes/GymDetailPage';
import PlusFitnessMediaPage from '@/routes/PlusFitnessMediaPage';
import MediaDetailPage from '@/routes/MediaDetailPage';
import { HeadFC, navigate } from 'gatsby';
import { SEO } from '@/components/SEO';
import useFetch from '@/hooks/useFetch';
import register from '@/routes/Register';
import Register from '@/routes/Register';
import { StoreProvider } from '../context/RegisterContext';
import Dashboard from '@/routes/DashBoard';
import Timetable from '@/components/DashBoard/DashboardPages/Timetable';
import Memberships from '@/components/DashBoard/DashboardPages/Memberships';
import Account from '@/routes/DashBoard/Account';
import { AuthContext, AuthProvider } from '../api/auth';
import ClubSearch from '@/routes/DashBoard/ClubSearch';
import MyBookings from '@/routes/DashBoard/MyBookings';
import { Toaster } from 'react-hot-toast';
// import "react-toastify/dist/ReactToastify.css";
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { Amplify, Auth } from 'aws-amplify';
import { setContext } from '@apollo/client/link/context';
import { createHttpLink } from 'apollo-link-http';
import { createAuthLink } from 'aws-appsync-auth-link';
import { ApolloLink } from 'apollo-link';
import Login from '@/routes/Login';
import { toastSuccess } from '@/common/toasts/toast';
import NotFoundPage from '@/components/404';
import ForgotPassword from '@/routes/ForgotPassword';
import OptOutPage from '@/routes/OptOutPage';
import ConsentFormPage from '@/routes/ConsentFormPage';
import TermsAndConditionApprovalPage from '@/routes/TermsAndConditionsApprovalPage';

import ApolloWrapper from '@/api/apolloWrapper';
import PublicRoute from '@/components/PublicRoute';
import { Helmet } from 'react-helmet';
import { MembershipProvider } from '@/context/MembershipContext';

const IndexPage = () => {
  return (
    <AuthProvider>
      <Toaster position="top-right" reverseOrder={false} />
      <ApolloWrapper>
        <StoreProvider>
          <MembershipProvider>
            <Helmet>
              <noscript>
                {`
            <iframe src=https://www.googletagmanager.com/ns.html?id=GTM-W6LRZL7
            height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
              </noscript>
              <script>{`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-W6LRZL7');
        `}</script>
            </Helmet>
            <Router basepath="/">
              {/* Protected Routes */}
              {/* <PrivateRoute component={HomePage} path="/" />
              <PrivateRoute component={AdvertiseWithUsPage} path="/advertise-with-us" />
              <PrivateRoute component={FreeTrial} path="/free-3-day-trial" />
              <PrivateRoute component={CareersPage} path="/careers" />
              <PrivateRoute component={FeedbackPage} path="/contact-plus-fitness" />
              <PrivateRoute component={MembershipsPage} path="/memberships" />
              <PrivateRoute component={OwnAGymPage} path="/own-a-gym" />
              <PrivateRoute component={PrivacyPolicyPage} path="/privacy-policy" />
              <PrivateRoute
                component={TermsAndConditionPage}
                path="/terms-and-conditions"
              />
              <PrivateRoute component={BlogPage} path="/blog" />
              <PrivateRoute component={BlogDetailPage} path="/blog/:slug" />
              <PrivateRoute component={GymsPage} path="/gyms" />
              <PrivateRoute component={GymDetailPage} path="/gyms/:slug" />
              <PrivateRoute
                component={PlusFitnessMediaPage}
                path="/plus-fitness-media"
              />
              <PrivateRoute
                component={MediaDetailPage}
                path="/plus-fitness-media/:slug"
              /> */}
              {/* <PrivateRoute component={register} path="/register" /> */}

              <PrivateRoute component={Dashboard} path="/dashboard" />
              <PrivateRoute
                component={Timetable}
                path="/dashboard/class/timetable"
              />
              <PrivateRoute
                component={Memberships}
                path="/dashboard/memberships"
              />
              <PrivateRoute component={Account} path="/dashboard/account" />

              <PrivateRoute
                component={ClubSearch}
                path="/dashboard/club-search"
              />
              <PrivateRoute
                component={MyBookings}
                path="/dashboard/class/bookings"
              />
              {/* Public Routes */}
              {/* <PrivateRoute component={Login} path="/login" /> */}
              <PublicRoute component={Login} path="/login" />
              <PublicRoute component={ForgotPassword} path="/forgot-password" />
              <PublicRoute component={Register} path="/register" />
              <PublicRoute component={OptOutPage} path="/opt-out" />
              <PublicRoute component={ConsentFormPage} path="/consent-form" />
              <PublicRoute
                component={TermsAndConditionApprovalPage}
                path="/terms-conditions"
              />

              {/* <Login path="/login" /> */}
              {/* <Login path="/log\in" reCreateClient={Init} /> */}
              {/* <ForgotPassword path="/forgot-password" /> */}
              {/* <Register path="/register" /> */}
              {/* <OptOutPage path="/opt-out" /> */}
              {/* <ConsentFormPage path="/consent-form" /> */}
              {/* <Access path="/access" /> */}
              <NotFoundPage path="*" />
            </Router>
          </MembershipProvider>
        </StoreProvider>
      </ApolloWrapper>
    </AuthProvider>
  );
};

export default IndexPage;

export const Head: HeadFC = () => {
  const path = useLocation();
  const [content, setContent] = useState<string>('');
  const { data: seo } = useFetch({ params: 'global', populate: 'seo' });

  useEffect(() => {
    if (path.pathname.replace(/\//g, '') == '') {
      setContent(seo?.data?.attributes?.seo?.[0]);
    } else {
      setContent(
        seo?.data?.attributes?.seo?.find(
          (item) => item?.key == path.pathname.replace(/\//g, '')
        )
      );
    }
  }, [seo, path.pathname]);

  return <SEO title={'PLUS FITNESS'} description={content?.value} />;
};
