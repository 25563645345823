import moment from 'moment';

export const validateApplyVoucher = (
  voucherData: object,
  currentVoucherDetail: object,
  membershipDetail: object,
  setError: (err: string) => void,
  setNewvoucherDetail: (value: object) => void,
  setAmount: (amount: number) => void,
  memberId: string,
  memberDetail: object
) => {
  const voucherType = voucherData?.getVoucherByVoucherCode?.items[0]?.type;
  const voucherInvitation =
    voucherData?.getVoucherByVoucherCode.items[0]?.voucherInvitations?.items.filter(
      (item) => item.memberId === memberId
    );
  const voucherLocation =
    voucherData?.getVoucherByVoucherCode.items[0]?.voucherLocation.items.filter(
      (item) => item.locationId === memberDetail?.homeLocationId
    );
  if (voucherData?.getVoucherByVoucherCode.items.length) {
    if (
      currentVoucherDetail?.id !==
      voucherData?.getVoucherByVoucherCode.items[0].id
    ) {
      if (
        (voucherType === 'FIXED' || voucherType === 'PERCENT') &&
        voucherData?.getVoucherByVoucherCode.items[0].membershipDetail.id !==
          membershipDetail.membershipId
      ) {
        setError('This voucher is not valid for this membership!');
        setNewvoucherDetail({});
      } else if (
        voucherData?.getVoucherByVoucherCode.items[0]?.used >=
        voucherData?.getVoucherByVoucherCode.items[0]?.quantity
      ) {
        setError('Voucher limit exceded!');
        setNewvoucherDetail({});
      } else if (
        voucherData?.getVoucherByVoucherCode.items[0]?.endDateTime &&
        moment().isAfter(
          moment(voucherData?.getVoucherByVoucherCode.items[0]?.endDateTime)
        )
      ) {
        setError('This voucher has expired!');
        setNewvoucherDetail({});
      } else {
        if (
          voucherData?.getVoucherByVoucherCode.items[0].memberType === 'NEW' ||
          voucherData?.getVoucherByVoucherCode.items[0].memberType ===
            'PREVIOUS'
        ) {
          setError('This voucher is not valid for existing member!');
          setNewvoucherDetail({});
        } else {
          if (!voucherLocation.length) {
            setError('This voucher is not valid for this location!');
            setNewvoucherDetail({});
          } else {
            if (
              !voucherInvitation.length ||
              voucherData?.getVoucherByVoucherCode.items[0].type ===
                'VISIT_PASS' ||
              voucherData?.getVoucherByVoucherCode.items[0].type ===
                'COMPLIMENTARY'
            ) {
              setError('This voucher is not valid for this member!');
              setNewvoucherDetail({});
            } else {
              if (!voucherData?.getVoucherByVoucherCode.items[0]?.isActive) {
                setError('This voucher is not active!');
                setNewvoucherDetail({});
              } else {
                setNewvoucherDetail(
                  voucherData?.getVoucherByVoucherCode.items[0]
                );
                setAmount(
                  voucherData?.getVoucherByVoucherCode.items[0].passFee +
                    voucherData?.getVoucherByVoucherCode.items[0]
                      .activationFee +
                    voucherData?.getVoucherByVoucherCode.items[0].joiningFee
                );
                setError('');
              }
            }
          }
        }
      }
    } else {
      setError('This voucher has been used');
      setNewvoucherDetail({});
    }
  } else {
    setError('This voucher does not exist');
    setNewvoucherDetail({});
  }
};
