export const calculateDateFromSpecificDate = ({
  type,
  amount,
  date,
}: {
  type: string;
  amount: number;
  date: string;
}) => {
  const currentDate = date ? new Date(date) : new Date();
  if (type === 'DAYS') {
    currentDate.setDate(currentDate.getDate() + amount);
  } else if (type === 'MONTHS') {
    currentDate.setMonth(currentDate.getMonth() + amount);
  } else if (type === 'YEARS') {
    currentDate.setFullYear(currentDate.getFullYear() + amount);
  } else if (type === 'WEEKS') {
    currentDate.setDate(currentDate.getDate() + amount * 7);
  }

  return currentDate;
};
