import React, { useState, useEffect, useRef } from 'react';
// import warnCircle from "../../assets/images/icons/warnCircle.svg";

type selectDropdownInfo = {
  style: string;
  data: Array<string>;
  title: string;
  titleStyle: string;
  placeholder: string;
  // placeholderColor: string;
  isTitle: boolean;
  isShowRequired: boolean;
  onSelect: any;
  onChange: any;
  name: string;
  value: string;
  revertColor?: boolean;
  selectedLabel?: boolean;
};
function SelectOutline({
  style,
  data = [],
  title,
  titleStyle,
  placeholder,
  contact,
  setContact,
  name,
  onChange,
  value,
  selectedLabel = false,

  // placeholderColor,
  isTitle = false,
  isShowRequired = false,
  onSelect = () => null,
  revertColor = false,
}: selectDropdownInfo) {
  const [isShow, setShow] = useState(false);
  const [selected, setSelected] = useState('');
  // const [dropdownSelected, setDropdownSelected] = useState(false);
  useEffect(() => {
    value && setSelected(value);
  }, [value]);
  useEffect(() => {
    if (isTitle) setSelected(data[0]);
  }, [data]);
  useEffect(() => {
    onSelect(selected);
    setShow(false);
  }, [selected]);
  const target = useRef<any>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (isShow && target.current && !target.current?.contains(e.target)) {
        setShow(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isShow]);

  return (
    <div
      ref={target}
      onClick={() => setShow(!isShow)}
      className="cursor-pointer"
    >
      {!isTitle ? (
        <div className="relative ">
          <span
            className={`absolute  block text-xs sm:text-base gotham font-medium transition-all duration-300 ${
              selected
                ? ' text-white sm:-top-[5px] '
                : 'top-[20px] left-0 text-textSecondary'
            } ${revertColor ? ' text-white ' : ' text-textSecondary '} ${
              selected && selectedLabel ? '!text-textSecondary' : ''
            }`}
          >
            {placeholder}
          </span>
          <div
            className={`flex items-end bg-transparent border-b cursor-pointer w-full focus:ring-1 pb-[7px] ${style} ${
              selected
                ? 'border-b-[#d3d3d3] text-[#d3d3d3] justify-between '
                : 'border-b-borderLine  text-[#FFFFFF] justify-end'
            } `}
          >
            {isShowRequired && !selected ? (
              <div className="gotham  absolute mr-[40px] right-[20px] bottom-3 flex items-center font-normal text-xs text-[#FF6900]">
                This Field is required
                <img className="w-4 ml-[12px]" src={'/icons/warn-circle.svg'} />
              </div>
            ) : (
              ''
            )}
            <div className="cursor-pointer">
              <p
                onChange={onChange}
                name={name}
                className={`outline-none text-sm w-full sm:text-[16px] bg-transparent ${
                  revertColor ? ' text-textSecondary ' : ' '
                } ${selectedLabel ? '!text-white' : ''}`}
              >
                {selected}
              </p>
            </div>

            <div
              className={`w-[10px] h-[10px]  mr-4 border-l-[2px] border-b-[2px] mb-[7px] transition-all duration-150 -rotate-45 ${
                selected ? 'border-primary' : 'border-primary'
              } ${isShow ? 'border-primary rotate-[135deg]' : ''}`}
            ></div>
          </div>
          <div
            className={`w-full bg-black border-slate-400 text-white border-2 absolute z-40 ${
              isShow ? '' : 'hidden'
            }`}
          >
            {data.map((item: any, index: number) => (
              <div
                key={index}
                className="cursor-pointer text-start text-secondaryWhite px-6 py-4 hover:bg-slate-400"
                onClick={() => {
                  setSelected(item);
                  setContact && setContact({ ...contact, [name]: item });
                  // setDropdownSelected(true);
                }}
              >
                {item}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="relative ">
          <span
            className={` after:ml-0.5 after:text-white block  font-medium graphik-regular text-white ${titleStyle}`}
          >
            {title}
          </span>

          <div
            className={`
      flex items-center mt-1 px-6 py-4 bg-black border cursor-pointer w-full focus:ring-1 justify-between  ${style} ${
              selected
                ? 'border-primary text-white '
                : 'border-[#FFFFFF] text-textSecondary '
            } `}
          >
            {selected ? selected : placeholder}
            <div
              className={`w-[10px] h-[10px] border-l-[2px] border-b-[2px] transition-all duration-150 -rotate-45 text-primary ${
                isShow ? 'rotate-[135deg]' : ''
              }`}
            ></div>
          </div>
          <div
            className={`w-full bg-black border-slate-400 text-white border-2 absolute z-40 ${
              isShow ? '' : 'hidden'
            }`}
          >
            {data.map((item: any) => (
              <div
                className="cursor-pointer px-6 py-4 hover:bg-slate-400"
                onClick={() => {
                  setSelected(item);
                }}
              >
                {item}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default SelectOutline;
