import { AuthContext } from '@/api/auth';
import { Auth } from 'aws-amplify';
import { navigate } from 'gatsby';
import React, { useContext, useEffect } from 'react';

const PublicRoute = ({
  component: Component,
  location,
  ...rest
}: {
  component: React.FunctionComponent<any>;
  location: any;
}) => {
  useEffect(() => {
    // if (!getCookie(accessKey) && location.pathname !== accessRoute) {
    //   navigate(accessRoute);
    // }
    signIn();
  }, []);
  const getToken = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      return true;
    } catch (e) {
      return false;
    }
  };
  const signIn = async () => {
    const token = await getToken();
    if (token) {
      navigate('/dashboard');
    }
  };
  return <Component {...rest} />;
};

export default PublicRoute;
