import moment from 'moment';

export const validateChangeVoucher = (
  voucherData: object,
  memberId: string,
  memberDetail: object,
  info: object,
  setVoucherDetail: (value: object) => void,
  toastError: (error: string) => void,
  toastSuccess: (error: string) => void,
  voucherCode: string,
  membership: object
) => {
  const voucher = voucherData?.getVoucherByVoucherCode.items[0];

  const isVoucherValidForMember = () => {
    if (!voucher) {
      toastError('This voucher does not exist!');
      return false;
    }
    if (
      voucherCode &&
      voucher.membershipDetail.id !== info.memberDetails.NewMembershipId
    ) {
      toastError('This voucher is not valid for this membership!');
      return false;
    }
    if (
      (Object.keys(membership?.[0]).length > 0 &&
        voucherData?.getVoucherByVoucherCode.items[0].memberType ===
          'PREVIOUS') ||
      (Object.keys(membership?.[0]).length === 0 &&
        voucherData?.getVoucherByVoucherCode.items[0].memberType === 'EXIST')
    ) {
      toastError('This voucher is not valid for this member!');
      return false;
    }
    if (voucher.used >= voucher.quantity) {
      toastError('Voucher limit exceeded!');
      return false;
    }
    if (moment().isAfter(moment(voucher.endDateTime))) {
      toastError('This voucher has expired!');
      return false;
    }
    if (
      voucher.paymentDelay?.amount !== 0 &&
      Object.keys(membership?.[0]).length > 0
    ) {
      toastError('This voucher has payment delay!');
      return false;
    }
    return true;
  };

  const isVoucherValidForLocation = () => {
    const voucherLocation = voucher?.voucherLocation.items.find(
      (item) => item.locationId === memberDetail.getMember.homeLocationId
    );
    if (!voucherLocation) {
      toastError('This voucher is not valid for this location!');
      return false;
    }
    return true;
  };

  const isVoucherActive = () => {
    if (!voucher?.isActive) {
      toastError('This voucher is not active');
      return false;
    }
    return true;
  };
  const voucherInvitation = voucher?.voucherInvitations?.items.filter(
    (item) => item.memberId === memberId
  );
  const isVoucherValidForInvitation = () => {
    if (
      !voucherInvitation.length ||
      (voucher.type === 'VISIT_PASS' && Object.keys(membership?.[0]).length > 0)
    ) {
      toastError('This voucher is not valid for this member!');
      return false;
    }
    return true;
  };

  if (
    isVoucherValidForMember() &&
    isVoucherValidForLocation() &&
    isVoucherActive() &&
    isVoucherValidForInvitation()
  ) {
    toastSuccess('Applied Voucher Successfully');
    setVoucherDetail(voucher);
  } else {
    setVoucherDetail({});
  }
};
