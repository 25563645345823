import React, { useEffect, useMemo, useState } from 'react';

import { Auth } from 'aws-amplify';
import { navigate } from 'gatsby';

import { AuthContext } from '@/api/auth';
import ArrowButton from '@/common/buttons/ArrowButton';
import CheckboxActive from '@/common/buttons/CheckboxActive';
import MobileButton from '@/common/buttons/MobileButton';
import InputBase from '@/common/inputs/InputBase';
import OTPInput from '@/common/inputs/OTPInput';
import { toastError } from '@/common/toasts/toast';
import { breakpoints } from '@/constants';
import useWindowSize from '@/hooks/useWindowSize';

function Login() {
  const Init = React.useContext(AuthContext).Init;

  const { width } = useWindowSize();
  // const status = "login";

  const [account, setAccount] = useState({ email: '', password: '' });
  const [otpValue, setOtpValue] = useState('');
  const [step, setStep] = useState(0);
  const [session, setSession] = useState<any>();

  const [isLoading, setLoading] = useState(false);
  const [otpType, setOtpType] = useState('LAND');

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const [OTPExpire, setOtpExpire] = useState({ seconds: 0, minutes: 0 });

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAccount({ ...account, [e.target.name]: e.target.value });
  };
  const [atemp, setAtemp] = useState(0);
  const verifyOtp = async (otp: string) => {
    setLoading(true);
    try {
      await Auth.sendCustomChallengeAnswer(session, otp);
      await Auth.currentAuthenticatedUser();
      setSession(null);
      await Init();
      window.localStorage.setItem('userInfo', 'Deval');
      window.localStorage.setItem('login', 'yes');
      navigate(`/dashboard`);
    } catch (err) {
      setOtpValue('');
      if (atemp === 2) {
        setSession(null);
        setStep(0);
        setAtemp(0);
        toastError(
          'You have entered invalid OTP many time please try again with your email '
        );
      } else {
        toastError('INVALID OTP!');

        setAtemp(atemp + 1);
      }
    }
    setLoading(false);
  };

  const handleSubmitEmail = async () => {
    try {
      setLoading(true);
      if (account.email) {
        const data = await Auth.signIn(account.email.trim());
        setSession(data);
        setOtpExpire({ seconds: 0, minutes: 0 });
        setSeconds(0);
        setMinutes(0);
        setStep(step + 1);
        setOtpType('LAND');
      } else {
      }
    } catch (e: any) {
      toastError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const resend = async (loginType: string) => {
    try {
      // const data = await Auth.signIn(account.email);
      // setSession(data);
      await Auth.sendCustomChallengeAnswer(session, '0', { loginType });

      setOtpType(loginType);
      setSeconds(60);
      setOtpExpire({ seconds: 60, minutes: 4 });
    } catch (e) {
      setOtpType('ERROR');
      // toastError(e.message);
      if (e.message.includes('VERIFY_PHONE_FAILED')) {
        setStep(-1);
      }
      // toastError(
      //   'There is ann error when send otp to your ' +
      //     loginType +
      //     ' please try another method'
      // );
    }
  };

  const OTPMessage = useMemo(() => {
    if (otpType === 'LAND')
      return (
        <>
          Please select one of the option below to receive 6 digit code
          <br /> and then input the code to login.
        </>
      );
    if (otpType === 'APP')
      return (
        <>
          We’ve sent a 6-Character code to your Members ID App via Notification
          <br /> message. Please check in-app messages if you have notifications
          turned off.
        </>
      );
    if (otpType === 'EMAIL')
      return (
        <>
          {' '}
          We’ve sent a 6-Character code to your Email.
          <br /> Please check your mailbox.
        </>
      );
    if (otpType === 'SMS')
      return (
        <>
          {' '}
          We’ve sent a 6-Character code to your SMS.
          <br /> Please check the SMS on your phone.
        </>
      );
    if (otpType === 'ERROR')
      return (
        <p className="text-textError">
          {' '}
          We are trying to send a 6-Character code.
          <br />
          But there is an error please try again or use a different method.
        </p>
      );
  }, [otpType]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (OTPExpire.seconds > 0) {
        setOtpExpire({ ...OTPExpire, seconds: OTPExpire.seconds - 1 });
      }

      if (OTPExpire.seconds === 0) {
        if (OTPExpire.minutes === 0) {
          clearInterval(interval);
        } else {
          setOtpExpire({ minutes: OTPExpire.minutes - 1, seconds: 59 });
          // setOtpExpire({...minutes:OTPExpire.minutes - 1});
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [OTPExpire.seconds]);

  return (
    <>
      {isLoading && (
        <div className="fixed flex items-center h-[100vh] w-[100vw] z-50 bg-[rgba(212,212,212,0.2)] ">
          <div className="animate-spin h-12 w-12  border-r-2 border-2 border-gray border-r-[#2757FF] rounded-full m-auto "></div>
        </div>
      )}
      <div
        style={{
          background: `${
            width && width <= breakpoints.sm
              ? 'url(/images/LoginPage/login-mobile-background.png),linear-gradient(to bottom, #000 0%, #13181b 50%, #16182b 66%, #151947 100%)'
              : ''
          }`,
        }}
        className="bg-richBlack sm:bg-none h-[100vh] md:px-6 px-0 w-[100vw] flex items-center justify-center !bg-center !bg-no-repeat !bg-cover"
      >
        <div className="flex flex-col gap-6 sm:gap-7 z-20 px-[20px] overflow-hidden">
          <div className="hidden sm:block h-24 w-fit mb-8 mt-4">
            <img
              className="block w-full object-contain"
              src="/icons/PF-logo_stacked.png"
              alt="FF-logo_stacked"
            />
          </div>
          <div className="w-[495px] h-[47px]">
            <img
              className="block sm:hidden  mx-auto w-full h-full object-contain"
              src="/icons/pf-logo-line.svg"
            />
          </div>

          <p className="hidden sm:block text-primary text-4xl leading-[38.5px] font-bicyclette font-bold uppercase">
            member portal login
          </p>
          <div className="block sm:hidden text-white uppercase md:text-[28px] text-[40px] leading-[80%] text-center mb-6 md:mt-0 mt-6 font-bicyclette font-bold">
            Member login
          </div>
          {step === 0 && (
            <>
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  handleSubmitEmail();
                }}
                className="flex flex-col gap-6"
              >
                <button hidden type="submit"></button>
                <InputBase
                  onchange={handleChangeValue}
                  nameInput="email"
                  holder="Email address"
                  style="lg:w-full"
                  isRequired={true}
                  type="text"
                  revertColor
                />
              </form>
              <div className="flex justify-between items-center sm:mb-8">
                <div className="flex gap-[12px] items-center">
                  <CheckboxActive />
                  <p className="text-white text-sm ">Remember me</p>
                </div>
                <div className="block sm:hidden">
                  <ArrowButton
                    text="LOGIN"
                    isRight={true}
                    onclick={() => handleSubmitEmail()}
                  />
                </div>
              </div>
              <MobileButton
                onClick={handleSubmitEmail}
                className="uppercase hidden sm:block"
              >
                LOG IN
              </MobileButton>
              <p className="block  text-white text-[16px] font-medium text-center">
                Don't have an account?{' '}
                <span
                  className="cursor-pointer text-[16px] font-medium underline"
                  onClick={() => navigate('/register')}
                >
                  Join now
                </span>
              </p>
            </>
          )}
          {step === 1 && (
            <>
              <p className="text-center text-base text-white">
                {OTPMessage}
                {(OTPExpire.seconds > 0 || OTPExpire.minutes > 0) && (
                  <span>
                    <br />
                    Your Code will be expired in:{' '}
                    {OTPExpire.minutes < 10
                      ? `0${OTPExpire.minutes}`
                      : OTPExpire.minutes}
                    :
                    {OTPExpire.seconds < 10
                      ? `0${OTPExpire.seconds}`
                      : OTPExpire.seconds}
                  </span>
                )}
              </p>
              <OTPInput
                autoFocus
                isNumberInput
                length={6}
                value={otpValue}
                onChangeOTP={(otp: string) => {
                  setOtpValue(otp);
                  if (otp.length === 6) verifyOtp(otp);
                }}
              />
              {/* <ArrowButton
                text="
              LOG IN
              "
                isRight
                onClick={() => verifyOtp(otp)}
                className="uppercase justify-self-end "
                disabled={otp.length < 6}
              ></ArrowButton> */}
              <div className="flex justify-evenly text-white">
                {seconds > 0 || minutes > 0 ? (
                  <p>
                    Didn't receive the code try again after{' '}
                    {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </p>
                ) : (
                  <>
                    <span
                      className="text-white underline cursor-pointer"
                      onClick={() => {
                        resend('EMAIL');
                      }}
                    >
                      Send Code
                    </span>

                    <span
                      className="text-white underline cursor-pointer"
                      onClick={() => {
                        resend('APP');
                        // setMinutes(1);
                      }}
                    >
                      Send by APP
                    </span>

                    {/* <span
                      className="text-white underline cursor-pointer"
                      onClick={() => {
                        resend('SMS');
                      }}
                    >
                      Resend by SMS
                    </span> */}
                  </>
                )}
              </div>
            </>
          )}
          {step === -1 && (
            <div className="text-center">
              <p className="text-white  text-lg">
                You haven't verified your phone number. <br /> Do you want to
                try another way to login ?
              </p>
              <span
                className="text-white underline cursor-pointer"
                onClick={() => {
                  setOtpType('EMAIL');
                  setSession(null);
                  setStep(0);
                }}
              >
                try again
              </span>
            </div>
          )}
        </div>
        <div className="block sm:hidden absolute bottom-0 w-[100vw] h-[44vh] bg-gradient-to-t to-[rgba(36,51,213,0.0)] from-[rgba(36,51,213,0.34)]"></div>
      </div>
    </>
  );
}

export default Login;
