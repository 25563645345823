import React, { useState, useRef, useEffect } from 'react';
import { navigate } from 'gatsby';
import Details from '../components/joining-process/Details';
import Payment from '../components/joining-process/Payment';
import logo from '../assets//images/logo.svg';
import Memberships from '@/components/joining-process/Memberships';
import Health from '@/components/joining-process/Health';
import YourMembership from '@/components/joining-process/YourMembership';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import Congrats from '@/components/joining-process/Congrats';
import {
  fullMemberData,
  healthQuestionsByBrandId,
  listBrands,
  listMemberships,
  preRegisterMutation,
  SignupMemberOver18,
  registerMutationUnder18,
  preRegisterMutationUnder18,
} from '@/api/mutation';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  toastDismiss,
  toastError,
  toastLoading,
  toastSuccess,
} from '@/common/toasts/toast';
import { Auth } from 'aws-amplify';
import InputBase from '@/common/inputs/InputBase';
import ArrowButton from '@/common/buttons/ArrowButton';
import ModalBase from '@/common/modals/ModalBase';
import MyReactQuill from '@/components/MyReactQuill';
import useFetch from '@/hooks/useFetch';
import useWindowSize from '@/hooks/useWindowSize';
import { breakpoints } from '@/constants';
import moment from 'moment';
import banner from '../assets/images/banner.png';

interface IDataTermsAndConditionPage {
  title: string;
  content: string;
}

function register() {
  const [percent, setPercent] = useState<number>(20);
  const [current, setCurrent] = useState<number>(0);
  const [isApiSignUpFail, setIsApiSignUpFail] = useState<boolean>(false);
  const [autoSignUp, setAutoSignUp] = useState<boolean>(false);
  const [closeModalAfterAutoSignUp, setCloseModalAfterAutoSignUp] =
    useState<boolean>(false);
  const [loadingAutoSignUp, setLoadingAutoSignUp] = useState<boolean>(false);
  const swiperRef = useRef<Swiper | null>(null);
  const handleSlideClick = (index: number) => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index);
    }
    if (index === 5) {
      updateOrSaveToStrapi(true);
    }
  };
  const handleSlideChange = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      const currentIndex = swiperRef.current.swiper.activeIndex;
      setCurrent(currentIndex);
      currentIndex <= 4 && setPercent(25 * (currentIndex + 1));
    }
  };
  const [input, setInput] = useState({
    memberDetails: {
      address: '',
      brandId: '',
      country: '',
      dob: '',
      email: '',
      gender: '',
      givenName: '',
      homeLocationId: '',
      joinLocationId: '',
      mobileNumber: '',
      postCode: 2000,
      state: '',
      suburb: '',
      surname: '',
    },
    membershipId: '',
    voucherCode: '',
    membershipLocationId: '',
  });
  const [contact, setContact] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    phone: null,
    dob: '',
  });
  const [voucherDetail, setVoucherDetail] = useState({});
  const [useBank, setUseBank] = useState<boolean>(false);
  const qr = preRegisterMutation;
  const [signupMemberPrePaymentOver18] = useMutation(qr);

  const qru18 = preRegisterMutationUnder18;
  const [signupMemberPrePaymentUnder18] = useMutation(qru18);

  const su = registerMutationUnder18;
  const [
    signupMemberUnder18,
    { loading: loadingUnder18, error: errorUnder18 },
  ] = useMutation(su);

  const fn = SignupMemberOver18;
  const [signupMemberOver18, { loading: loadingOver18, error: errorOver18 }] =
    useMutation(fn);

  const { data: dataPromotionBanner } = useFetch({
    params: 'global',
    populate: 'promotion, promotion.image',
  });
  const isShowPromotionBanner = React.useMemo(() => {
    return moment(moment().format('YYYY-MM-DD')).isBetween(
      dataPromotionBanner?.data?.attributes?.promotion?.startDate,
      dataPromotionBanner?.data?.attributes?.promotion?.endDate,
      undefined,
      '[]'
    );
  }, [dataPromotionBanner]);

  const handleCallApiPrePayment = async (inputsData: any) => {
    let data = {};
    try {
      const userAge = moment(new Date()).diff(
        moment(inputsData.memberDetails?.dob),
        'years'
      );
      setLoading(true);
      let payload = {
        ...inputsData,
        //move
        membershipStartDate: new Date(input?.membershipStartDate).toISOString(),
      };
      delete payload?.cardDetails;
      delete payload?.paymentType;
      delete payload?.prospectId;
      delete payload?.memberId;

      if (userAge < 18) {
        payload = { ...payload };
        data = await signupMemberPrePaymentUnder18({
          variables: {
            input: { ...payload },
          },
        });
        if (!data?.data.signupMemberViaWebPreparePaymentUnder18.prospectId) {
          localStorage.removeItem('payment');
          throw Error(
            data?.data.signupMemberViaWebPreparePaymentUnder18.message
          );
        } else {
          localStorage.setItem(
            'payment',
            JSON.stringify(data?.data?.signupMemberViaWebPreparePaymentUnder18)
          );
          handleSlideClick(3);
          // if (membershipType === 'weekly') handleSlideClick(4);
          // else handleSlideClick(3);
        }
      } else {
        data = await signupMemberPrePaymentOver18({
          variables: {
            input: { ...payload },
          },
        });

        if (!data?.data.signupMemberViaWebPreparePaymentOver18.prospectId) {
          localStorage.removeItem('payment');
          throw Error(
            data?.data.signupMemberViaWebPreparePaymentOver18.message
          );
        } else {
          localStorage.setItem(
            'payment',
            JSON.stringify(data?.data?.signupMemberViaWebPreparePaymentOver18)
          );
          handleSlideClick(3);
          // if (membershipType === 'weekly') handleSlideClick(4);
          // else handleSlideClick(3);
        }
      }

      return data;
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoading(false);
    }
    return data;
  };
  const handleCallApiUnder18 = async (inputsData: any) => {
    let data = {};
    try {
      if (!autoSignUp) {
        toastLoading(
          'Please do not close the browser until the transaction has been confirmed.',
          window.innerHeight <= 900
            ? { marginTop: '0px' }
            : { marginTop: '140px' }
        );
      }

      setIsLoadingSignUpUnder18(true);
      let payload = { ...inputsData };
      delete payload?.paymentType;
      payload = { ...payload };
      data = await signupMemberUnder18({
        variables: {
          input: { ...payload },
        },
      });
      if (data?.data.signupMemberViaWebUnder18.error) {
        setIsLoadingSignUpUnder18(false);
        setIsPaymentSuccess(false);
        throw Error(data?.data.signupMemberViaWebUnder18.error);
      } else if (data?.data.signupMemberViaWebUnder18.memberId) {
        if (!autoSignUp) {
          setSuccessLoadingUnder18(true);
          setTimeout(() => {
            setSuccessLoadingUnder18(false);
            toastDismiss();
            handleSlideClick(5);
            setIsPaymentSuccess(false);
            toastSuccess('successfully sign up account');
          }, 3000);
        }
      } else if (data?.data.signupMemberViaWebUnder18.memberId) {
        toastDismiss();
        setIsPaymentSuccess(false);
      }
      return data;
    } catch (e) {
      toastDismiss();
      toastError(e.message);
      setIsPaymentSuccess(false);
    } finally {
      setIsLoadingSignUpUnder18(false);
    }
    return data;
  };
  const handleCallApiOver18 = async (inputsData: any) => {
    try {
      if (!autoSignUp) {
        toastLoading(
          'Please do not close the browser until the transaction has been confirmed.',
          window.innerHeight <= 900
            ? { marginTop: '0px' }
            : { marginTop: '140px' }
        );
      }

      setIsLoadingSignUpOver18(true);
      let payload = { ...inputsData };
      const data = await signupMemberOver18({
        variables: {
          input: { ...payload },
        },
      });
      if (data?.data.signupMemberViaWebOver18.error) {
        setIsLoadingSignUpOver18(false);
        setIsPaymentSuccess(false);
        throw Error(data?.data.signupMemberViaWebOver18.error);
      } else if (data?.data.signupMemberViaWebOver18.memberId) {
        if (!autoSignUp) {
          setSuccessLoadingOver18(true);
          setTimeout(() => {
            setSuccessLoadingOver18(false);
            handleSlideClick(5);
            setIsPaymentSuccess(false);
            toastDismiss();
            toastSuccess('successfully sign up account');
          }, 3000);
        }
      } else if (!data?.data.signupMemberViaWebOver18.memberId) {
        toastDismiss();
        setIsPaymentSuccess(false);
      }
    } catch (e) {
      toastDismiss();
      toastError(e.message);
      setIsPaymentSuccess(false);
    } finally {
      setIsLoadingSignUpOver18(false);
    }
  };
  const [isRedirectLink, setRedirectLink] = useState(null);
  const [confirmData, setConfirmData] = useState();
  const [listMemberShipApi] = useLazyQuery(listMemberships);
  const [healthQuestionnaires] = useLazyQuery(healthQuestionsByBrandId);
  const [listTimelines, setListTimeLines] = useState([
    'contact',
    'membership',
    'details',
    'payment',
  ]);
  const [listBrand] = useLazyQuery(listBrands);
  const [membershipType, setMembershipType] = React.useState<string>('');
  const saveDataToStrapi = async (payload: any) => {
    const res = await fetch(
      `${process.env.GATSBY_STRAPI_API_URL}/api/temporary-users`,
      {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(payload),
      }
    );
    const result = await res.json();
    return result;
  };
  const updateDataToStrapi = async (id: string, payload: any) => {
    const res = await fetch(
      `${process.env.GATSBY_STRAPI_API_URL}/api/temporary-users/${id}`,
      {
        method: 'PUT',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(payload),
      }
    );
    const result = await res.json();
    return result;
  };
  const deleteDataonStrapi = async (id: string) => {
    const res = await fetch(
      `${process.env.GATSBY_STRAPI_API_URL}/api/temporary-users/${id}`,
      {
        method: 'DELETE',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        // body: JSON.stringify(payload),
      }
    );
    const result = await res.json();
    return result;
  };
  const [focused, setFocus] = useState(false);
  const [strapiUpdatedId, setStrapiId] = useState<string>('');
  const [dataForstrapi, setDataStrapi] = useState({
    address: '',
    brandId: 'Plus Fitness',
    country: '',
    dob: '',
    email: '',
    gender: '',
    givenName: '',
    homeLocationId: '',
    joinLocationId: '',
    membershipId: '',
    membershipLocationId: '',
    mobileNumber: '',
    postCode: '',
    state: '',
    suburb: '',
    surname: '',
  });
  const PrevStrapiData = useRef<any>();
  const updateOrSaveToStrapi = async (isDelete = false) => {
    const payload = {
      data: { ...dataForstrapi },
    };
    if (
      isDelete ||
      (Object.values(contact).some((i) => i != '') &&
        JSON.stringify(payload) !== JSON.stringify(PrevStrapiData.current))
    ) {
      if (isDelete) {
        await deleteDataonStrapi(strapiUpdatedId);
      } else if (strapiUpdatedId) {
        await updateDataToStrapi(strapiUpdatedId, payload);
        PrevStrapiData.current = payload;
      } else {
        const res = await saveDataToStrapi(payload);
        setStrapiId(res?.data?.id);
        PrevStrapiData.current = payload;
      }
    }
  };
  const timer = useRef();
  const isTimerSet = useRef(false);
  useEffect(() => {
    if (focused) {
      if (!isTimerSet.current) {
        timer.current = setTimeout(() => {
          updateOrSaveToStrapi();
        }, 120000);
        isTimerSet.current = true;
      }
    } else {
      if (isTimerSet.current) {
        clearTimeout(timer.current);
        isTimerSet.current = false;
      }
    }
    return;
  }, [focused]);
  useEffect(() => {
    if (closeModalAfterAutoSignUp && autoSignUp) {
      if (loadingOver18 || loadingUnder18) {
        setLoadingAutoSignUp(true);
      } else {
        setLoadingAutoSignUp(true);
        const timeoutId = setTimeout(() => {
          !errorOver18 && !errorUnder18 && handleSlideClick(5);
          setLoadingAutoSignUp(false);
        }, 3000);

        return () => clearTimeout(timeoutId);
      }
    }
  }, [closeModalAfterAutoSignUp, loadingOver18, loadingUnder18]);

  useEffect(() => {
    // let focused = true;
    window.onfocus = function () {
      setFocus(false);
    };
    window.onblur = function () {
      setFocus(true);
    };
    window.addEventListener('beforeunload', function (e) {
      // updateOrSaveToStrapi();
      let confirmationMessage = 'o/';
      (e || window.event).returnValue = confirmationMessage; //Gecko + IE
      return confirmationMessage; //Webkit, Safari, Chrome
    });
  }, []);

  useEffect(() => {
    listBrand();
    healthQuestionnaires().then((res) => {});
    const parseQueryString = () => {
      let str = window.location.search;
      let objURL = {};

      str.replace(
        new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
        function ($0, $1, $2, $3) {
          objURL[$1] = $3;
        }
      );
      return objURL;
    };
    const qr = parseQueryString();
    if (
      (Object.keys(qr).length > 0 && qr.email) ||
      qr.firstName ||
      qr.lastName ||
      qr.phoneNumber
    ) {
      setContact({
        ...contact,
        email: qr?.email ?decodeURIComponent(qr?.email): '',
        firstName: qr?.firstName ?decodeURIComponent(qr?.firstName): '',
        lastName: qr?.lastName ?decodeURIComponent(qr?.lastName): '',
        phone: qr?.phoneNumber ? '61' + decodeURIComponent(qr?.phoneNumber.slice(1)) : '',
      });
    }
    if (
      Object.keys(qr).length &&
      qr.inviteToken &&
      qr.email &&
      qr.brandId &&
      qr.memberId
    ) {
      setRedirectLink(true);
      setConfirmData(qr);
    } else {
      setRedirectLink(false);
    }
  }, []);

  useEffect(() => {
    isRedirectLink && confirmSignUp();
  }, [isRedirectLink]);

  useEffect(() => {
    if (current === 0) {
      window.location.hash = 'detail';
    } else if (current === 1) {
      window.location.hash = 'membership';
    } else if (current === 2) {
      window.location.hash = 'health';
    } else if (current === 3) {
      window.location.hash = 'payment';
    } else if (current === 4) {
      window.location.hash = 'successful';
    } else {
      history.replaceState(
        '',
        document.title,
        window.location.pathname + window.location.search
      );
    }
  }, [current]);
  const [qrMember] = useLazyQuery(fullMemberData);
  const mapInfo = (e) => {
    setConfirmInfo({ ...confirmInfo, [e.target.name]: e.target.value });
  };
  const [confirmInfo, setConfirmInfo] = useState({});

  const [isUnderAge, setIsUnderAge] = React.useState<boolean>(false);

  const handleUnderAge = () => {
    setIsUnderAge(true);
  };

  function getRandomNumber() {
    return Math.floor(10000000 + Math.random() * 90000000);
  }

  async function hashString(str: string) {
    const encoder = new TextEncoder();
    const data = encoder.encode(str);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('');
    return hashHex;
  }

  const confirmSignUp = async () => {
    try {
      setLoading(true);

      // Step 1: Generate an 8-digit random number
      const randomNumber = getRandomNumber();
      // Step 2: Convert the number to a string
      const randomNumberStr = randomNumber.toString();
      // Step 3: Hash the string using SHA-256
      const randomPassword = await hashString(randomNumberStr);

      const signupPayload = {
        username: confirmData.email,
        password: randomPassword,
        attributes: {
          email: confirmData.email,
          'custom:inviteToken': confirmData.inviteToken,
          'custom:brandId': confirmData.brandId,
          'custom:memberId': confirmData.memberId,
        },
      };
      if (confirmData.mobileNumber) {
        signupPayload.attributes.phone_number = confirmData.mobileNumber;
      }
      await Auth.signUp(signupPayload);
      navigate('/');
      toastSuccess('successfully confirm !');
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoading(false);
    }
  };
  const [isLoading, setLoading] = useState(false);
  const [isLoadingSignupOver18, setIsLoadingSignUpOver18] =
    useState<boolean>(false);
  const [isLoadingSignupUnder18, setIsLoadingSignUpUnder18] =
    useState<boolean>(false);
  const [successLoadingUnder18, setSuccessLoadingUnder18] =
    useState<boolean>(false);
  const [successLoadingOver18, setSuccessLoadingOver18] =
    useState<boolean>(false);
  const [TAndC, setTAndC] = useState<boolean>(false);
  const [privacyPolicy, setPrivacyPolicy] = useState<boolean>(false);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState<boolean>(false);
  const [preExercise, setPreExercise] = useState<boolean>(false);
  const { data: dataTermAndCondition } = useFetch({
    params: 'term-and-condition',
    populate: '',
  });
  const { data: dataPrivacyPolicy } = useFetch({
    params: 'privacy-policy',
    populate: '',
  });
  const termAndCondition: IDataTermsAndConditionPage =
    dataTermAndCondition?.data?.attributes;
  const privacyPolicyData: IDataTermsAndConditionPage =
    dataPrivacyPolicy?.data?.attributes;

  const { width } = useWindowSize();
  const [getHealthQuestion] = useLazyQuery(healthQuestionsByBrandId);
  const [healthQuestions, setHealthQuestions] = useState([]);
  useEffect(() => {
    getHealthQuestion().then((res: any) => {
      const questions = res.data?.getHealthQuestionnairesByBrandId.items.map(
        (i) => {
          return { ...i, userValue: '' };
        }
      );
      setHealthQuestions(questions);
    });
  }, []);

  const isVoucherNoCost: boolean =
    (voucherDetail?.type === 'COMPLIMENTARY' ||
      voucherDetail?.type === 'VISIT_PASS') &&
    !(
      voucherDetail?.cost +
      voucherDetail?.joiningFee +
      voucherDetail?.activationFee +
      voucherDetail?.passFee
    );

  return (
    <>
      {' '}
      {TAndC && (
        <ModalBase noBaseHeader onClickOutSide={() => setTAndC(false)}>
          <div
            onClick={() => setTAndC(false)}
            className="flex items-center absolute right-5 top-5 justify-center w-8 h-8 rounded-full border border-white cursor-pointer z-20"
          >
            <div className="w-4 h-4 block">
              <img
                src="/icons/close.png"
                alt="close"
                className="object-fit w-full"
              />
            </div>
          </div>
          <div className="h-[800px] overflow-y-auto" id="scroll-hidden">
            <section className="bg-dark pt-6 pb-20">
              <div className="containerPage">
                <h1 className="heading heading-underline text-white">
                  {termAndCondition?.title}
                </h1>
                <div className="contentWrapper">
                  <MyReactQuill content={termAndCondition?.content} />
                </div>
              </div>
            </section>
          </div>
        </ModalBase>
      )}
      {preExercise && (
        <ModalBase noBaseHeader onClickOutSide={() => setPreExercise(false)}>
          <div
            onClick={() => setPreExercise(false)}
            className="flex items-center absolute right-5 top-5 justify-center w-8 h-8 rounded-full border border-white cursor-pointer z-20"
          >
            <div className="w-4 h-4 block">
              <img
                src="/icons/close.png"
                alt="close"
                className="object-fit w-full"
              />
            </div>
          </div>
          <div className="overflow-y-auto" id="scroll-hidden">
            <section className="bg-dark pt-6 pb-20">
              <div className="containerPage">
                <h1 className="heading heading-underline text-white">
                  {/* {termAndCondition?.title} */}
                  PRE-EXERCISE CHECKLIST
                </h1>
                <div className="contentWrapper modalPreExercise">
                  <ul className="">
                    {healthQuestions
                      .filter((i) => i.answerType === 'NUMBER')
                      .map((i, index: number) => {
                        return (
                          <li key={index} className=" py-3 sm:px-0">
                            {i.question}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </section>
          </div>
        </ModalBase>
      )}
      {privacyPolicy && (
        <ModalBase noBaseHeader onClickOutSide={() => setPrivacyPolicy(false)}>
          <div
            onClick={() => setPrivacyPolicy(false)}
            className="flex items-center absolute right-5 top-5 justify-center w-8 h-8 rounded-full border border-white cursor-pointer z-20"
          >
            <div className="w-4 h-4 block">
              <img
                src="/icons/close.png"
                alt="close"
                className="object-fit w-full"
              />
            </div>
          </div>
          <div className="h-[800px] overflow-y-auto" id="scroll-hidden">
            <section className="bg-dark pt-6 pb-20">
              <div className="containerPage">
                <h1 className="heading heading-underline text-white">
                  {privacyPolicyData?.title}
                </h1>
                <div className="contentWrapper">
                  <MyReactQuill content={privacyPolicyData?.content} />
                </div>
              </div>
            </section>
          </div>
        </ModalBase>
      )}
      {!closeModalAfterAutoSignUp && (
        <div>
          {isLoading && !isLoadingSignupUnder18 && !isLoadingSignupOver18 && (
            <div className="fixed flex items-center h-[100vh] w-[100vw] z-50 bg-[rgba(212,212,212,0.2)] ">
              <div className="animate-spin h-12 w-12  border-r-2 border-2 border-gray border-r-[#2757FF] rounded-full m-auto "></div>
            </div>
          )}
          {isLoadingSignupUnder18 && (
            <div
              className={`fixed flex items-center transition-opacity ease-in-out duration-500 ${
                isLoadingSignupUnder18 ? 'opacity-100' : 'opacity-0'
              }  h-[100vh] w-[100vw] z-50 bg-[rgba(14,13,13,0.6)]`}
            >
              <div className="m-auto h-[400px] w-[35%] sm:w-[350px] lg:w-[400px] bg-richBlack flex justify-center items-center px-[35px]">
                <div className="mb-8">
                  <div className="animate-spin h-12 w-12  border-r-2 border-2 border-gray border-r-[#2757FF] rounded-full mx-auto "></div>
                  <div className="mt-5 text-center text-2xl text-primary  loading">
                    {isVoucherNoCost ? 'Signing Up' : 'Saving Card Details'}
                  </div>
                  <div className="mt-5 text-center  text-white tracking-[1.4px] leading-[25px]">
                    {isVoucherNoCost
                      ? 'Processing time may take up to 60 seconds'
                      : 'We securely save your card details to process your payment once your membership has been approved by your parent/guardian.'}
                  </div>
                </div>
              </div>
            </div>
          )}
          {isPaymentSuccess && (
            <div
              className={`fixed flex items-center transition-opacity ease-in-out duration-500 ${
                successLoadingUnder18 || successLoadingOver18
                  ? 'opacity-100 '
                  : 'opacity-0'
              }  h-[100vh] w-[100vw] z-50 bg-[rgba(14,13,13,0.6)]`}
            >
              <div className="m-auto h-[400px] w-[35%] sm:w-[350px] lg:w-[400px] bg-richBlack flex justify-center items-center px-[35px]">
                <div className="mb-8">
                  {(successLoadingUnder18 || successLoadingOver18) && (
                    <div className="success-animation">
                      <svg
                        className="checkmark"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 52 52"
                      >
                        <circle
                          className="checkmark__circle"
                          cx="26"
                          cy="26"
                          r="25"
                          fill="none"
                        />
                        <path
                          className="checkmark__check"
                          fill="none"
                          d="M14.1 27.2l7.1 7.2 16.7-16.8"
                        />
                      </svg>
                    </div>
                  )}
                  <div className="mt-5 text-center text-2xl text-primary  ">
                    {successLoadingUnder18 &&
                      !isVoucherNoCost &&
                      'Card Details Saved'}
                    {successLoadingOver18 &&
                      !isVoucherNoCost &&
                      'Payment Successful'}
                    {isVoucherNoCost && 'Sign up Successful'}
                  </div>
                  <div className="mt-5 text-center  text-white tracking-[1.4px] leading-[25px]">
                    {successLoadingUnder18 &&
                      !isVoucherNoCost &&
                      'Thank you! Your card details have been saved securely. Once your membership is approved, we will process the payment using this card.'}
                    {successLoadingOver18 &&
                      isVoucherNoCost &&
                      'Thank you! Please check your email to complete your signup.'}
                    {successLoadingOver18 &&
                      !isVoucherNoCost &&
                      'Thank you! Your payment has been processed; please check your email to complete your signup.'}
                  </div>
                  <div className="mt-5 text-center  text-white tracking-[1.4px]  leading-[25px]">
                    {successLoadingUnder18 &&
                      'Please check with your parent/guardian to access their email and approve the consent form.'}
                  </div>
                </div>
              </div>
            </div>
          )}
          {isLoadingSignupOver18 && (
            <div
              className={`fixed flex items-center  transition-opacity ease-in-out duration-500 ${
                isLoadingSignupOver18 ? 'opacity-100' : 'opacity-0'
              } h-[100vh] w-[100vw] z-50 bg-[rgba(14,13,13,0.6)]`}
            >
              <div className="m-auto h-[300px] w-[35%] sm:w-[350px] lg:w-[400px] bg-richBlack flex justify-center items-center">
                <div className="mb-8">
                  <div className="animate-spin h-12 w-12  border-r-2 border-2 border-gray border-r-[#2757FF] rounded-full mx-auto "></div>
                  <div className="mt-5 text-center text-2xl text-primary  loading">
                    {isVoucherNoCost
                      ? 'Signing Up'
                      : 'Processing your payment and signing up'}
                  </div>
                  <div className="mt-5 text-center  text-white">
                    Processing time may take up to 60 seconds
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {loadingAutoSignUp && closeModalAfterAutoSignUp && (
        <div
          className={`fixed flex items-center transition-opacity ease-in-out duration-500 opacity-100  h-[100vh] w-[100vw] z-50 bg-[rgba(14,13,13,0.6)]`}
        >
          <div className="m-auto h-[400px] w-[35%] sm:w-[350px] lg:w-[400px] bg-richBlack flex justify-center items-center px-[35px]">
            <div className="mb-8">
              <div className="animate-spin h-12 w-12  border-r-2 border-2 border-gray border-r-[#2757FF] rounded-full mx-auto "></div>
              <div className="mt-5 text-center text-2xl text-primary  loading">
                Signing Up
              </div>
              <div className="mt-5 text-center  text-white tracking-[1.4px] leading-[25px]">
                Processing time may take up to 60 seconds
              </div>
            </div>
          </div>
        </div>
      )}
      {!isRedirectLink && (
        <div id="registerPage" className="min-h-[100vh] bg-black">
          <div className=" mx-auto max-w-[1440px] relative">
            <div className="py-[37px] sm:!mx-[0px] xl:mx-10">
              <img
                className="lg:mx-6 sm:!mx-[14px] mx-0  w-auto h-[36px] md:h-[21px]"
                src={logo}
                alt="logo"
              />
            </div>
            {/* Navigation */}
            {!isUnderAge && (
              <div
                style={{ width: percent + '%' }}
                className={`hidden h-[6px] md:flex  transition-transform bg-primary z-10`}
              ></div>
            )}
            {input?.memberDetails?.homeLocationId &&
              current === 0 &&
              isShowPromotionBanner && (
                <div className={`text-white w-[900px] h-[227px] mb-10`}>
                  <img
                    className="lg:mx-6 sm:!mx-[14px] mx-0  w-full h-full md:h-full object-contain"
                    src={
                      dataPromotionBanner?.data?.attributes?.promotion?.image
                        ?.data?.attributes?.url || ''
                    }
                    alt="logo"
                  />
                </div>
              )}

            <div
              className={`flex items-start justify-between pb-[11px] overflow-hidden  lg:w-full  sm:!px-[0px] xl:px-10 ${
                input?.memberDetails?.homeLocationId &&
                input.memberDetails?.suburb
                  ? 'w-[960px]'
                  : ''
              }`}
            >
              {!isUnderAge && current < 5 && (
                <>
                  <div className="md:hidden lg:mx-6 mx-0 flex items-center cursor-pointer md:justify-evenly justify-between w-full gap-x-8">
                    {listTimelines.map((item: any, index: number) => (
                      <div
                        onClick={() =>
                          current >= index ? handleSlideClick(index) : null
                        }
                        key={index}
                        className="md:px-0 flex items-center w-1/4"
                      >
                        <div
                          className={`w-[34px] h-[34px] rounded-[50%] text-white flex items-center justify-center font-bold text-sm ${
                            current >= index && 'bg-primary text-black'
                          } ${
                            current < index &&
                            'border border-white  opacity-60 text-white'
                          } ${
                            current > index &&
                            'border border-primary text-white'
                          } `}
                        >
                          {index + 1}
                        </div>
                        <p
                          className={`md:hidden ml-[15px] uppercase  tracking-widest font-bold gotham  text-[10px] ${
                            current < index ? 'text-gray' : 'text-white'
                          }`}
                        >
                          {item}
                        </p>
                      </div>
                    ))}
                  </div>
                  <div className="md:flex  hidden items-center justify-between w-full mx-6 sm:mx-[14px] mt-[20px] cursor-pointer">
                    {listTimelines.map((mobileStep, mobileIndex) => {
                      return (
                        <div
                          key={mobileStep}
                          className={`${
                            current >= mobileIndex
                              ? 'bg-primary '
                              : 'bg-richBlack '
                          } relative w-9 h-9 rounded-full border border-primary flex items-center justify-center font-bicyclette font-bold leading-[18px] text-white text-xl text-center after:content-[' '] ${
                            mobileIndex === 3
                              ? ' '
                              : ' after:absolute after:left-[calc(100%+1px)] after:top-1/2 after:h-[2px] after:w-[calc(40vw)] after:bg-textSecondary'
                          }  `}
                        >
                          {mobileIndex + 1}
                        </div>
                      );
                    })}
                  </div>
                </>
              )}

              {current >= 5 && (
                <h2 className="text-primary md:hidden flex uppercase heading2">
                  congrats, you’ve just joined plus fitness
                </h2>
              )}

              {/* Close */}
              <div
                onClick={() => {
                  if (isUnderAge && width && width <= breakpoints.sm) {
                    setIsUnderAge(false);
                  } else {
                    navigate('/');
                  }
                }}
                //  to={isUnderAge && width && width <= breakpoints.sm ? '' : '/'}
              >
                <div className="cursor-pointer absolute top-[25px] right-0 sm:right-4">
                  <svg
                    width="41"
                    height="41"
                    viewBox="0 0 41 41"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M29.7926 29.7929C24.4542 35.1314 15.8318 35.1543 10.5341 29.8565C5.23632 24.5588 5.25924 15.9364 10.5977 10.598C15.9362 5.25952 24.5585 5.2366 29.8563 10.5344C35.154 15.8321 35.1311 24.4545 29.7926 29.7929Z"
                      stroke="white"
                      strokeWidth="1.32496"
                    />
                    <path
                      d="M24.7275 15.1323L15.3977 24.4622"
                      stroke="white"
                      strokeWidth="1.32496"
                    />
                    <path
                      d="M24.8311 24.5654L15.5598 15.2942"
                      stroke="white"
                      strokeWidth="1.32496"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div className="flex items-start  w-full  max-w-[1440px] justify-between mx-auto">
              <div
                className={`flex flex-col ${
                  input.memberDetails?.suburb ? 'w-[900px]  ' : 'w-full'
                } lg:w-full `}
              >
                <div className="sm:!mx-[14px] xl:px-10">
                  <div
                    style={{ width: percent > 100 ? '100%' : percent + '%' }}
                    className={`flex h-[6px] md:hidden  transition-transform bg-primary z-10`}
                  ></div>
                </div>
                <div
                  className={`xl:w-auto w-auto sm:!mx-[14px] xl:mx-10 ${
                    current == 4
                      ? 'h-[calc(100vh-150px)]'
                      : 'h-[calc(100vh-180px)]'
                  }  overflow-hidden  `}
                >
                  <Swiper
                    allowTouchMove={false}
                    ref={swiperRef}
                    slidesPerView={1}
                    initialSlide={current}
                    onSlideChange={handleSlideChange}
                  >
                    <SwiperSlide>
                      <Details
                        setStrapiData={(v) =>
                          setDataStrapi({ ...dataForstrapi, ...v })
                        }
                        onPrev={() => true}
                        onNext={() => handleSlideClick(1)}
                        info={input}
                        setInfo={setInput}
                        onUnderAge={handleUnderAge}
                        isUnderAge={isUnderAge}
                        setContact={setContact}
                        contact={contact}
                        setIsUnderAge={setIsUnderAge}
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <Memberships
                        setStrapiData={(v) =>
                          setDataStrapi({ ...dataForstrapi, ...v })
                        }
                        onPrev={() => handleSlideClick(0)}
                        onNext={() => handleSlideClick(2)}
                        info={input}
                        setInfo={setInput}
                        setMembershipType={setMembershipType}
                        getVoucher={setVoucherDetail}
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <Health
                        setStrapiData={(v) =>
                          setDataStrapi({ ...dataForstrapi, ...v })
                        }
                        onPrev={() => handleSlideClick(1)}
                        onNext={() => handleSlideClick(3)}
                        info={input}
                        setInfo={setInput}
                        openModalTC={() => setTAndC(true)}
                        openModalPP={() => setPrivacyPolicy(true)}
                        openModalPreExercise={() => setPreExercise(true)}
                        setContact={setContact}
                        contact={contact}
                        handleCallApiPrePayment={handleCallApiPrePayment}
                        membershipType={membershipType}
                        voucherDetail={voucherDetail}
                      />
                    </SwiperSlide>

                    <SwiperSlide>
                      <Payment
                        voucherDetail={voucherDetail}
                        onPrev={() => handleSlideClick(2)}
                        onNext={() => {
                          handleSlideClick(4);
                        }}
                        info={input}
                        setInfo={setInput}
                        handleCallApiOver18={handleCallApiOver18}
                        handleCallApiUnder18={handleCallApiUnder18}
                        contact={contact}
                        isApiSignUpFail={isApiSignUpFail}
                        useBank={useBank}
                        setIsPaymentSuccess={setIsPaymentSuccess}
                        membershipType={membershipType}
                        slideMove={(v: number) => handleSlideClick(v)}
                        setCloseModalAfterAutoSignUp={
                          setCloseModalAfterAutoSignUp
                        }
                        setAutoSignUp={setAutoSignUp}
                        autoSignUp={autoSignUp}
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <Congrats info={input} moveSlide={handleSlideClick} />
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
              {input.memberDetails?.suburb && (
                <YourMembership
                  voucherDetail={voucherDetail}
                  slideMove={(v: number) => handleSlideClick(v)}
                  current={current}
                  info={input}
                  setInfo={setInput}
                  contact={contact}
                  membershipType={membershipType}
                />
              )}
            </div>
          </div>
        </div>
      )}
      {isRedirectLink && (
        <div className="bg-black h-[100vh] w-[100vw] flex items-center justify-center relative">
          <div className="flex flex-col gap-6 z-10 sm:mx-6">
            <div className="w-[495px] h-[47px]">
              <img
                className="w-full h-full object-contain"
                src="/icons/pf-logo-line.svg"
              />
            </div>
            <div className="text-white uppercase text-[40px] leading-[36px] text-center my-6 ">
              Member Signup Confirmation
            </div>
            <div className="self-center max-w-[600px] text-center text-white text-xl ">
              Thank you for verifying your email address. Your account is now
              confirmed and ready for use.
              <br />
              You can now log in to your member portal by clicking the button
              below. This will direct you to the login page where you can enter
              your credentials and access your profile.
            </div>

            <div className="flex justify-end items-center">
              <ArrowButton
                text="LOGIN"
                isRight={true}
                onclick={() => navigate('/login')}
              />
            </div>
          </div>
          <div className="absolute bottom-0 w-[100vw] h-[44vh] bg-gradient-to-t to-[rgba(36,51,213,0.0)] from-[rgba(36,51,213,0.34)]"></div>
        </div>
      )}
    </>
  );
}

export default register;
