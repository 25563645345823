import { getMemberContractByMemberId } from '@/api/mutation';
import { MEMBERSHIP_STATUS } from '@/enums/membership.enum';
import { IContract, ISuspensions } from '@/interfaces/Contract.interface';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import { Auth } from 'aws-amplify';
import { utcToZonedTime } from 'date-fns-tz';
import { startOfDay } from 'date-fns';
import moment from 'moment';
import React, { useState, createContext, useContext } from 'react';

const MembershipContext = createContext<any>(null);
interface iProps {
  children: React.ReactNode;
}
export interface IContractWithStatus extends IContract {
  status: string;
  remainingDay: number;
}
export const MembershipProvider = ({ children }: iProps) => {
  const [membershipInfo, setMembershipInfo] = useState<IContractWithStatus[]>(
    []
  );
  const [userSelectedMembership, setUserSelectedMembership] =
    useState<IContractWithStatus | null>(null);
  // const [getUserMembership] = useLazyQuery(getMemberContractByMemberId);
  const client = useApolloClient();

  const getUserMembershipInfo = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const userId = user.attributes['custom:memberId'];
    const contracts = await client.query({
      fetchPolicy: 'no-cache',
      query: getMemberContractByMemberId,
      variables: {
        memberId: userId,
      },
    });
    const contractsFiltering: IContractWithStatus[] =
      contracts.data.getMemberContractByMemberId.items
        .map((i: IContract) => {
          const status = membershipStatus(i);
          return { ...i, ...status, isActive: true };
        })
        .filter((i: IContractWithStatus) => i.status !== 'cancelled');

    setUserSelectedMembership(contractsFiltering[0]);
    setMembershipInfo(contractsFiltering);
  };

  const membershipStatus = (memberShip: IContract) => {
    const result: Record<string, any> = {
      status: null,
      remainingDay: 0,
    };

    const membershipItem = memberShip;
    const currentDate = new Date().toISOString();

    const activeSuspensionItem = membershipItem?.suspensions?.items?.filter(
      (e: ISuspensions) =>
        e.cancelledDateTime === null &&
        e.suspensionStatus === MEMBERSHIP_STATUS.APPROVED &&
        moment(currentDate).isBefore(e.suspensionEndDateTime)
    )?.[0];

    if (
      !membershipItem ||
      (membershipItem?.expiryDateTime !== null &&
        (moment(currentDate).isSame(membershipItem?.expiryDateTime) ||
          moment(currentDate).isAfter(membershipItem?.expiryDateTime)))
    ) {
      result.status = 'cancelled';
    } else if (
      membershipItem?.expiryDateTime !== null &&
      moment(currentDate).isBefore(membershipItem?.expiryDateTime)
    ) {
      result.status = 'pendingCancellation';
      result.remainingDay = moment(membershipItem?.expiryDateTime).diff(
        currentDate,
        'days'
      );
    } else if (
      activeSuspensionItem &&
      activeSuspensionItem.suspensionStatus.toLowerCase() !== 'rejected' &&
      activeSuspensionItem &&
      moment(currentDate).isBefore(
        activeSuspensionItem.suspensionStartDateTime
      ) &&
      moment(currentDate).isBefore(
        activeSuspensionItem.suspensionEndDateTime
      ) &&
      currentDate.split('T')[0] !==
        activeSuspensionItem.suspensionStartDateTime.split('T')[0]
    ) {
      result.status = 'pendingSuspension';
    } else if (
      activeSuspensionItem &&
      activeSuspensionItem.suspensionStatus.toLowerCase() === 'approved' &&
      (moment(currentDate).isBetween(
        activeSuspensionItem.suspensionStartDateTime,
        activeSuspensionItem.suspensionEndDateTime,
        undefined,
        '[]'
      ) ||
        currentDate.split('T')[0] ===
          activeSuspensionItem.suspensionStartDateTime.split('T')[0] ||
        currentDate.split('T')[0] ===
          activeSuspensionItem.suspensionEndDateTime.split('T')[0])
    ) {
      result.status = 'suspended';
    } else if (
      membershipItem.isPending ||
      moment(currentDate).isBefore(
        startOfDay(
          utcToZonedTime(membershipItem.startDateTime, membershipItem.timezone)
        )
      )
    ) {
      result.status = 'future';
    } else {
      result.status = 'active';
    }

    return result;
  };

  return (
    <MembershipContext.Provider
      value={{
        membershipInfo,
        getUserMembershipInfo,
        userSelectedMembership,
        setUserSelectedMembership,
      }}
    >
      {children}
    </MembershipContext.Provider>
  );
};

export const useMemberShipContext = () => {
  return useContext(MembershipContext);
};
