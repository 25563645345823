import ArrowButton from '@/common/buttons/ArrowButton'
import CheckboxActive from '@/common/buttons/CheckboxActive'
import InputBase from '@/common/inputs/InputBase'
import { toastError, toastSuccess } from '@/common/toasts/toast';
import { Amplify, Auth } from 'aws-amplify';
import { stat } from 'fs';
import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react'

function ForgotPassword(props) {
    const [status, setStatus] = useState(0);
    // const status = "login";

    const [formData, setFormData] = useState({
        email: '',
        code: "",
        password: "",
        retypePassword: "",
    })
    const handleChangeValue = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleForgotPassword = () => {
        setLoading(true);
        if (formData.email != '')
            Auth.forgotPassword(formData.email)
                .then(() => { toastSuccess("Your password reset code have been sent to your email."); setStatus(1) }
                )
                .catch((err) => toastError(err.message));
        else toastError('please fill the input')
        setLoading(false);
    };
    const handleResetPassword = () => {
        setLoading(true);
        if (formData.password === formData.retypePassword)
            Auth.forgotPasswordSubmit(
                formData.email,
                formData.code,
                formData.retypePassword
            )
                .then(() => { toastSuccess("password reset successful"); navigate('/login') })
                .catch((err) => toastError(err.message));
        else toastError("password and retype password does not match");
        setLoading(false);
    };
    const [isLoading, setLoading] = useState(false);

    return (
        <>
            {isLoading && <div className="fixed flex items-center h-[100vh] w-[100vw] z-50 bg-[rgba(212,212,212,0.2)] ">
                <div className="animate-spin h-12 w-12  border-r-2 border-2 border-gray border-r-[#2757FF] rounded-full m-auto "></div>
            </div>}
            <div className='bg-black h-[100vh] md:px-6 px-0 w-[100vw] flex items-center justify-center relative'>
                <div className='flex flex-col gap-6 z-10'>
                    <img className='md:w-[80%] w-auto md:mx-auto mx-0 ' src='/icons/pf-logo-line.svg' />
                    <div className='text-white uppercase md:text-[28px] text-[40px] leading-[36px] text-center mb-6 md:mt-0 mt-6 '>Member forgot password</div>
                    <p className='w-full mx-auto max-w-[500px] text-white text-center'>{status == 0 ? "To reset your password, you'll need to provide your email. We’ll send an email to the address you provided." : 'Please enter all data below to reset your password'}</p>
                    <div className='flex flex-col gap-6'>
                        {status == 0 &&
                            <div>
                                <InputBase
                                    onchange={handleChangeValue}
                                    nameInput="email"
                                    holder="Email address*"
                                    style="lg:w-full"
                                    isRequired={true}
                                    type='text'
                                />
                                <p className='mt-[8px] underline text-gray cursor-pointer hover:text-white text-xs text-end'><span className='cursor-pointer'
                                    onClick={() => setStatus(1)}>already have your code?</span></p>
                            </div>}
                        {
                            status == 1 && <>
                                <InputBase
                                    onchange={handleChangeValue}
                                    nameInput="email"
                                    holder="Email address*"
                                    style="lg:w-full"
                                    isRequired={true}
                                    type='text'
                                /><InputBase
                                    onchange={handleChangeValue}
                                    nameInput="code"
                                    holder="verification code*"
                                    style="lg:w-full"
                                    isRequired={true}
                                    type='text'
                                /><InputBase
                                    onchange={handleChangeValue}
                                    nameInput="password"
                                    holder="Password new*"
                                    style="lg:w-full"
                                    isRequired={true}
                                    type='password'
                                />
                                <InputBase
                                    onchange={handleChangeValue}
                                    nameInput="retypePassword"
                                    holder="Password confirm*"
                                    style="lg:w-full"
                                    isRequired={true}
                                    type='password'
                                />
                            </>
                        }
                    </div>
                    <div className='flex justify-between items-center'>
                        <div className='flex gap-[12px] items-center'>
                            {status == 1 &&
                                <ArrowButton text="back" isArrowLeft={true} isRight={false} onclick={() => { setStatus(0) }} />
                            }
                        </div>
                        <ArrowButton text="NEXT" isRight={true} onclick={() => { status == 0 && handleForgotPassword(); status == 1 && handleResetPassword() }} />
                    </div>
                    <p className='text-white text-[16px] font-medium text-center'>Don't have an account? <span className='cursor-pointer text-[16px] font-medium underline' onClick={() => navigate('/register')}>Join now</span> </p>

                </div>
                <div className='absolute bottom-0 w-[100vw] h-[44vh] bg-gradient-to-t to-[rgba(36,51,213,0.0)] from-[rgba(36,51,213,0.34)]'></div>

            </div>
        </>
    )
}

export default ForgotPassword
