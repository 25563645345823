import React, { useEffect, useState } from 'react';
async function DistanceCalculator({ origin, destination }) {
  // Check if the Google Maps API script is already loaded
  const service = new window.google.maps.DistanceMatrixService();
  return service.getDistanceMatrix(
    {
      origins: [{ lat: origin.lat, lng: origin.lng }],
      destinations: [{ lat: destination.lat, lng: destination.lng }],
      travelMode: 'DRIVING',
    },
  ).then((response) => {
    return response.rows[0].elements[0].distance;
  }).catch(e => {
    console.log('Error:', e.message);
    return 0
  }
  );
}
export default DistanceCalculator;