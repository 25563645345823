// BREAKPOINTS
export const breakpoints: Record<'xl' | 'lg' | 'md' | 'sm' | 'xs', number> = {
  xl: 1500,
  lg: 1279,
  md: 1025,
  sm: 768,
  xs: 414,
};

// ROUTES
export const accessRoute = '/access';
export const accessKey = 'pf_acs';
export const defaultPassword = 'Y@^p#6%3Ash8T2';
// BANNER
export const defaultFlipText = ['GOALS', 'HEALTH', 'DISCIPLINE', 'STRENGTH'];

// START - MAPBOX
export const MAP_API_KEY =
  process.env.GATSBY_MAPBOX_API_KEY ||
  'pk.eyJ1IjoicGx1c2ZpdG5lc3MiLCJhIjoiY2txNXVlbWo4MHR5azJwb2h1Z3N5bTdxMCJ9.RGW_SHvAZmYjqgzWbAma5Q';
export const baseMap = 'mapbox';
export const mapId = 'streets-v8';
export const basedMapStyle = `mapbox://styles/${baseMap}/${mapId}`;
export const defaultMapCenter: [number, number] = [135, -27]; // Australia
export const defaultMapZoom: [number] = [3];
export const gymLocationMapZoom: [number] = [2.5];
export const gymLocationMapZoomMobile: [number] = [2.25];

export const siteZoomLevels: any = {
  nz: {
    desktop: [4],
    mobile: [4],
  },
  au: {
    desktop: [2.5],
    mobile: [2.25],
  },
  in: {
    desktop: [4.5],
    mobile: [4.25],
  },
  id: {
    desktop: [4.5],
    mobile: [3.25],
  },
  other: {
    desktop: [2.5],
    mobile: [2.25],
  },
};
export const state = [
  'ACT',
  'QLD',
  'NSW',
  'VIC',
  'WA',
  'TAS',
  'SA',
  'NT',
  'NZ',
];
export const maxDateValidate = 6;
// END - MAPBOX
