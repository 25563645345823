import CheckboxActive from '@/common/buttons/CheckboxActive';
import { uppercaseFirstLetter } from '@/helpers/string.helper';
import React, { memo, useState } from 'react';

interface IProps {
  holder: string;
  data: string[];
  initial: string[];
  childData: string[];
  onClick: () => void;
  setData: React.Dispatch<React.SetStateAction<string[]>>;
}

function DropdownLocationByState(props: IProps) {
  const { data, setData, initial, onClick, holder, childData } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenChild, setIsOpenChild] = useState(
    new Array(initial?.length).fill(false)
  );

  const handleChecked = (current: string) => {
    setData((prev) => {
      const isChecked = data.some((item: string) => item?.id === current?.id);
      return isChecked
        ? data?.filter((item: string) => item?.id !== current?.id)
        : [...prev, current];
    });
  };

  const handleToggleChild = (index) => {
    const newIsOpenChild = [...isOpenChild];
    newIsOpenChild[index] = !newIsOpenChild[index];
    setIsOpenChild(newIsOpenChild);
  };

  return (
    <div className="relative h-full">
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={`py-[9.5px] h-full my-auto cursor-pointer pl-6 pr-[22px] rounded-[6px] flex items-center justify-between`}
      >
        <span
          className={`w-full placeholder:text-white font-medium text-[14px] bg-transparent heading3 border-none outline-none text-start`}
        >
          {data.length > 0
            ? data
                .map((item) =>
                  item?.name
                    ?.split(' ')
                    ?.map((locationName) => uppercaseFirstLetter(locationName))
                    ?.join(' ')
                )
                ?.join(', ')
            : holder.toLocaleUpperCase()}
        </span>
        <svg
          className="flex-none"
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="9"
          viewBox="0 0 14 9"
          fill="none"
        >
          <path
            d="M1 1.5L6.29289 6.79289C6.62623 7.12623 6.79289 7.29289 7 7.29289C7.20711 7.29289 7.37377 7.12623 7.70711 6.79289L13 1.5"
            stroke={'white'}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      {isOpen && (
        <div className="w-full relative bg-[#13181b] z-20 border-x border-b border-white rounded-bl-md rounded-t-none rounded-br-md left-[-1px] top-[-3px] box-content sm:block sm:bg-[#060820] ">
          <ul className="flex flex-col ">
            {initial?.map((item: string, idx: number) => (
              <li
                key={idx}
                className="py-[10px] text-sm font-medium px-6 items-center text-start justify-between"
              >
                <div
                  onClick={() => {
                    handleToggleChild(idx);
                    setIsOpen(true);
                  }}
                  className={`py-[9.5px] h-full my-auto cursor-pointer pr-[22px] rounded-[6px] flex items-center justify-between`}
                >
                  <span
                    className={`w-full placeholder:text-white font-medium text-[14px] bg-transparent heading3 border-none outline-none text-start uppercase`}
                  >
                    {item}
                  </span>
                  <svg
                    className="flex-none"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="9"
                    viewBox="0 0 14 9"
                    fill="none"
                  >
                    <path
                      d="M1 1.5L6.29289 6.79289C6.62623 7.12623 6.79289 7.29289 7 7.29289C7.20711 7.29289 7.37377 7.12623 7.70711 6.79289L13 1.5"
                      stroke={'white'}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>

                <div
                  className={`w-full relative bg-[#13181b] z-20 left-[-1px] box-content sm:bg-[#060820] ${
                    isOpenChild[idx] ? '' : 'hidden'
                  }`}
                >
                  <ul
                    className="flex flex-col max-h-[200px] overflow-y-auto "
                    style={{
                      scrollbarWidth: 'thin',
                      scrollbarColor: '#7681FF #141a25',
                      WebkitScrollbarWidth: 'thin',
                      WebkitScrollbarColor: '#7681FF #141a25',
                    }}
                  >
                    {childData
                      ?.filter((i) => i.state === item)
                      ?.map((item: string, idx: number) => (
                        <li
                          key={idx}
                          className="flex py-[10px] text-sm font-medium px-6 items-center text-start justify-between hover:bg-[#141a25] hover:text-[#7681FF]"
                        >
                          {item?.name
                            ?.split(' ')
                            ?.map((locationName) =>
                              uppercaseFirstLetter(locationName)
                            )
                            .join(' ')}
                          <div onClick={() => handleChecked(item)}>
                            <CheckboxActive
                              isChecked={
                                data.map((i) => i.id).includes(item?.id)
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </li>
            ))}
          </ul>
          <div className="w-full flex justify-center">
            <button
              style={{ border: '1px solid white' }}
              onClick={() => {
                setIsOpen(!isOpen);
                onClick();
              }}
              className="border-white rounded-[5px] text-white group-hover:border-brand-clime group-hover:text-brand-clime focus:border-r focus:border-r-white border-b text-medium md:text-base text-sm px-[16px] py-[12px] font-medium flex focus:text-white my-3"
            >
              APPLY
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default memo(DropdownLocationByState);
