import React, { ComponentPropsWithoutRef } from 'react';

interface IProps extends ComponentPropsWithoutRef<'button'> {
  children: React.ReactNode;
  className?: string;
}

const PrimaryButton = (props: IProps) => {
  const { children, className = '', ...rest } = props;
  return (
    <button
      className={`bg-primary border-primary py-2 px-8 min-h-[40px] text-white text-sm transition-all duration-300 hover:bg-primaryDark hover:border-primaryDark ${className}`}
      {...rest}
    >
      {children}
    </button>
  );
};

export default PrimaryButton;
