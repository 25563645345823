import React, { useEffect, useState } from 'react';
interface IProps {
  onChange?: (value: string) => void;
  initial?: string;
  isYesNo?: boolean;
  noDivider?: boolean;
  disabled?: boolean;
}
function Switch(props: IProps) {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onChange = (value: string) => {
      return;
    },
    disabled = false,
    initial,
    isYesNo = false,
    noDivider = false,
  } = props;
  const [mode, setMode] = useState<string>(null);
  useEffect(() => {
    onChange && onChange(mode);
  }, [mode]);
  useEffect(() => {
    setMode(initial);
  }, [initial]);
  return (
    <div className="flex items-center border border-white rounded-md cursor-pointer">
      <div
        onClick={() => !disabled && setMode('on')}
        className={`${
          isYesNo ? 'w-[45px]' : 'w-[51px]'
        }  h-[40px] rounded-l gotham font-medium text-[11.2px] uppercase
                ${mode == 'on' ? 'bg-primary text-white' : ' text-white'}
                ${noDivider ? '' : 'border-r-[0.5px] border-r-white'}
                flex items-center justify-center`}
      >
        {isYesNo ? 'yes' : 'on'}
      </div>
      <div
        onClick={() => !disabled && setMode('off')}
        className={`${
          isYesNo ? 'w-[45px]' : 'w-[51px]'
        } h-[40px]  rounded-r  gotham font-medium text-[11.2px] uppercase 
                ${mode == 'off' ? 'bg-primary text-white' : ' text-white'}
                ${noDivider ? '' : 'border-l-[0.5px] border-l-white'}
                flex items-center justify-center`}
      >
        {isYesNo ? 'no' : 'off'}
      </div>
    </div>
  );
}

export default Switch;
