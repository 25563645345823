import React, { useState } from 'react';
import warnCircle from '../../assets/images/icons/warnCircle.svg';
import { disable } from 'debug';
interface InputType {
  holder: string;
  // label: string;
  type: string;
  nameInput: string;
  value?: any;
  onchange: any;
  // isActiveData: boolean;
  img: any;
  isRequired: boolean;
  isShowRequired: boolean;
  errorText: string;
  width: string;
  style: string;
  isDashboard: boolean;
  prefix: '+61' | '+64';
  onChangePrefix: (value: '+61' | '+64') => void;
  revertColor?: boolean;
  disabled?: boolean;
}
function InputNumber({
  holder,
  // label,
  //   type,
  value,
  onchange,
  nameInput,
  // isActiveData = false,
  img = null,
  isRequired = false,
  isDashboard,
  isShowRequired = false,
  errorText = '',
  width = '',
  style = '',
  prefix = '+61',
  onChangePrefix,
  revertColor = false,
  disabled = false,
}: InputType) {
  const [active, setActive] = useState(false);

  const [isSelectPrefix, setIsSelectPrefix] = React.useState<boolean>(false);

  const activeValue = (e) => {
    return e.target.value === ''
      ? (setActive(false),
        (e.target.style.borderColor = ''),
        (e.target.style.color = ''),
        (e.target.style.caretColor = '#ccc'))
      : (setActive(true),
        (e.target.style.borderColor = '#d3d3d3'),
        (e.target.style.caretColor = '#d3d3d3'),
        (e.target.style.color = isDashboard ? 'white' : '#d3d3d3'));
  };
  const onChange = (e) => {
    const elm = { ...e };
    // if (e.target.value[0] == 0) {
    //   e.target.value = '';
    // }
    return onchange(elm);
  };
  return (
    <div
      style={{
        width: `${width !== '' ? `${width}` : style == '' ? '373px' : ''}`,
      }}
      className={` relative ${style}`}
    >
      <label>
        <span className="font-gotham after:content-[''] after:ml-0.5 after:text-white block text-[12px] gotham font-medium text-white">
          {active ? '' : ''}
        </span>
        <span
          className={`absolute  block text-[12px] font-medium transition-all duration-300 sm:text-base sm:font-normal ${
            active || !!value
              ? isDashboard
                ? 'text-textSecondary top-0 sm:-top-1 left-0'
                : 'top-0 sm:-top-2 left-0 text-Secondary'
              : 'top-[24px] sm:top-5 left-10 text-white'
          } ${
            prefix && !!onChangePrefix
              ? active || !!value
                ? ' !left-0 '
                : ' !left-14'
              : ' '
          } ${revertColor ? ' text-white ' : ' text-textSecondary '}`}
        >
          {holder}
        </span>
        {isRequired ? (
          <div
            style={{ borderColor: active ? '' : '' }}
            className={`flex inputBorder items-center  border-b py-[7px] ${
              (active || !!value) && !isDashboard
                ? 'border-[#d3d3d3]'
                : 'border-borderLine'
            } ${disabled ? '!border-opacity-50' : ''}`}
          >
            <div
              className={`relative flex items-center justify-start gap-x-1 cursor-pointer text-sm gotham border-r pr-2 ${
                !active
                  ? 'border-borderLine text-textSecondary'
                  : 'border-[#d3d3d3] text-[#d3d3d3]'
              }`}
              onClick={() => setIsSelectPrefix(!isSelectPrefix)}
            >
              {prefix}
              {!!onChangePrefix && prefix && (
                <>
                  <div className="block h-3 w-3">
                    <img
                      className={`w-full object-contain duration-300 transition-all ${
                        isSelectPrefix ? 'rotate-180' : ''
                      }`}
                      src="/icons/chevron-down.svg"
                      alt="select"
                    />
                  </div>
                  {isSelectPrefix && (
                    <div className="absolute z-40 left-0 right-0 top-full translate-y-[7px] w-full bg-black border-slate-400 text-white border">
                      <p
                        onClick={() => onChangePrefix('+61')}
                        className="cursor-pointer text-center text-secondaryWhite text-sm py-2 hover:bg-slate-400"
                      >
                        AUS
                      </p>
                      <p
                        onClick={() => onChangePrefix('+64')}
                        className="cursor-pointer text-center text-secondaryWhite text-sm py-2 hover:bg-slate-400"
                      >
                        NZ
                      </p>
                    </div>
                  )}
                </>
              )}
            </div>
            <input
              value={
                value ? value.replace('+61', '').replace('+64', '') : undefined
              }
              onChange={onChange}
              type={'number'}
              onInput={(e) => {
                activeValue(e);
              }}
              autoComplete="off"
              name={nameInput}
              className={`mt-0 text-sm appearance-none ml-2 w-full text-white placeholder:text-white py-[px] bg-transparent gotham  placeholder-slate-400 block focus:outline-none sm:text-base sm:font-normal ${
                revertColor
                  ? ' !text-textSecondary !placeholder:text-textSecondary '
                  : ' !text-white !placeholder:text-white '
              } ${disabled ? '!text-opacity-50' : ''}`}
              placeholder={''}
              disabled={disabled}
            />

            <div
              className={`gotham absolute -z-10 w-[50%] flex items-center float-right   font-normal text-xs text-[#FF6900] justify-end right-1`}
            >
              {(isShowRequired && !active) || errorText !== '' ? (
                <>
                  {errorText !== '' ? errorText : 'This Field is required'}
                  <img
                    className="w-4 ml-[12px] h-4 flex"
                    src={'/icons/warn-circle.svg'}
                  />
                </>
              ) : (
                ''
              )}
            </div>
          </div>
        ) : (
          <input
            value={
              value ? value.replace('+61', '').replace('+64', '') : undefined
            }
            onChange={onChange}
            type={type}
            onInput={(e) => {
              activeValue(e);
            }}
            disabled={disabled}
            name={nameInput}
            autoComplete="off"
            className="mt-1 appearance-none px-6 placeholder:text-white py-4 bg-black border gotham border-borderLine placeholder-slate-400  focus:outline-none  block w-full focus:ring-1 sm:text-base"
            placeholder={''}
          />
        )}
        {img}
      </label>
    </div>
  );
}

export default InputNumber;
