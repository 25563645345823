import React, { useContext, useEffect, useState } from 'react';

import { Auth } from 'aws-amplify';
// import timetableImg from '@/assets/images/dashboard/images/timetable.png'
// import timetableIcon from '@/assets/images/dashboard/icons/timetable.svg'
// import bookingsImg from '@/assets/images/dashboard/images/bookings.png'
// import bookingsIcon from '@/assets/images/dashboard/icons/bookings.svg'
import { navigate } from 'gatsby';

import { AuthContext } from '@/api/auth';
import { getMemberContractByMemberId } from '@/api/mutation';
import useWindowSize from '@/hooks/useWindowSize';
import { useLazyQuery } from '@apollo/client';

import ModalConfirmOtp from '../LayoutDashboard/Sidebar/modals/ModalConfirmOtp';
import useFetch from '@/hooks/useFetch';
import moment from 'moment';

function General() {
  const [getMembership] = useLazyQuery(getMemberContractByMemberId);
  const [userInfoMemberShip, setUserInfoMemberShip] = useState();
  const userInfo = useContext(AuthContext).userInfo;

  const data = [
    {
      text: 'Timetable',
      img: '/images/Dashboard/timetable.png',
      icon: '/icons/Dashboard/timetable.svg',
      link: '/dashboard/class/timetable',
      display: true,
      disabled: userInfo?.outstandingBalance ? true : false,
      description: 'Check out the latest class options from your local club.',
    },
    {
      text: 'My Bookings',
      img: '/images/Dashboard/bookings.png',
      icon: '/icons/Dashboard/bookings.svg',
      link: '/dashboard/class/bookings',
      display: true,
      disabled: userInfo?.outstandingBalance ? true : false,
      description:
        'Keep up to date with what classes you’ve booked so you don’t miss a workout!',
    },
    {
      text: 'My Buddies',
      img: '/images/Dashboard/buddies.png',
      icon: '/icons/Dashboard/buddies.svg',
      link: '/dashboard/class/bookings',
      display: true,
      disabled: true,
      description: 'Coming soon',
    },
    {
      text: 'My Profile',
      img: '/images/Dashboard/profile.png',
      icon: '/icons/Dashboard/profile.svg',
      link: '/dashboard/account',
      display: true,
      disabled: false,
      description: 'Manage, update, and view your profile.',
    },
    {
      text: 'Personal training',
      img: '/images/Dashboard/training.png',
      icon: '/icons/Dashboard/training.svg',
      link: '/dashboard/timetable',
      display: true,
      disabled: true,
      description: 'Coming soon',
    },
    {
      text: 'Invite A Friend',
      img: '/images/Dashboard/friend.png',
      icon: '/icons/Dashboard/mail.svg',
      link: '/dashboard/timetable',
      display: true,
      disabled: true,
      description: 'Coming soon',
    },
    // {
    //   text: 'Upgrade',
    //   img: '/images/dashboard/upgrade.png',
    //   icon: '/icons/dashboard/upgrade.svg',
    //   link: '/dashboard/timetable',
    //   display: true
    // },
    {
      text: 'Member benefits',
      img: '/images/Dashboard/benefit.png',
      icon: '/icons/Dashboard/benefit.svg',
      link: '/dashboard/timetable',
      display: false,
      disabled: true,
      description: '',
    },
  ];
  const { width } = useWindowSize();
  const [modalConfirm, setModalConfirm] = useState(false);
  const [isVerifiedPhoneNumber, setIsVerifiedPhoneNumber] = useState(false);
  const { data: dataPromotionBanner } = useFetch({
    params: 'global',
    populate: 'promotion, promotion.image',
  });
  const isShowPromotionBanner = React.useMemo(() => {
    return moment(moment().format('YYYY-MM-DD')).isBetween(
      dataPromotionBanner?.data?.attributes?.promotion?.startDate,
      dataPromotionBanner?.data?.attributes?.promotion?.endDate,
      undefined,
      '[]'
    );
  }, [dataPromotionBanner]);
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setIsVerifiedPhoneNumber(user.attributes.phone_number_verified);
        getMembership({
          variables: {
            memberId: user.attributes['custom:memberId'],
          },
        })
          .then((membership) => {
            const memberShipDataFilter =
              membership.data.getMemberContractByMemberId.items
                .filter((i) => {
                  if (i.expiryDateTime != null) {
                    if (
                      new Date(i.expiryDateTime).valueOf() <
                      new Date().valueOf()
                    ) {
                      return false;
                    }
                  }

                  if (i.endDateTime != null) {
                    if (
                      new Date(i.endDateTime).valueOf() < new Date().valueOf()
                    ) {
                      return false;
                    }
                  }

                  return true;
                })
                .map((i) => {
                  // if the susspension is currently active have to be in status approved
                  const suspensionItems = i.suspensions.items.filter(
                    (i) =>
                      i.cancelledDateTime === null &&
                      i.suspensionStatus === 'APPROVED'
                  );
                  if (
                    suspensionItems.length > 0 &&
                    suspensionItems.every(
                      (item) =>
                        new Date().valueOf() >
                          new Date(item.suspensionStartDateTime).valueOf() &&
                        new Date().valueOf() <
                          new Date(item.suspensionEndDateTime).valueOf()
                    )
                  ) {
                    return { ...i, isActive: true };
                  }
                  // suspendMembership.push(i);
                  return { ...i, isActive: true };
                })
                .filter((i) => i);
            setUserInfoMemberShip(memberShipDataFilter);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((e) => navigate('/login', { replace: true }));
  }, []);
  return (
    <>
      {modalConfirm && (
        <ModalConfirmOtp
          onClose={() => setModalConfirm(false)}
          onComplete={async () => {
            const user = await Auth.currentAuthenticatedUser();
            await Auth.updateUserAttributes(user, {
              phone_number_verified: true,
            });
            setIsVerifiedPhoneNumber(true);
          }}
        />
      )}
      <div className="bg-[#13181b] sm:bg-transparent sm:px-[14px] sm:pt-0 h-full pl-[60px] text-white pt-[67px] pr-[52px] ">
        <p className="text-white sm:hidden text-sm mb-[9px] font-medium tracking-widest ">
          Members Portal
        </p>
        <p className="uppercase !mb-[48px] !leading-[31px] sm:hidden text-white subheading font-bicyclette">
          HEY,{' '}
          {userInfo?.surname && userInfo?.givenName
            ? userInfo?.givenName + ' ' + userInfo?.surname
            : ''}
          {/* {!isVerifiedPhoneNumber && (
            <span
              className={`text-[35px] relative group cursor-pointer inline-block h-[35px] w-[35px] text-center ml-2 rounded-full text-yellow-300 border border-yellow-300`}
              onClick={() => {
                setModalConfirm(!modalConfirm);
              }}
            >
              !
              <span className="group-hover:block text-start rounded-[5px] bg-slate-500 normal-case hidden absolute bottom-[105%] left-[10px] w-[320px] px-2 py-1">
                {' '}
                you haven't confirm your phone number please click to verify
              </span>
            </span>
          )} */}
        </p>
        <div className="flex sm:flex-col sm:pb-[82px] flex-wrap sm:gap-y-[20px] gap-[15px] relative">
          {userInfoMemberShip?.length === 0 ? (
            <div className="h-full max-w-[1491px] gap-[5px] z-20 flex flex-col w-full absolute bg-[#0F0F0F] opacity-95 items-center justify-center sm:justify-start">
              {isShowPromotionBanner && (
                <img
                  className="mt-[40px] object-contain px-10 h-min sm:mt-6"
                  src={
                    dataPromotionBanner?.data?.attributes?.promotion?.image
                      ?.data?.attributes?.url || ''
                  }
                />
              )}
              {/* <img
                src={'/images/promo-banner-short.png'}
                className="w-[473px] h-[138px] object-contain mb-[45px]"
              /> */}
              <p className="text-[40px] font-bold font-bicyclette leading-[50px] gravity sm:text-center ">
                NO ACTIVE MEMBERSHIP
              </p>
              <p className="text-sm tracking-[0.01em] gotham text-center font-medium leading-[200%]">
                UNFORTUNATELY WE CANNOT FIND AN ACTIVE MEMBERSHIP ON YOUR
                ACCOUNT.
                <br />
                LET’S GET YOU BACK IN THE GYM AND TRAINING AGAIN!{' '}
              </p>
              <div
                onClick={() => {
                  if (
                    !userInfo?.outstandingBalance ||
                    userInfo?.outstandingBalance === 0
                  ) {
                    navigate('/dashboard/memberships');
                  }
                }}
                className={`mt-6 cursor-pointer px-[25px] py-[13px] border !border-white rounded-md text-brand-clime gotham  ${
                  userInfo?.outstandingBalance &&
                  userInfo?.outstandingBalance !== 0
                    ? ' tooltip inline-block '
                    : ''
                }`}
              >
                RENEW MEMBERSHIP
                {userInfo?.outstandingBalance &&
                userInfo?.outstandingBalance !== 0 ? (
                  <div>
                    <span className="triangle invisible absolute bottom-[calc(100%+2px)] left-[5px] w-[13px] h-3 rotate-45 border-white border-b border-r z-10 bg-primary"></span>
                    <span className="border border-white tooltiptext bottom-[calc(100%+8px)]">
                      Please pay your outstanding amount before renewing your
                      membership
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          ) : (
            ''
          )}
          {data.map((data, index) => {
            return (
              <div
                key={index}
                className="group h-[269px] sm:h-[170px] sm:w-full w-[236px] text-white border sm:hover:border-primary border-[rgb(255,255,255,0.4)] sm:border-transparent rounded-[5px] relative "
                onClick={() => {
                  !data.disabled && navigate(data.link);
                }}
              >
                <div className="absolute group-hover:block hidden sm:group-hover:hidden bg-primary opacity-60 h-full w-full"></div>
                <img
                  src={data.img}
                  className="h-full w-full object-cover rounded-md group-hover: "
                />

                <div className="grid grid-rows-3 group-hover:h-full flex-wrap flex-col absolute top-0 h-full w-full sm:pl-[16px] sm:pt-[15px] sm:pr-[16px] p-[15px] cursor-pointer">
                  {/* <div className='w-full grid grid-cols-2'>
                    <div className='flex w-full  justify-between col-span-2'>
                      <div className='my-[5.5px]'><p className='text-white text-lg font-bold '>{data.text}</p></div>
                      <img className='h-[39px] w-[39px]' src={data.icon} />
                    </div>
                    <div className='group-hover:block hidden col-span-2'>aaaaaaaaaaaaaaa</div>
                  </div> */}
                  <div className="w-full  h-fit ">
                    <div className="flex justify-between pl-[3px] items-center">
                      <div className="my-[5.5px]">
                        <p className="text-white text-xl font-bold uppercase leading-[90%] font-bicyclette">
                          {data.text}
                        </p>
                      </div>
                      <img className="h-[39px] w-[39px]" src={data.icon} />
                    </div>
                  </div>
                  {/* <div className='w-full h-max'></div> */}
                  <div className="group-hover:block hidden text-white pl-[3px] pr-[5px] sm:group-hover:hidden ">
                    {!data.disabled
                      ? data?.description
                      : data.description
                      ? data?.description
                      : 'Coming soon'}
                  </div>
                  {!data.disabled && (
                    <div className="ml-[5px] mt-auto mb-[22px] sm:group-hover:hidden justify-self-start group-hover:flex hidden bg-transparent border border-white hover:border-black rounded-md px-1 py-1 text-primary bg-white hover:bg-black hover:text-white cursor-pointer w-[104px]  justify-center items-center h-[30px] uppercase font-medium font-bicyclette">
                      view
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className="mt-[82px] pb-[67px] sm:hidden">
          <p
            className={` mb-[9px] ${
              userInfoMemberShip?.length == 0
                ? 'text-[rgb(43,47,50)]'
                : 'text-white'
            }`}
          >
            Your membership includes:
          </p>
          <p
            className={`text-sm uppercase font-medium ${
              userInfoMemberShip?.length == 0
                ? 'text-[rgb(43,47,50)]'
                : 'text-primary'
            }`}
          >
            Open 24/7, Access 200 gyms Australia wide
          </p>
        </div>
      </div>
    </>
  );
}

export default General;
