import React, { useState, createContext, useEffect, useRef } from 'react';
import { Amplify, Auth, Storage } from 'aws-amplify';
import { ApolloLink } from 'apollo-link';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from '@apollo/client/link/context';
import { navigate } from 'gatsby';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { getMember } from './mutation';
import { config } from '../config';


const AuthContext = createContext<any>(null);
interface iProps {
  children: React.ReactNode;
}
const AuthProvider = ({ children }: iProps) => {
  const [userInfo, setUserInfo] = useState();
  const [refreshClient, setRefresh] = useState(false);
  const [refreshData, setRefreshData] = useState(false);

  const [client, setClient] = useState<any>(null);

  const Init = async () => {
    const getToken = async () => {
      try {
        const jwtToken = (await Auth.currentSession())
          .getIdToken()
          .getJwtToken();
        return jwtToken;
      } catch (e) {
        return '';
      }
    };
    const token = await getToken();

    Amplify.configure({
      region: config.awsRegion,
      identityPoolId:config.identityPoolId,
      aws_project_region: config.awsRegion,
      aws_appsync_graphqlEndpoint: config.awsGraphQlEndpoint,
      aws_appsync_region: config.awsRegion,
      aws_appsync_authenticationType: token
        ? config.awsSignInAuthType
        : config.awsAuthType,
      aws_user_pools_id: config.awsUserPoolsId,
      aws_cognito_identity_pool_id: config.awsCogIdentityPoolId,
      aws_user_pools_web_client_id: config.awsUserPoolClientId,
      aws_user_files_s3_bucket: config.awsS3Bucket,
      aws_user_files_s3_bucket_region: config.awsS3BucketRegion,
    });

    const authLink = createAuthLink({
      url: config.awsGraphQlEndpoint,
      region: config.awsRegion,
      auth: {
        //@ts-ignore
        type: token
          ? config.awsSignInAuthType
          : config.awsAuthType,
        credentials: () => ({
          accessKeyId: config.awsAccessKey,
          secretAccessKey: config.awsSecretKey,
        }),
      },
    });

    const requestMiddleware = setContext(async (_, { headers }) => {
      const token = await getToken();
      return {
        headers: {
          ...headers,
          authorization: token ? `${token}` : '',
        },
      };
    });

    const responseMiddleWare = new ApolloLink((operation, forward) => {
      return forward(operation).map((response) => {
        return response;
      });
    });

    const client = async () => {
      const token = await getToken();
      if (token) {
        navigate('/dashboard');
        return new ApolloClient({
          //@ts-ignore
          link: ApolloLink.from([
            //@ts-ignore
            requestMiddleware,
            responseMiddleWare,
            createHttpLink({ uri: config.awsGraphQlEndpoint }),
          ]),
          cache: new InMemoryCache(),
        });
      } else {
        setUserInfo(undefined);
        setUserInfoMemberShip(undefined);
        return new ApolloClient({
          //@ts-ignore
          link: ApolloLink.from([
            authLink,
            createHttpLink({ uri: config.awsGraphQlEndpoint }),
          ]),
          cache: new InMemoryCache(),
        });
      }
    };
    const current = await client();
    setClient(current);
  };
  useEffect(() => {
    Init();
  }, []);
  const [userInfoMemberShip, setUserInfoMemberShip] = useState();
  const getUserInfo = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const res = await client.query({
        fetchPolicy: 'no-cache',
        query: getMember,
        variables: {
          memberId: user?.attributes['custom:memberId'],
        },
      });
      const data = res.data.getMember;
      const imgUrl = data?.imageUrl
        ? await Storage.get(data.imageUrl)
        : '/icons/Dashboard/profile.svg';
      setUserInfo({ ...data, imageUrl: imgUrl });
      localStorage.setItem(
        'currentUserDetail',
        JSON.stringify(res.data.getMember)
      );
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        Init,
        client,
        refreshClient,
        setRefresh,
        refreshData,
        setRefreshData,
        userInfo,
        setUserInfo,
        userInfoMemberShip,
        setUserInfoMemberShip,
        getUserInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export { AuthContext, AuthProvider };
