import { AuthContext } from '@/api/auth';
import PrimaryOutlinedButton from '@/common/buttons/PrimaryOutlinedButton';
import React, { useContext } from 'react';

interface IModalInviteProps {
  open: boolean;
  onClose: () => void;
}

const ModalInvite = (props: IModalInviteProps) => {
  const { open, onClose } = props;
  const userInfo = useContext(AuthContext).userInfo;

  if (!open) return <></>;
  return (
    <div
      onClick={onClose}
      className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-[100] bg-backDrop"
    >
      <div
        onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
          event.stopPropagation()
        }
        className="fixed flex flex-col justify-between items-center max-w-[700px] max-h-[90vh] min-w-[660px] xs:min-w-[100vw] bg-black"
      >
        <div className="inviteModal pt-8 px-8 pb-10 w-full">
          <div className="inviteModalHeader flex items-center justify-between w-full">
            {/* Left header */}
            <div className="flex items-center gap-x-4">
              <div className="block w-9 h-9">
                <img src="/icons/icon-play.png" className="object-fit w-full" />
              </div>
              <p className="text-white text-sm font-medium leading-5 uppercase">
                HELLO {userInfo.surname}
              </p>
            </div>

            {/* Right header */}
            <div
              onClick={onClose}
              className="flex items-center justify-center w-8 h-8 rounded-full border border-white cursor-pointer"
            >
              <div className="w-4 h-4 block">
                <img
                  src="/icons/close.png"
                  alt="close"
                  className="object-fit w-full"
                />
              </div>
            </div>
          </div>

          <div className="inviteModalContent w-full h-full mt-7">
            <p className="text-white text-3xl uppercase font-bold mb-3 font-bicyclette">
              Invite your Class buddies
            </p>
            <p className="text-white text-sm font-normal mb-10">
              Barre, 7:30am, Monday, 10 July 2021 at ANU
            </p>
            <div className="">
              <p className="text-white text-xs font-normal pb-2 border-b border-white border-opacity-40 font-bicyclette">
                Invite your class buddies
              </p>

              {['SARAH W', 'EMILY W', 'STEPH K', 'PHOEBE MH'].map(
                (e, index) => {
                  return (
                    <div
                      key={index}
                      className="flex items-center justify-between pb-2 pt-3 border-b border-white border-opacity-40"
                    >
                      <p className="text-white font-medium uppercase text-xs tracking-widest">
                        {e}
                      </p>
                      <input className="w-6 h-6" type="checkbox" />
                    </div>
                  );
                }
              )}
            </div>
          </div>

          <div className="inviteModalBottom mt-10">
            <div className="flex items-center justify-end w-full">
              <PrimaryOutlinedButton className="!p-0">
                <div className="flex items-center">
                  <p className="text-xs uppercase font-medium border-r border-white p-3">
                    SEND REQUEST
                  </p>
                  <div className="h-10 w-10 flex items-center justify-center p-3">
                    <img
                      src="/icons/arrow-right-white.png"
                      alt="arrow-right"
                      className="w-4 h-2 object-contain"
                    />
                  </div>
                </div>
              </PrimaryOutlinedButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalInvite;
