import React, { ComponentPropsWithoutRef } from 'react';

interface IProps extends ComponentPropsWithoutRef<'button'> {
  children?: React.ReactNode;
  className?: string;
}

const MobileButton = (props: IProps) => {
  const { children = <></>, className = '', ...rest } = props;

  return (
    <button
      className={`bg-white rounded-md min-h-[40px] w-full flex items-center justify-center text-center text-primary text-xl leading-[47.5px] font-bold ${className}`}
      {...rest}
    >
      {children}
    </button>
  );
};

export default MobileButton;
