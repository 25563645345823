export function getTimezoneOffset(timeZone: string, date = new Date()) {
  const tz = date.toLocaleString("en", {timeZone, timeStyle: "long"}).split(" ").slice(-1)[0];
  const dateString = date.toString();
  const offset = Date.parse(`${dateString} UTC`) - Date.parse(`${dateString} ${tz}`);
  
  // return UTC offset in hours
  return offset  / (1000 * 3600);
}

export function convertToFixedAEST(utcDate: string, offset: number) {
  const date = new Date(utcDate)
  const fixedOffsetDate = new Date(date.getTime() + offset * 60 * 60 * 1000).toISOString();
  return fixedOffsetDate.substring(fixedOffsetDate.indexOf("T") + 1, fixedOffsetDate.indexOf("T") + 6)
}

export function classTimeFromUTCtoAusTimeZone (curr: any, timeZone: string) {
  const offset = getTimezoneOffset(timeZone, new Date(curr.createdAt))
  const startTime = convertToFixedAEST(curr.classScheduleDateTime, offset)
  const endTime = convertToFixedAEST(new Date(new Date(curr.classScheduleDateTime).getTime()+ curr.classTemplateDetails.classDuration * 60000).toUTCString(), offset)

  return `${startTime}-${endTime}`;
};