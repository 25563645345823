import React from 'react';
import 'react-quill/dist/quill.snow.css';
import loadable from '@loadable/component';
const ReactQuill = loadable(() => import('react-quill'));
import './styles.css';

interface IMyReactQuillProps {
  content?: string;
}

const MyReactQuill = (props: IMyReactQuillProps) => {
  if (!props.content) return null;
  return <ReactQuill theme={'bubble'} readOnly={true} value={props.content} />;
};

export default MyReactQuill;
