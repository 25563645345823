import React, { useState } from 'react';
// import warnCircle from "../../assets/images/icons/warnCircle.svg";
interface InputType {
  holder: string;
  // label: string;
  type?: string;
  nameInput: string;
  value?: any;
  onchange: any;
  // isActiveData: boolean;
  errorMessageStart?: boolean;
  img?: any;
  isRequired?: boolean;
  isShowRequired?: boolean;
  errorText?: string;
  width?: string;
  style: string;
  isDashboard?: boolean;
  positionMessage?: string;
  revertColor?: boolean;
  disabled?: boolean;
  isShowRequireIcon?: boolean;
}
function InputBase({
  holder,
  // label,
  isShowRequireIcon = true,
  type = 'text',
  value,
  onchange,
  nameInput,
  errorMessageStart = false,
  // isActiveData = false,
  img = null,
  isRequired = false,
  isDashboard = false,
  isShowRequired = false,
  errorText = '',
  width = '',
  style = '',
  positionMessage = '',
  revertColor = false,
  disabled = false,
}: InputType) {
  const [active, setActive] = useState(false);
  const activeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    return e.target.value === ''
      ? (setActive(false),
        (e.target.style.borderColor = ''),
        (e.target.style.color = ''),
        (e.target.style.caretColor = '#ffffff'))
      : (setActive(true),
        (e.target.style.borderColor = '#d3d3d3'),
        (e.target.style.caretColor = '#d3d3d3'),
        (e.target.style.color = isDashboard ? 'white' : '#d3d3d3'));
  };
  const [passShow, setPassShow] = useState(false);
  return (
    <div
      style={{
        width: `${width !== '' ? `${width}` : style == '' ? '373px' : ''}`,
      }}
      className={` relative ${style}`}
    >
      <label>
        <span className="after:content-[''] after:ml-0.5 after:text-white block text-[12px] gotham font-medium text-white">
          {active ? '' : ''}
        </span>
        <span
          className={`absolute  block text-[12px] sm:text-base gotham font-medium sm:font-normal transition-all duration-300 ${
            active || value
              ? isDashboard
                ? ' top-0 sm:-top-1 left-0 text-white'
                : 'top-0 sm:-top-1 left-0 text-textSecondary'
              : 'top-[23px] left-[1px] text-white'
          } ${revertColor ? ' text-white ' : ' text-textSecondary '} `}
        >
          {holder}
        </span>
        {isRequired ? (
          <div
            style={{ borderColor: active ? '' : '' }}
            className={`flex inputBorder items-center  border-b ${
              (active || !!value) && !isDashboard
                ? 'border-[#d3d3d3]'
                : 'border-borderLine'
            } ${disabled ? ' !border-opacity-50' : ''} `}
          >
            <input
              onChange={(e) => {
                onchange(e);
                activeValue(e);
              }}
              type={
                type == 'password' ? (passShow ? 'text' : 'password') : type
              }
              value={value}
              autoComplete="off"
              name={nameInput}
              className={`mt-0 w-full py-[7px] bg-transparent placeholder-slate-400 block text-sm sm:text-base sm:font-normal focus:outline-none ${
                revertColor
                  ? ' !text-textSecondary !placeholder:text-textSecondary '
                  : ' !text-white !placeholder:text-white '
              } ${disabled ? ' !text-opacity-50' : ''}                `}
              placeholder={''}
              disabled={disabled}
            />

            {isShowRequired && (
              <div
                className={`gotham absolute flex items-center float-right   font-normal text-xs text-[#FF6900] ${
                  type == 'password'
                    ? 'justify-start right-3'
                    : errorMessageStart
                      ? 'justify-start left-1'
                      : 'justify-end right-1'
                } ${
                  active &&
                  positionMessage != 'bottom' &&
                  !isShowRequired &&
                  ' -z-10 '
                } ${
                  positionMessage == 'bottom'
                    ? active
                      ? isShowRequired
                        ? '-bottom-5'
                        : 'hidden'
                      : '-bottom-5'
                    : 'right-1 w-[50%]'
                }`}
              >
                {(isShowRequired && !active) || errorText !== '' ? (
                  <>
                    {errorText !== '' ? errorText : 'This Field is required'}
                    {isShowRequireIcon && (
                      <img
                        className="w-4 ml-[12px] h-4 flex"
                        src={'/icons/warn-circle.svg'}
                      />
                    )}
                  </>
                ) : (
                  ''
                )}
              </div>
            )}
          </div>
        ) : (
          <input
            value={value}
            onChange={(e) => {
              onchange(e);
              activeValue(e);
            }}
            type={type}
            name={nameInput}
            autoComplete="off"
            className="mt-1 px-6 placeholder:text-white py-4 bg-black border gotham border-borderLine placeholder-slate-400  focus:outline-none  block w-full sm:text-base sm:font-normal focus:ring-1"
            placeholder={''}
            disabled={disabled}
          />
        )}
        {img}
      </label>

      {type == 'password' && (
        <svg
          className="absolute right-[12px] bottom-[13px] z-10 cursor-pointer"
          width="24"
          height="13"
          viewBox="0 0 24 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => {
            setPassShow(!passShow);
          }}
        >
          <path
            d="M12.2015 12.4596C9.58119 12.4312 7.40012 11.6499 5.35427 10.4319C3.83612 9.52816 2.4696 8.43176 1.23733 7.16589C0.98859 6.91023 0.886973 6.65844 1.17349 6.36822C3.70251 3.80625 6.57737 1.83918 10.1939 1.16919C12.5511 0.732649 14.7944 1.17063 16.9489 2.14082C19.1538 3.13368 21.0591 4.55567 22.7472 6.27866C23.0093 6.54621 23.1226 6.79608 22.8143 7.108C20.3976 9.55196 17.6533 11.4438 14.2389 12.208C13.9388 12.2752 13.6343 12.33 13.3288 12.3644C12.8854 12.4143 12.4392 12.4403 12.2015 12.4596ZM1.9755 6.707C2.07631 6.8173 2.13323 6.8874 2.19787 6.94947C3.50458 8.20634 4.94121 9.28843 6.5465 10.1358C8.99287 11.4271 11.5566 12.0345 14.3069 11.3465C17.3089 10.5955 19.7715 8.92135 21.9536 6.78627C21.9709 6.76939 21.9658 6.72967 21.9762 6.67356C20.0818 4.84332 17.9672 3.33048 15.4465 2.44631C13.8202 1.87584 12.1531 1.65878 10.4416 1.96379C7.09061 2.56095 4.43344 4.39617 1.9755 6.707Z"
            fill="#2757FF"
            stroke="#2757FF"
            strokeWidth="0.5"
          />
          <path
            d="M7.84719 6.71367C7.85378 4.42246 9.70942 2.5747 12.0045 2.57422C14.3073 2.57374 16.1785 4.46201 16.16 6.76786C16.1418 9.05022 14.2672 10.8936 11.9733 10.8848C9.68771 10.8761 7.84059 9.0089 7.84719 6.71367ZM8.67073 6.70515C8.66157 8.53956 10.1394 10.045 11.9646 10.0608C13.8116 10.0767 15.3331 8.57333 15.3353 6.73039C15.3376 4.90932 13.8373 3.40468 12.0125 3.39793C10.1837 3.39118 8.6799 4.87942 8.67073 6.70531V6.70515Z"
            fill="#2757FF"
            stroke="#2757FF"
            strokeWidth="0.5"
          />
        </svg>
      )}
    </div>
  );
}

export default InputBase;
