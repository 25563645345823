import React from 'react';
import Sidebar from './Sidebar';
import Footer from './Footer';
import Header from './Header';

interface IProps {
  children: React.ReactNode;
}

function LayoutDashboard(props: IProps) {
  return (
    <div id="Layout" className="w-full">
      <div className="flex sm:block bg-gradient-to-b from-[#03030C] to-[#0A0E38]">
        <Header />
        <Sidebar />
        <div className="dashboardPages w-[calc(100%-339px)] sm:w-full max-h-[calc(100vh-40px)] sm:max-h-none sm:min-h-[calc(100vh-292.59px-78px)] overflow-auto bg-[#13181b] sm:bg-transparent">
          {props.children}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default LayoutDashboard;
