import React from 'react';
import "./styles.css"

enum STATUS {
  IDLE = 'idle',
  LOADING = 'loading',
  UNSUBSCRIBED = 'unsubscribed'
}

const OptOutPage = () => {
  const [sms, setSms] = React.useState<string>('')

  const [status, setStatus] = React.useState<STATUS>(STATUS.IDLE)

  const onChangeSms = (value: string): void => {
    setSms(value)
  }

  const onUnsubscribe = (): void => {
    setStatus(STATUS.UNSUBSCRIBED)
  }

  return (
    <main id='opt-out-page' className="bg-black h-[100vh] w-[100vw] flex items-center justify-center relative">
      <div className="w-[600px] rounded-md">
        {/* HEADER */}
        <div className="flex items-center justify-center border-b h-[158px] border-primary bg-gradient-to-b from-primary to-[#000]">
          <div className="h-[68px]">
            <img
              className="block w-full object-contain"
              src="/icons/plus-fitness-inline-revert.png"
              alt="plus-fitness"
            />
          </div>
        </div>
        {/* CONTENT */}
        <div className="p-9 bg-[#FFFFFF] min-h-[440px] h-[440px]">
          {/* STEP 1 */}
          {status === STATUS.IDLE && <div className="flex flex-col items-start justify-start h-full w-full gap-y-6">
            <p className="text-black text-xl font-bold">
              We are sorry to see you go.
            </p>
            <p className="text-black text-sm font-normal">
              We enjoy keeping in touch with you but also respect your inbox. If
              you'd prefer to limit the types of marketing messages, please select
              your preferred methods:
            </p>

            <p>
              <input type="checkbox" id="emailCheckbox" />
              <label htmlFor="emailCheckbox">Email</label>

              <input readOnly value={'prefilled@emailaddress.com'} type='text' id='emailTextbox' className="min-w-[250px] h-[38px] rounded-md bg-[#e4e4e4] p-[10px] text-black text-sm font-normal mt-2 ml-6" />
            </p>

            <p>
              <input type="checkbox" id="smsCheckbox" />
              <label htmlFor="smsCheckbox">SMS</label>

              <input value={sms} onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangeSms(event.target.value)} type='text' id='smsTextbox' className="min-w-[250px] h-[38px] rounded-md bg-[#e4e4e4] p-[10px] text-black text-sm font-normal mt-2 ml-6" />
            </p>

            <button onClick={onUnsubscribe} className='bg-primary border border-solid border-white rounded-md text-white text-sm font-normal p-[10px] hover:bg-white hover:!border-primary hover:text-primary mt-auto' >Unsubscribe</button>
          </div>}

          {/* STEP 2 */}
          {status === STATUS.UNSUBSCRIBED && <div className="flex flex-col items-start justify-start h-full w-full gap-y-6">
            <p className='text-black text-xl font-bold'>You've successfully unsubscribed from Plus Fitness.</p>
            <p className='text-black text-sm font-normal'>Remember, these are your choices, and you can update them anytime. We're here to make your experience with us as enjoyable as possible.</p>
            <p className='text-black text-sm font-normal mt-3'>Miss us already?  <a className='underline text-primary' href="#">Click here</a> to Rejoin</p>
          </div>}
        </div>
      </div>
    </main>
  );
};

export default OptOutPage;
