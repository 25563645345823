export const config = {
  awsAccessKey: `${process.env.GATSBY_ACCESS_KEY_ID}`,
  awsSecretKey: `${process.env.GATSBY_SECRET_ACCESS_KEY}`,
  awsRegion: `${process.env.GATSBY_AWS_REGION}`,
  identityPoolId: `${process.env.GATSBY_AWS_COGNITO_IDENTITY_POOL_ID}`,
  awsGraphQlEndpoint: `${process.env.GATSBY_AWS_ENDPOINT}`,
  awsSignInAuthType: `${process.env.GATSBY_AWS_SIGNIN_AUTH_TYPE}`,
  awsAuthType: `${process.env.GATSBY_AWS_AUTH_TYPE}`,
  awsUserPoolsId: `${process.env.GATSBY_AWS_USER_POOLS_ID}`,
  awsCogIdentityPoolId: `${process.env.GATSBY_AWS_COGNITO_IDENTITY_POOL_ID}`,
  awsUserPoolClientId: `${process.env.GATSBY_AWS_USER_POOLS_WEB_CLIENT_ID}`,
  awsS3Bucket: `${process.env.GATSBY_AWS_S3_BUCKET_NAME}`,
  awsS3BucketLink: `${process.env.GATSBY_BUCKET_LINK}`,
  awsS3BucketRegion: `${process.env.GATSBY_AWS_S3_BUCKET_REGION}`,
  vivaPayEndpoint: `${process.env.GATSBY_VIVAPAY_ENPOINT}`,
  vivaPayIFrame: `${process.env.GATSBY_VIVAPAY_IFRAME}`
}