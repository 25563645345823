import PrimaryOutlinedButton from '@/common/buttons/PrimaryOutlinedButton';
import LayoutDashboard from '@/components/LayoutDashboard';
import React, { useEffect, useState } from 'react';
import SelectFieldPortal from '@/common/inputs/SelectFieldPortal';
import './styles.css';
import ModalCancel from './components/ModalCancel';
import ModalInvite from './components/ModalInvite';
import { Auth } from 'aws-amplify';
import { getClassBookingByMemberId, getListLocation } from '@/api/mutation';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment';
import { Link, navigate } from 'gatsby';
import { validateOperation } from '@apollo/client/link/utils';

export interface ClassTemplate {
  id: string;
  classDescription: string;
  className: string;
  hasVirtualClass: boolean;
  isActive: boolean;
  isBookable: boolean;
  classDuration: number;
}
export interface Instructor {
  id: string;
  instructorDisplayName: string;
  givenName: string;
  instructorFullName: string;
}

export interface BookInfo {
  classScheduleDateTime: string;
  classScheduleEndDateTime: string;
  classTemplateDetails: Partial<ClassTemplate>;

  id: string;
  instructor: string;
  instructorDetails: Partial<Instructor>;
  locationId: string;
}

interface IBookingTable extends BookInfo {
  place: string;
  time: string;
  timezone: string;
  action: string;
  brand: string;
  class: string;
  coach: string;
  date: string;
}

export interface ClassBookings {
  id: string;
  bookedDateTime: string;
  cancelledDateTime: string;
  classScheduleDetails: Partial<ClassScheduleDetails>;
  locationDetails: Partial<ClassLocation>;
  hasAttended: boolean;
  waitlistClassScheduleId: string;
  waitlistClassScheduleDetails: Partial<ClassScheduleDetails> | null;
}
export interface ClassScheduleDetails {
  id: string;
  classScheduleDateTime: string;
  classScheduleEndDateTime: string;
  classTemplateDetails: Partial<ClassTemplate>;
  instructorDetails: Partial<Instructor>;
  locationId: string;
}
export interface ClassLocation {
  name: string;
  timezone: string;
}

enum TAB {
  UPCOMING = 'UPCOMING',
  CLASS_HISTORY = 'CLASS_HISTORY',
}

const MyBookings = () => {
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const [tab, setTab] = React.useState<TAB>(TAB.UPCOMING);

  const [currentClass, setCurrentClass] = React.useState<any>();

  const [isOpenCancel, setIsOpenCancel] = React.useState<boolean>(false);

  const [isOpenInvite, setIsOpenInvite] = React.useState<boolean>(false);

  const handleCloseCancel = () => {
    fetchData();
    setIsOpenCancel(false);
  };

  const handleOpenCancel = (classData: any) => {
    setCurrentClass(classData);
    setIsOpenCancel(true);
  };

  const handleCloseInvite = () => {
    setIsOpenInvite(false);
  };

  const handleOpenInvite = () => {
    setIsOpenInvite(true);
  };

  const onChangeTab = (tab: TAB) => {
    setTab(tab);
  };
  const [myBookingApi] = useLazyQuery(getClassBookingByMemberId);
  const [getLocation] = useLazyQuery(getListLocation);
  const [myBooking, setMyBooking] = useState<Array<IBookingTable>>([]);
  const [myBookingPast, setMyBookingPast] = useState<Array<IBookingTable>>([]);
  const [listLocation, setListLocation] = useState();
  const sortByCreatedAtDesc = (arr, sort) => {
    if (sort == 'desc')
      return arr.sort((a, b) => {
        const result =
          new Date(a.classScheduleDateTime).valueOf() -
          new Date(b.classScheduleDateTime).valueOf();
        return result;
      });
    if (sort == 'asc')
      return arr.sort((a, b) => {
        const result =
          new Date(b.classScheduleDateTime).valueOf() -
          new Date(a.classScheduleDateTime).valueOf();
        return result;
      });
  };
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = () => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        myBookingApi({
          fetchPolicy: 'no-cache',
          variables: {
            memberId: user.attributes['custom:memberId'],
          },
        }).then(async (res) => {
          const locationData = await getLocation();
          setListLocation(locationData.data?.listLocations.items);
          const date = moment().format('DD/MM/YYYY');
          const mapData = (v, i: ClassBookings, timezone) => {
            return {
              ...v,
              id: i.id,
              date:
                date ===
                moment(
                  new Date(v.classScheduleDateTime.split('T')[0] ?? '')
                ).format('DD/MM/YYYY')
                  ? 'TODAY'
                  : moment(
                      new Date(v.classScheduleDateTime.split('T')[0] ?? '')
                    ).format('DD/MM/YYYY'),
              time:
                new Date(v.classScheduleDateTime).toLocaleString('en-AU', {
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                  timeZone: timezone,
                }) +
                '-' +
                new Date(
                  new Date(v.classScheduleDateTime).getTime() +
                    v.classTemplateDetails.classDuration * 60000
                ).toLocaleString('en-AU', {
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                  timeZone: timezone,
                }),
              class: v?.classTemplateDetails?.className,
              coach: v?.instructorDetails?.instructorDisplayName,
              brand: 'club lime',
              place: locationData?.data.listLocations.items.filter(
                (it: any) => it.id == v?.locationId
              )[0].name,
              action: 'book me',
              instructor: 'Laura B',
            };
          };
          const uppBooking: IBookingTable[] = [];
          const pastBooking: IBookingTable[] = [];
          res.data.getClassBookingsByMemberId.items
            .filter((i: ClassBookings) => !Boolean(i.cancelledDateTime))
            .forEach((i: ClassBookings) => {
              const timeZone = i.locationDetails.timezone;
              const date = i.classScheduleDetails
                ? i.classScheduleDetails?.classScheduleDateTime
                : i.waitlistClassScheduleDetails?.classScheduleDateTime;
              const classData = i.classScheduleDetails
                ? { ...i.classScheduleDetails }
                : { ...i.waitlistClassScheduleDetails };
              if (moment(new Date(date ?? '')).valueOf() > moment().valueOf()) {
                uppBooking.push(mapData(classData, i, timeZone));
              } else {
                pastBooking.push(mapData(classData, i, timeZone));
              }
            });
          setMyBooking(sortByCreatedAtDesc(uppBooking, 'desc'));
          setMyBookingPast(sortByCreatedAtDesc(pastBooking, 'asc'));
        });
      })
      .catch(() => navigate('/login', { replace: true }));
  };
  return (
    <LayoutDashboard>
      <div className="pl-[60px] pt-[50px] sm:hidden">
        <Link to="/dashboard">
          <div className=" flex gap-[8px] items-center ">
            <div className="group w-[41px] h-[48px] p-[7px] pl-0">
              <div className="border overflow-hidden border-white rounded-full w-[34px] h-[34px] flex justify-center items-center">
                <img
                  className="duration-300 transition-all group-hover:-translate-x-1/2 h-[15px] w-[15px]"
                  src="/icons/arrow-left-short.svg"
                />
              </div>
            </div>
            <span className="gotham uppercase text-sm leading-[19px] text-white tracking-widest">
              dashboard
            </span>
          </div>
        </Link>
      </div>
      {isLoading && (
        <div className="fixed flex items-center h-[100vh] w-[100vw] z-50 bg-[rgba(212,212,212,0.2)] ">
          <div className=" animate-spin h-12 w-12  border-r-8 border-8 border-gray border-r-white rounded-full m-auto "></div>
        </div>
      )}

      <main id="my-bookings-page">
        <section className="px-[61.5px] pt-[37px] pb-[50px] sm:hidden">
          {/* MY BOOKINGS */}
          <section>
            <div className="flex items-center justify-between">
              <h2 className="subheading text-white uppercase !mb-0 font-bicyclette">
                {tab === TAB.UPCOMING ? ' MY BOOKINGS' : 'CLASS HISTORY'}
              </h2>
              <div className="flex items-center">
                <PrimaryOutlinedButton
                  className={`font-medium text-[11px] uppercase rounded-l-md border-r-0 hover:!text-black hover:!bg-white hover:!border-white ${
                    tab === TAB.UPCOMING ? '!text-black bg-white' : ' '
                  }`}
                  onClick={() => onChangeTab(TAB.UPCOMING)}
                >
                  Upcoming
                </PrimaryOutlinedButton>
                <PrimaryOutlinedButton
                  className={`font-medium text-[11px] uppercase rounded-r-md border-l-0 hover:!text-black hover:!bg-white hover:!border-white ${
                    tab === TAB.CLASS_HISTORY ? '!text-black bg-white' : ' '
                  }`}
                  onClick={() => onChangeTab(TAB.CLASS_HISTORY)}
                >
                  Class History
                </PrimaryOutlinedButton>
              </div>
            </div>

            {/* UPCOMING --- TAB */}
            {tab === TAB.UPCOMING && (
              <div className="mt-12">
                <table>
                  <thead>
                    <tr className="text-sm">
                      <th>Day</th>
                      <th>Time</th>
                      <th className="">Class</th>
                      <th>Instructor</th>
                      <th>Location</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {myBooking &&
                      myBooking.map((e) => {
                        return (
                          <tr key={e.id} className="hover:bg-[#1F2528]">
                            <td className="font-medium">{e.date}</td>
                            <td className="font-medium">{e.time}</td>
                            <td>
                              <div className="flex font-medium items-center gap-x-4 h-full cursor-pointer">
                                {e.class}
                              </div>
                            </td>
                            <td className="font-normal">{e.coach}</td>
                            <td className="font-normal">{e.place}</td>
                            <td>
                              <div className="flex items-center justify-end gap-x-2">
                                <PrimaryOutlinedButton
                                  className="rounded-md !px-4 hover:!bg-[#ff0000] hover:!border-[#ff0000] hover:!text-black"
                                  onClick={() => handleOpenCancel(e)}
                                >
                                  <svg
                                    width="11"
                                    height="11"
                                    viewBox="0 0 11 11"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M5.53488 4.08761L1.90695 0.459684L0.651133 1.7155L4.27906 5.34343L0.686018 8.93648L1.97672 10.2272L5.56977 6.63414L9.1977 10.2621L10.4535 9.00625L6.82559 5.37832L10.4186 1.78527L9.12793 0.494568L5.53488 4.08761Z"
                                      fill="white"
                                    />
                                  </svg>
                                </PrimaryOutlinedButton>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            )}

            {/* CLASS HISTORY --- TAB */}
            {tab === TAB.CLASS_HISTORY && (
              <div className="mt-12">
                <table>
                  <thead>
                    <tr className="text-sm">
                      <th>Day</th>
                      <th>Time</th>
                      <th className="">Class</th>
                      <th>Instructor</th>
                      <th>Location</th>
                    </tr>
                  </thead>
                  <tbody>
                    {myBookingPast &&
                      myBookingPast.map((e) => {
                        return (
                          <tr key={e.id} className="hover:bg-[#1F2528]">
                            <td className="font-medium">{e.date}</td>
                            <td className="font-medium">{e.time}</td>
                            <td>
                              <div className="flex font-medium items-center gap-x-4 h-full cursor-pointer">
                                {e.class}
                              </div>
                            </td>
                            <td className="font-normal">{e.coach}</td>
                            <td className="font-normal">{e.place}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            )}
          </section>
        </section>

        <section className="hidden sm:block px-6">
          <PrimaryOutlinedButton
            className="w-full rounded-md !h-[52px]"
            onClick={() => navigate('/dashboard/class/timetable')}
          >
            CLASSES
          </PrimaryOutlinedButton>

          <p className="my-6 text-white font-bicyclette text-2xl font-bold uppercase leading-[90%]">
            {tab === TAB.UPCOMING ? ' MY BOOKINGS' : 'CLASS HISTORY'}
          </p>

          <section className="flex justify-center w-full">
            <PrimaryOutlinedButton
              className={`rounded-l-md !px-0 w-[163px] h-[52px] font-bold ${
                tab === TAB.UPCOMING
                  ? ' !bg-primary !border-primary !text-white'
                  : 'border-r-0'
              }`}
              onClick={() => {
                onChangeTab(TAB.UPCOMING);
              }}
            >
              UPCOMING
            </PrimaryOutlinedButton>
            <PrimaryOutlinedButton
              className={`rounded-r-md !px-0 w-[163px] h-[52px] font-bold ${
                tab === TAB.CLASS_HISTORY
                  ? ' !bg-primary !border-primary !text-white'
                  : 'border-l-0'
              }`}
              onClick={() => {
                onChangeTab(TAB.CLASS_HISTORY);
              }}
            >
              CLASS HISTORY
            </PrimaryOutlinedButton>
          </section>
          <section className="mt-[8px] ">
            {tab === TAB.UPCOMING &&
              myBooking &&
              myBooking?.map((e) => {
                return (
                  <div className="h-[76px] border-b-gray border-b grid grid-cols-2 text-white py-[16px] ">
                    <div className="">
                      <div className="font-bold leading-[24px]">{e.time}</div>
                      <div className="font-normal leading-[24px]">
                        {e.class}
                      </div>
                    </div>
                    <div className="flex justify-end gap-[10px] ">
                      <PrimaryOutlinedButton
                        onClick={() => handleOpenCancel(e)}
                        className="!text-textError !h-[34px] focus:bg-textError focus:!text-white rounded-md !w-[34px] !p-0 !border-textError"
                      >
                        X
                      </PrimaryOutlinedButton>
                    </div>
                  </div>
                );
              })}
            {tab === TAB.CLASS_HISTORY &&
              myBookingPast &&
              myBookingPast.map((e) => (
                <div className="h-[109px] border-b-gray border-b grid grid-cols-2 text-white py-[16px] ">
                  <div className="font-bold leading-[24px] col-span-2 text-primary text-[20px] ">
                    {e.place}
                  </div>
                  <div className="">
                    <div className="font-bold leading-[24px]">
                      {moment(e.date, 'DD/MM/YYYY').format('ddd DD/MM/YYYY')}
                    </div>
                    <div className="font-bold leading-[24px]">{e.time}</div>
                  </div>
                  <div className="flex flex-col items-end gap-[10px] ">
                    <div className="font-normal ">{e.class}</div>
                    <div className="text-textSecondary truncate">
                      Instructor: {e.coach}
                    </div>
                  </div>
                </div>
              ))}
          </section>
        </section>
      </main>

      <ModalCancel
        open={isOpenCancel}
        locationInfo={listLocation}
        onClose={handleCloseCancel}
        classDetail={currentClass}
      />

      <ModalInvite open={isOpenInvite} onClose={handleCloseInvite} />
    </LayoutDashboard>
  );
};
export default MyBookings;
