import { AuthContext } from '@/api/auth';
import { cancelBooking } from '@/api/mutation';
import PrimaryOutlinedButton from '@/common/buttons/PrimaryOutlinedButton';
import ThreeDot from '@/common/loading/ThreeDot';
import { toastError, toastSuccess } from '@/common/toasts/toast';
import { useMutation } from '@apollo/client';
import { utcToZonedTime } from 'date-fns-tz';
import moment from 'moment';
import React, { useContext, useState } from 'react';

interface IModalCancelProps {
  open: boolean;
  onClose: () => void;
  classDetail: any;
  locationInfo: any;
  reFreshData?: any;
}

const ModalCancel = (props: IModalCancelProps) => {
  const userInfo = useContext(AuthContext).userInfo;
  const [loading, setLoading] = useState(false);
  const { open, onClose, classDetail, locationInfo, reFreshData } = props;
  const [cancelBookingApi] = useMutation(cancelBooking);

  const cancelClassBooking = async () => {
    setLoading(true);
    try {
      await cancelBookingApi({ variables: { bookingId: classDetail.id } });
      if (typeof reFreshData === 'function') reFreshData();
      toastSuccess('Class canceled');
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  if (!open) return <></>;
  return (
    <div
      onClick={onClose}
      className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-[100] bg-backDrop"
    >
      <div
        onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
          event.stopPropagation()
        }
        className="fixed flex flex-col justify-between items-center max-w-[688px] max-h-[90vh] min-w-[400px] xs:min-w-[100vw] bg-black"
      >
        {loading && <ThreeDot />}

        <div className="cancelModal pt-8 px-8 pb-10 w-full">
          <div className="cancelModalHeader flex items-center justify-between w-full">
            {/* Left header */}
            <div className="flex items-center gap-x-4">
              <div className="block w-9 h-9">
                <img
                  src="/icons/default-avatar.svg"
                  className="object-fit w-full"
                />
              </div>
              <p className="text-white text-sm font-medium leading-5 uppercase">
                HELLO {userInfo.surname}
              </p>
            </div>

            {/* Right header */}
            <div
              onClick={onClose}
              className="flex items-center justify-center w-8 h-8 rounded-full border border-white cursor-pointer"
            >
              <div className="w-4 h-4 block">
                <img
                  src="/icons/close.png"
                  alt="close"
                  className="object-fit w-full"
                />
              </div>
            </div>
          </div>

          <div className="cancelModalContent w-full h-full">
            <div className=" flex items-center mt-7 ">
              {/* Left content */}
              <div
                className="sm:hidden w-1/2 h-full relative opacity-80 "
                style={{
                  minWidth: '310px',
                  minHeight: '370px',
                  backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.24)), url('/images/MyBookingsPage/cancel-booking-modal.jpg')`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                }}
              ></div>
              {/* Right content */}
              <div className=" sm:w-full w-1/2 h-full relative -left-7 sm:static">
                <p className="text-xs text-[#7681FF] tracking-[1.2px] uppercase leading-4 font-medium mb-7">
                  Are you sure you want to cancel?
                </p>
                <p className="uppercase text-white text-3xl leading-7 font-bold mb-12 font-bicyclette">
                  {classDetail?.classTemplateDetails?.className} <br />{' '}
                  {classDetail?.classTemplateDetails?.classDuration} MIN <br />{' '}
                  {moment(
                    utcToZonedTime(
                      classDetail.classScheduleDateTime,
                      classDetail.location.timezone
                    )
                  ).format('h:mma')}{' '}
                  <br />
                  {moment(
                    utcToZonedTime(
                      classDetail.classScheduleDateTime,
                      classDetail.location.timezone
                    )
                  ).format('dddd, DD/MM/YY')}
                  <br />{' '}
                  {classDetail.place
                    ? classDetail.place
                    : locationInfo.filter(
                        (i) => i.id === classDetail.locationId
                      )[0].name}
                </p>

                <div className="btnGroup flex items-center gap-x-8 sm:justify-evenly">
                  <PrimaryOutlinedButton
                    className="rounded-md w-[108px]"
                    onClick={() => cancelClassBooking()}
                  >
                    Yes
                  </PrimaryOutlinedButton>
                  <PrimaryOutlinedButton
                    onClick={() => onClose()}
                    className="rounded-md w-[108px] hover:!bg-[#ff0000] hover:!border-[#ff0000]"
                  >
                    No
                  </PrimaryOutlinedButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalCancel;
