import React from 'react';

import { navigate } from 'gatsby';
import moment from 'moment';

import ArrowButton from '@/common/buttons/ArrowButton';
import ArrowButtonSecondary from '@/common/buttons/ArrowButtonSecondary';

import appstore from '../../assets/images/appstore.png';
import googlePlay from '../../assets/images/chplay.png';

function Congrats(props) {
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  return (
    <div
      id="hide-scroll"
      className=" h-full w-full md:overflow-y-scroll overflow-y-hidden sm:pb-20"
    >
      <h2
        onClick={() => {
          props.moveSlide(0);
        }}
        className="text-primary border-b border-b-primary pb-2 text-[25px] text-left md:flex hidden uppercase heading2"
      >
        success, you’ve just <br /> joined plus fitness
      </h2>
      <div
        className={`sm:mt-[28px] mt-[47px] flex md:flex-col flex-row md:items-start items-center w-full ${moment(new Date()).diff(
          moment(props.info.memberDetails?.dob),
          'years'
        ) >= 18
          ? 'justify-between '
          : 'gap-[62px] sm:gap-6'
          }`}
      >
        <div className="flex flex-col justify-start items-start">
          <h2
            style={{ fontSize: '40px', textAlign: 'start' }}
            className="font-bold uppercase text-white text-start md:mb-3 mb-0 heading"
          >
            {moment(new Date()).diff(
              moment(props.info.memberDetails?.dob),
              'years'
            ) >= 18
              ? 'welcome, ' + props.info.memberDetails.givenName + '!'
              : 'MEMBERSHIP PENDING'}
          </h2>
          <div
            className={`text-white text-start font-normal text-lg leading-[28.8px] max-w-[535px]   ${moment(new Date()).diff(
              moment(props.info.memberDetails?.dob),
              'years'
            ) >= 18
              ? 'mt-4 sm:hidden block'
              : 'mt-[5px]'
              }`}
            onClick={() =>
              moment(new Date()).diff(
                moment(props.info.memberDetails?.dob),
                'years'
              ) >= 18 && navigate('/login')
            }
          >
            {moment(new Date()).diff(
              moment(props.info.memberDetails?.dob),
              'years'
            ) >= 18 ? (
              <ArrowButton text="LOGIN" isRight={true} />
            ) : (
              'Thank you for joining Plus Fitness. We’re excited to have you onboard. What happens now? An SMS has been sent to your nominated parent/guardian, which requires approval to finalise your membership. If you or your parent/guardian have any issues, please contact your local Plus Fitness directly.'
            )}
          </div>
        </div>
        <div
          className={` gap-x-12 gap-y-[18px] items-start sm:flex-col justify-between self-start ${moment(new Date()).diff(
            moment(props.info.memberDetails?.dob),
            'years'
          ) >= 18
            ? 'flex flex-row'
            : 'grid grid-row-reverse '
            }`}
        >
          <div className='order-2'>
            {/* <h2 className='gotham text-sm text-white text-left'>Your membership number</h2>
                        <p className='gotham text-textSecondary text-left tracking-[1px] text-[12px] mt-1'>123 456 789</p> */}
            <h2 className="gotham text-sm text-white text-left">Username</h2>
            <p className="gotham text-textSecondary text-left tracking-[1.4px] text-[14px] mt-1">
              {props.info.memberDetails?.email}
            </p>
          </div>
          <div className='order-1'>
            <h2 className="gotham text-sm text-white text-left">Start date</h2>
            <p className="gotham text-textSecondary text-left tracking-[1.4px] text-[14px] mt-1">
              {moment(new Date()).diff(
                moment(props.info.memberDetails?.dob),
                'years'
              ) >= 18
                ? `${moment(props.info.membershipStartDate).format('DD/MM/YYYY')}`
                : 'Pending'}
            </p>
          </div>
          {moment(new Date()).diff(
            moment(props.info.memberDetails?.dob),
            'years'
          ) < 18 ? (
            <div className='order-3 w-max'
              onClick={() => navigate('/login')}>
              <ArrowButton text="LOGIN" isRight={true} />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className="mt-[57px]">
        <h2 className="uppercase text-left sm:pb-[8px] pb-[15px] border-b border-b-primary text-primary heading2 font-bicyclette">
          next steps
        </h2>
      </div>
      <div className="mt-[21px]">
        <div className="flex md:flex-col flex-row md:items-start pb-[21px] border-b border-b-borderLine items-center justify-between">
          <div className="flex items-center gap-4">
            <h1 className="font-bold md:text-white text-primary heading font-bicyclette">
              01
            </h1>
            <p
              style={{ fontSize: '18px' }}
              className="uppercase font-bold md:text-primary text-white heading2 font-bicyclette"
            >
              Check your email
            </p>
          </div>
          <p className="text-white md:text-left text-right md:mt-2 mt-0 sm:pl-[42px] leading-[160%]">
            We’ve sent your membership details and payment receipt <br /> to your nominated email.
          </p>
        </div>
        <div className="flex pt-4 pb-3 md:flex-col flex-row md:items-start border-b border-b-borderLine items-center justify-between">
          <div className="flex items-center gap-4">
            <h1 className="font-bold md:text-white text-primary heading font-bicyclette">
              02
            </h1>
            <p
              style={{ fontSize: '18px' }}
              className="uppercase font-bold md:text-primary text-white heading2 font-bicyclette"
            >
              Check the app
            </p>
          </div>
          <div>
            <p className="text-white md:text-left text-right md:mt-2 mt-0 sm:pl-[42px]">
              Download the Plus Fitness app, get it setup on your phone <br />{' '}
            </p>
            <div className="mt-2 flex md:justify-start justify-end items-center gap-3 sm:pl-[42px]">
              <img className="h-[34px] w-auto cursor-pointer" src={appstore} alt="appstore" onClick={() => openInNewTab('https://apps.apple.com/au/app/plus-fitness-member-id/id1612191369')} />
              <img className="h-[53px] w-auto cursor-pointer" src={googlePlay} alt="ggplay" onClick={() => openInNewTab('https://play.google.com/store/apps/details?id=com.vivaleisure.plus_fitness_member_id&pli=1')} />
            </div>
          </div>
        </div>
        <div className="flex pt-6 pb-3 md:flex-col flex-row md:items-start items-center justify-between">
          <div className="flex items-center gap-4">
            <h1 className="font-bold md:text-white text-primary heading font-bicyclette">
              03
            </h1>
            <p
              style={{ fontSize: '18px' }}
              className="uppercase text-left font-bold md:text-primary text-white heading2 font-bicyclette"
            >
              Come to your club and <br />
              start training
            </p>
          </div>
          <p className="text-white md:text-left text-right md:mt-2 mt-0 sm:pl-[42px]">
            Congratulations on taking the first step of your fitness journey at
            Plus Fitness. <br /> Simply use the mobile member app to access your
            gym and get your sweat on!
          </p>
        </div>

        <div className="hidden sm:block mt-8 customArrowCongrats">
          <ArrowButtonSecondary
            isRight
            text="NEED A PERSONAL TRAINER ?"
            onclick={() => console.log('Personal trainer')}
            noArr={false}
          />
        </div>
      </div>
    </div>
  );
}

export default Congrats;
