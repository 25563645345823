import axios from 'axios';
import { useEffect, useState } from 'react';

interface queryTypes {
  params: string;
  populate?: string;
  page?: number;
  pageSize?: number;
  sort?: string;
  filters?: Array<string>;
}

const checkParamsQuery = (url: string) => {
  return url.split('?').length > 1 ? '&' : '?';
};

function useFetch({
  params,
  populate,
  page,
  pageSize,
  sort,
  filters,
}: queryTypes) {
  const API = process.env.GATSBY_STRAPI_API_URL;
  const [error, setError] = useState(null);
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    let url = `${API}/api/${params}`;
    if (populate) url += `${checkParamsQuery(url)}populate=${populate}`;
    if (page) url += `${checkParamsQuery(url)}pagination[page]=${page}`;
    if (pageSize)
      url += `${checkParamsQuery(url)}pagination[pageSize]=${pageSize}`;
    if (sort) url += `${checkParamsQuery(url)}sort=${sort}`;
    if (filters && filters?.length > 0) {
      for (let index = 0; index < filters.length; index++) {
        if (filters[index]) {
          url += `${checkParamsQuery(url)}filters${filters[index]}`;
        }
      }
    }
    axios
      .get(url)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params, JSON.stringify(filters), sort]);

  return { data, loading, error };
}

export default useFetch;
