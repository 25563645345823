import React, { useState } from 'react';
// import warnCircle from "../../assets/images/icons/warnCircle.svg";

function InputTextArea(props: any) {
  const [active, setActive] = useState(false);
  const activeValue = (e) => {
    setActive(true);
    return e.target.value === ''
      ? ((e.target.style.borderColor = ''),
        (e.target.style.color = ''),
        setActive(false))
      : ((e.target.style.borderColor = '#ffffff'),
        (e.target.style.color = '#ffffff'),
        setActive(true));
  };
  return (
    <div className="flex relative flex-col w-full">
      <label>
        <span className="after:content-[''] text-white after:ml-0.5 after:text-white block text-[12px] graphik-regular font-medium">
          {active ? '' : ''}
        </span>
        <span
          className={`absolute  block text-[12px] pb-[2px] gotham font-medium  transition-all duration-300 ${
            active
              ? 'top-0 left-0 text-textSecondary'
              : 'top-[25px] left-[1px] text-white'
          }`}
        >
          {' '}
          {props.placeholder ?? ''}
        </span>
        {props.isShowRequired && !active && (
          <div className="absolute top-10 right-3 graphik-regular font-normal text-xs text-brand-clime">
            This Field is required{' '}
            <img
              className="w-4 ml-1 h-4 inline"
              src={'/icons/warn-circle.svg'}
            />
          </div>
        )}
      </label>
      <textarea
        // onChange={props.onChange ? props.onchange : () => {}}
        onInput={(e) => {
          activeValue(e);
        }}
        className="gotham h-[136px] placeholder:text-white py-[7px] bg-black border-b graphik-regular border-gray focus:outline-none placeholder-slate-400  block w-full sm:text-sm text-start"
        placeholder={''}
        onChange={props.onchange ? props.onchange : null}
      />
    </div>
  );
}
export default InputTextArea;
