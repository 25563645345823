import ModalBase from '@/common/modals/ModalBase';
import React, { useEffect, useMemo, useState } from 'react';
import OTPInput from '@/common/inputs/OTPInput';
// import { Auth } from 'aws-amplify';
import { toastError, toastSuccess } from '@/common/toasts/toast';
import { useMutation } from '@apollo/client';
import { sendConfirmPhoneOtp, sendOtpConfirmNewEmail } from '@/api/mutation';
import { Auth } from 'aws-amplify';

function ModalConfirmOtp({
  onClose = () => {
    return;
  },
  onComplete = () => {
    return;
  },
  isNewPhone = false,
  isNewMail = false,
  newPrimaryEmail = '',
  newPhoneNumber = '',
}) {
  const [sendVerifyCode] = useMutation(sendConfirmPhoneOtp);

  const [sendVerifyEmail] = useMutation(sendOtpConfirmNewEmail);

  const [loading, setLoading] = useState(false);
  const [otpType, setOtpType] = useState('SMS');
  const OTPMessage = useMemo(() => {
    if (otpType === 'SMS')
      if (isNewMail)
        return (
          <>
            {' '}
            We’ve sent a 6-Character code to your Email.
            <br /> Please check the Email on mail box.
          </>
        );
      else
        return (
          <>
            {' '}
            We’ve sent a 6-Character code to your SMS.
            <br /> Please check the SMS on your phone.
          </>
        );
    if (otpType === 'ERROR')
      return (
        <p className="text-textError">
          {' '}
          We are trying to send a 6-Character code.
          <br />
          But there is an error please try again.
        </p>
      );
  }, [otpType]);

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [seconds]);
  const [OTPExpire, setOtpExpire] = useState({ seconds: 0, minutes: 0 });

  const [otpValue, setOtpValue] = useState('');
  useEffect(() => {
    const interval = setInterval(() => {
      if (OTPExpire.seconds > 0) {
        setOtpExpire({ ...OTPExpire, seconds: OTPExpire.seconds - 1 });
      }

      if (OTPExpire.seconds === 0) {
        if (OTPExpire.minutes === 0) {
          clearInterval(interval);
        } else {
          setOtpExpire({ minutes: OTPExpire.minutes - 1, seconds: 59 });
          // setOtpExpire({...minutes:OTPExpire.minutes - 1});
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [OTPExpire.seconds]);
  const handleSendOtpToUser = () => {
    setLoading(true);
    const payload = { operation: '', newPhoneNumber: '' };

    isNewPhone
      ? (payload.operation = 'SEND_OTP_NEW_PHONE')
      : (payload.operation = 'SEND_OTP');

    isNewPhone
      ? (payload.newPhoneNumber = newPhoneNumber)
      : delete payload.newPhoneNumber;

    sendVerifyCode({
      variables: {
        ...payload,
      },
    })
      .then((res) => {
        setSeconds(60);
        setOtpExpire({ seconds: 60, minutes: 4 });
      })
      .catch((e) => {
        setOtpType('ERROR');
        toastError(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleSendOtpMailToUser = async () => {
    try {
      setLoading(true);
      const payload = {
        email: '',
        memberId: '',
        operation: 'SEND_OTP_PRIMARY_EMAIL',
      };

      const user = await Auth.currentAuthenticatedUser();
      payload.memberId = user?.attributes['custom:memberId'];
      payload.email = newPrimaryEmail;
      await sendVerifyEmail({
        variables: {
          input: { ...payload },
        },
      });
      setSeconds(60);
      setOtpExpire({ seconds: 60, minutes: 4 });
    } catch (e) {
      setOtpType('ERROR');
      toastError(e.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    isNewPhone && handleSendOtpToUser();
    isNewMail && handleSendOtpMailToUser();
  }, []);
  const resend = async (otpTypeResend: string) => {
    isNewPhone && handleSendOtpToUser();
    isNewMail && handleSendOtpMailToUser();
    // const data = await Auth.signIn(account.email);
    // setSession(data);
    setOtpType(otpTypeResend);
  };
  const verifyEmailOtp = async (otp: string) => {
    try {
      setLoading(true);
      const user = await Auth.currentAuthenticatedUser();
      await sendVerifyEmail({
        variables: {
          input: {
            operation: 'UPDATE_PRIMARY_EMAIL',
            memberId: user?.attributes['custom:memberId'],
            inputOTPCode: otp,
          },
        },
      });
      toastSuccess('Successfully confirm your email address');
      onComplete();
    } catch (err) {
      setOtpValue('');
      toastError(err.message);
    } finally {
      onClose();
      setLoading(false);
    }
  };
  const verifyOtp = async (otp: string) => {
    try {
      setLoading(true);
      await sendVerifyCode({
        variables: {
          operation: isNewPhone ? 'VERIFY_OTP_NEW_PHONE' : 'VERIFY_OTP',
          otpCode: otp,
        },
      });
      toastSuccess('Successfully confirm your phone number');
      onComplete();
    } catch (err) {
      setOtpValue('');
      toastError(err.message);
    } finally {
      onClose();
      setLoading(false);
    }
  };
  return (
    <ModalBase
      loading={loading}
      closeModal={() => {
        onClose();
      }}
      // onClickOutSide={() => {}}
    >
      <div className="w-[500px] px-[33px] flex flex-col gap-6 pb-6">
        <>
          <p className="text-center text-base text-white">
            {OTPMessage}
            {(OTPExpire.seconds > 0 || OTPExpire.minutes > 0) && (
              <span>
                <br />
                Your OTP will be expired in:{' '}
                {OTPExpire.minutes < 10
                  ? `0${OTPExpire.minutes}`
                  : OTPExpire.minutes}
                :
                {OTPExpire.seconds < 10
                  ? `0${OTPExpire.seconds}`
                  : OTPExpire.seconds}
              </span>
            )}
          </p>
          <OTPInput
            autoFocus
            isNumberInput
            length={6}
            value={otpValue}
            onChangeOTP={(otp: string) => {
              setOtpValue(otp);
              if (otp.length === 6) {
                isNewPhone && verifyOtp(otp);
                isNewMail && verifyEmailOtp(otp);
              }
            }}
          />
          {/* <ArrowButton
                text="
              LOG IN
              "
                isRight
                onClick={() => verifyOtp(otp)}
                className="uppercase justify-self-end "
                disabled={otp.length < 6}
              ></ArrowButton> */}
          <div className="flex justify-evenly text-white">
            {seconds > 0 || minutes > 0 ? (
              <p>
                Didn't receive the code try again after{' '}
                {minutes < 10 ? `0${minutes}` : minutes}:
                {seconds < 10 ? `0${seconds}` : seconds}
              </p>
            ) : (
              <>
                {!loading && (
                  <span
                    className="text-white underline cursor-pointer"
                    onClick={() => {
                      resend('SMS');
                    }}
                  >
                    Resend OTP
                  </span>
                )}
                {/*
                <span
                  className="text-white underline cursor-pointer"
                  onClick={() => {
                    resend('EMAIL');
                    // setMinutes(1);
                  }}
                >
                  Resend by Email
                </span>

                <span
                  className="text-white underline cursor-pointer"
                  onClick={() => {
                    resend('SMS');
                  }}
                >
                  Resend by SMS
                </span> */}
              </>
            )}
          </div>
        </>
      </div>
    </ModalBase>
  );
}

export default ModalConfirmOtp;
