import Layout from '@/components/Layout';
import React, { useEffect, useState } from 'react';
import useFetch from '@/hooks/useFetch';
import MyReactQuill from '@/components/MyReactQuill';
import PrimaryOutlinedButton from '@/common/buttons/PrimaryOutlinedButton';
import { operationName, useLazyQuery, useMutation } from '@apollo/client';
import { approveTermsConditions, getMember } from '@/api/mutation';
import toast from 'react-hot-toast';

interface IDataTermsAndConditionPage {
  title: string;
  content: string;
}
interface IQueryString {
  prospectId: string;
  memberId: string;
}
const TermsAndConditionApprovalPage = () => {
  const { data: termsConditionsData } = useFetch({
    params: 'term-and-condition',
    populate: '',
  });
  const { data: privacyPolicyData } = useFetch({
    params: 'privacy-policy',
    populate: '',
  });
  const [
    approveTermsAndCondition,
    { loading: approveTermsAndConditionLoading },
  ] = useMutation(approveTermsConditions);
  const dataPagePrivacyPolicy: IDataTermsAndConditionPage =
    privacyPolicyData?.data?.attributes;
  const dataPageTermsAndConditions: IDataTermsAndConditionPage =
    termsConditionsData?.data?.attributes;

  const [getMemberApi] = useLazyQuery(getMember);

  const [userInfo, setUserInfo] = useState<IQueryString>({
    memberId: '',
    prospectId: '',
  });
  const [approveSuccess, setApproveSuccess] = useState(false);

  useEffect(() => {
    const parseQueryString = () => {
      const str = window.location.search;
      const objURL: any = {};

      str.replace(
        new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
        function ($0, $1, $2, $3) {
          objURL[$1] = $3;
          return '';
        }
      );
      return objURL;
    };
    const queryString: IQueryString = parseQueryString();
    setUserInfo(queryString);
  }, []);

  const handleApprove = () => {
    approveTermsAndCondition({
      variables: {
        operationName: 'approveTermsAndConditions',
        prospectId: userInfo?.prospectId,
      },
      onCompleted: () => {
        setApproveSuccess(true);
      },
      onError: (err) => {
        setApproveSuccess(false);
        toast.error(`${err}`);
      },
    });
  };

  return (
    <main id="termsAndConditionPage">
      <section className="bg-dark pt-6 pb-20">
        <div className="containerPage">
          <h1 className="heading heading-underline text-white">
            {dataPageTermsAndConditions?.title}
          </h1>
          <div className="contentWrapper">
            <MyReactQuill content={dataPageTermsAndConditions?.content} />
          </div>
        </div>
      </section>
      <section className="bg-dark pt-6 pb-20">
        <div className="containerPage">
          <h1 className="heading heading-underline text-white">
            {dataPagePrivacyPolicy?.title}
          </h1>
          <div className="contentWrapper">
            <MyReactQuill content={dataPagePrivacyPolicy?.content} />
          </div>
        </div>
        <div className="bg-dark containerPage flex justify-end">
          <PrimaryOutlinedButton
            className={` !w-[108px] relative ${'!text-primary hover:!bg-primary hover:!text-white font-bold'}`}
            onClick={handleApprove}
            disabled={approveTermsAndConditionLoading}
          >
            {approveTermsAndConditionLoading ? (
              <div className="absolute w-full h-full flex items-center justify-center   top-0 left-0">
                <div className="ldsEllipsis ">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            ) : (
              'Accept'
            )}
          </PrimaryOutlinedButton>
        </div>
      </section>
      {approveSuccess && (
        <div
          className={`fixed top-0 left-0 flex items-center transition-opacity ease-in-out duration-500 h-[100vh] w-[100vw] z-50 bg-[rgba(14,13,13,0.6)]`}
        >
          <div className="m-auto h-[300px] w-[30%] sm:w-[350px] lg:w-[400px] bg-richBlack flex justify-center items-center px-[35px]">
            <div className="mb-8">
              {
                <div className="success-animation">
                  <svg
                    className="checkmark"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 52 52"
                  >
                    <circle
                      className="checkmark__circle"
                      cx="26"
                      cy="26"
                      r="25"
                      fill="none"
                    />
                    <path
                      className="checkmark__check"
                      fill="none"
                      d="M14.1 27.2l7.1 7.2 16.7-16.8"
                    />
                  </svg>
                </div>
              }
              <div className="mt-5 text-center text-2xl text-primary  ">
                Success
              </div>
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default TermsAndConditionApprovalPage;
