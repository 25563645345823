import React, { useState, createContext, useEffect } from "react";
import useFetch from "../hooks/useFetch";

const RegisterContext = createContext<any>(null)
interface iProps {
  children: React.ReactNode;
}

const StoreProvider = ({ children }: iProps) => {
  const [param, setParam] = useState<string>('')

  const [yourClub, setYourClub] = useState<object>()
  const [upgrades, setUpgrades] = useState<[]>([])
  const [plan, setPlan] = useState<null>(null)

  const [access, setAccess] = useState<[]>([])
  const [isChangeClub, setIsChangeClub] = useState<boolean>(false)

  const { data: nearYou } = useFetch({ params: param, populate: "image,access,address.state", });
  const { data } = useFetch({ params: 'studios', populate: "image,access,address.state", });


  //open change location on change button only
  const [open, setOpen] = useState<boolean>(false);


  useEffect(() => {
    const getStudios = () => {
      navigator?.geolocation?.getCurrentPosition((position) => {
        setParam(
          `studios/inRange?location[]=${position?.coords?.latitude}&location[]=${position?.coords?.longitude}&distance=10000000`
        );
      });
    };
    getStudios();
  }, []);

  useEffect(() => {
    nearYou && setYourClub(nearYou?.data?.[0]);
  }, [nearYou]);


  useEffect(() => {
    // Save Studio selected into localStorage
    if (localStorage.getItem('yourClub') !== null) {
      let yourCLub = localStorage.getItem('yourClub')
      setYourClub(JSON.parse(yourCLub))
    } else {
      // Studio Near you
      if (nearYou && nearYou?.data?.length > 0) {
        setYourClub(nearYou?.data?.[0])
      } else {
        // Get Default
        setYourClub(data?.data?.find(item => item?.attributes?.title?.toLowerCase() === 'braddon'))
      }
    }
  }, [nearYou, data])


  return (
    <RegisterContext.Provider value={{ yourClub, setYourClub, isChangeClub, setIsChangeClub, plan, setPlan, nearYou, access, open, setOpen, upgrades, setUpgrades }}>
      {children}
    </RegisterContext.Provider>
  )
}

export { RegisterContext, StoreProvider }
