export type DateCheckParams = {
  startDateTime: string;
  amount: number;
  type: 'DAYS' | 'WEEKS' | 'MONTHS' | 'YEARS';
};

export function CheckingEndDate({
  startDateTime,
  amount,
  type,
}: DateCheckParams): boolean {
  const startDate: Date = new Date(startDateTime);

  let endDate: Date;
  switch (type) {
    case 'DAYS':
      endDate = new Date(startDate.getTime() + amount * 24 * 60 * 60 * 1000);
      break;
    case 'WEEKS':
      endDate = new Date(
        startDate.getTime() + amount * 7 * 24 * 60 * 60 * 1000
      );
      break;
    case 'MONTHS':
      endDate = new Date(startDate);
      endDate.setMonth(startDate.getMonth() + amount);
      break;
    case 'YEARS':
      endDate = new Date(startDate);
      endDate.setFullYear(startDate.getFullYear() + amount);
      break;
    default:
      throw new Error('Invalid type');
  }

  // Compare the end date with the current date
  const currentDate: Date = new Date();
  if (endDate > currentDate) {
    return true;
  } else if (endDate < currentDate) {
    return false;
  } else {
    return true;
  }
}
